import { Actions } from '../../interfaces'
import { ComponentCategory } from '@/entities/vehicle'

import find from './find'
import findLite from './findLite'

export const actions: Actions = {
  find,
  findLite,
}

export default {
  Model: { ComponentCategory },
  actions: { [ComponentCategory.name]: actions },
}
