import { Deal, Employee, Entity, Person } from '@/models'
import { Alert, Business, BusinessStatus, BusinessStatusIcons, Lead as ILead, LeadStatus } from '../interfaces'

export class Lead extends Entity implements ILead {
  public static readonly Aggregate = {
    path: 'business.status',
    enum: BusinessStatus,
    child: {
      path: 'status',
      enum: LeadStatus,
    },
  }

  status: LeadStatus;
  client: Person;
  responsible: Employee;
  deals: Array<Deal>;

  private _sell?: Deal;
  private _buy?: Deal;

  constructor (lead: ILead) {
    super(lead)
    const { status, client, responsible, deals, id } = lead

    this.id = id
    this.status = status
    this.client = new Person(client)
    this.responsible = new Employee(responsible)
    this.deals = deals.map(deal => new Deal(deal))
  }

  get sell (): Deal | undefined {
    const { _sell } = this
    if (!_sell) return this._sell = this.deals.find(({ isSell }) => isSell)

    return _sell
  }

  get buy (): Deal | undefined {
    const { _buy } = this
    if (!_buy) return this._buy = this.deals.find(({ isBuy }) => isBuy)

    return _buy
  }

  get alerts (): Array<Alert> {
    // TODO: Compute lead alerts
    return []
  }

  get business (): Business | undefined {
    const { buy, sell } = this
    if (!buy && !sell) return

    const credit = sell?.financial
    const renew = buy && sell

    const status = renew
      ? credit
        ? BusinessStatus.renewalCredit : BusinessStatus.renewal
      : sell
        ? credit
          ? BusinessStatus.sellCredit : BusinessStatus.sell
        : BusinessStatus.buy

    return { status, toString: () => BusinessStatusIcons[status].name || '' }
  }
}
