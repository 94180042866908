import { createDecorator } from 'vue-class-component'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const debounce = require('lodash/debounce')

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const Debounce = function (timeout = 2000): any {
  return createDecorator((options, key) => {
    // @ts-ignore
    const originalMethod = options.methods[key]
    // @ts-ignore
    options.methods[key] = debounce(originalMethod, timeout)
  })
}
