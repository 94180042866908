import gql from 'graphql-tag'
import { PaymentOrderItemFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentOrderItemFragment}
  query findPaymentOrderItem($filter: finance_payment_order_item_bool_exp) {
    records: finance_payment_order_item(where: $filter) {
      ...PaymentOrderItem
    }
  }
`
export default query
