import { MutationTree } from 'vuex'
import { Files } from '@/store/files/state'

export const mutations: MutationTree<Files> = {
  insertId: (state, payload) => {
    state.filesStorage.push(...payload)
  },
  deleteId: (state, payload) => {
    payload.forEach(id => {
      const index = state.filesStorage.indexOf(id)
      if (index !== -1) {
        state.filesStorage.splice(index, 1)
      }
    })
  },
}
