import gql from 'graphql-tag'

import { MaintenanceFragment } from '@/graphql/fragments/public/maintenance'
import { AutoFragment } from '@/graphql/fragments/public'
import { MaintenanceTypeFragment } from '@/graphql/fragments/public/maintenanceType'

export const mutation = gql`
  ${MaintenanceFragment}
  ${AutoFragment}
  ${MaintenanceTypeFragment}
  mutation updateMaintenance($id: Int!, $fields: maintenance_set_input!) {
    record: update_maintenance_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Maintenance
      auto{
        ...Auto
      }
      type{
        ...MaintenanceType
      }
    }
  }
`

export default mutation
