import { Type } from 'class-transformer'
import { Entity } from '..'

import { Evaluation } from './Evaluation'
import { Person } from '../persons'
import { SaleOrder } from '../sales'
import { fixPrice } from '@/utils/general'
import { FinancedItem } from '@/entities/loans/FinancedItem'

export class Financing extends Entity {
  @Type(() => Evaluation)
  evaluations: Evaluation[];

  @Type(() => Person)
  applicant: Person

  @Type(() => FinancedItem)
  financedItems: FinancedItem[]

  @Type(() => SaleOrder)
  saleOrder: SaleOrder

  warrantyAmount: number;
  initial: number;

  private findHighestOrder (evaluations): number {
    let highestOrder = -Infinity
    for (const evaluation of evaluations) {
      if (evaluation.order !== null && evaluation.order > highestOrder) {
        highestOrder = evaluation.order
      } else if (evaluation.order === null && highestOrder === -Infinity) {
        highestOrder = evaluation?.closingReasonOrder?.order
      } else if (evaluation?.closingReasonOrder?.order > highestOrder) {
        highestOrder = evaluation?.closingReasonOrder?.order
      }
    }
    return highestOrder
  }

  get applicantWithRut () {
    const { applicant } = this

    return `${applicant.shortName} ${applicant.uid}`
  }

  get evaluation () {
    const { evaluations } = this
    if (!evaluations || !evaluations.length) return

    const statusOrder = evaluations.map(evaluation => {
      return {
        name: evaluation.status?.name,
        order: evaluation.status?.order,
        closingReason: evaluation.closingReason?.type,
        status: evaluation.status,
        closingReasonOrder: evaluation.closingReason,
      }
    })

    const highOrder = this.findHighestOrder(statusOrder)

    const highEvaluations = evaluations.filter(evaluation => {
      return evaluation.status?.order === highOrder || evaluation?.closingReason?.order === highOrder
    })

    const companyNames = highEvaluations.map(evaluation => evaluation?.agreement?.name).join(', ')
    const isPending = evaluations.every(evaluation => evaluation.status?.isPending)

    return {
      status: highEvaluations[0]?.status,
      company: { alias: isPending ? '' : companyNames },
      evaluation: highEvaluations[0],
    }
  }

  get startingAmount () {
    const { warrantyAmount, initial } = this
    const value = warrantyAmount - initial
    const formatted = fixPrice(value)

    return { value, formatted }
  }

  get client () {
    const { saleOrder: { deal: { lead: { client } } } } = this

    return client
  }

  get responsible () {
    const { evaluations } = this

    if (!evaluations || !evaluations.length) return

    const evaluation = evaluations.find(evaluation => evaluation?.responsibleAgreement?.responsible)
    if (!evaluation) return

    return evaluation.responsibleAgreement.responsible
  }

  get whatsappMe () {
    const { responsible: { whatsappMeWork } } = this
    if (!whatsappMeWork) return

    return whatsappMeWork
  }

  get executive () {
    const { saleOrder } = this

    return saleOrder?.deal?.lead?.executive
  }

  get priceList () {
    const { saleOrder } = this
    if (!saleOrder) return 0

    const product = saleOrder.product
    if (!product?.amount) return 0

    return product.amount
  }

  get priceListFixed () {
    const { priceList } = this
    if (!priceList) return `0`

    return fixPrice(priceList)
  }

  get piePercentage () {
    const { initial, priceList } = this
    if (!initial) return `0%`

    const percentage = (initial * 100) / priceList

    return `${percentage.toFixed(0)}%`
  }

  get initialFixed () {
    const { initial } = this
    if (!initial) return `0%`

    return fixPrice(initial)
  }

  get transfer () {
    const { financedItems } = this

    const financed = financedItems?.find(financed => financed.saleOrderItem?.type.name === 'transfer')
    if (!financed?.saleOrderItem?.amount) return 0
    return financed.saleOrderItem.amount
  }

  get requestAmount () {
    const { warrantyAmount, initial } = this

    return warrantyAmount - initial
  }

  get warrantyAmountFixed () {
    const { warrantyAmount } = this

    return fixPrice(warrantyAmount)
  }

  get requestAmountTransfer () {
    const { transfer, requestAmount } = this
    if (!transfer) return 0
    return transfer + requestAmount
  }

  get pie () {
    const { initialFixed, piePercentage } = this

    return `${initialFixed} ${piePercentage}`
  }

  get bestCommission () {
    const { evaluations } = this

    if (!evaluations?.length) return ''

    let highestCommissionOffer = { fee: 0, companyName: '' }

    evaluations.forEach(evaluation => {
      if (evaluation?.offers?.length) {
        evaluation.offers.forEach(offer => {
          if (offer.fee > highestCommissionOffer.fee) {
            highestCommissionOffer = {
              fee: offer.fee,
              companyName: evaluation?.agreement?.name,
            }
          }
        })
      }
    })

    if (highestCommissionOffer.fee === 0) return ''

    return `${highestCommissionOffer.companyName} ${fixPrice(highestCommissionOffer.fee)}`
  }
}
