import gql from 'graphql-tag'
import { PipelineFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${PipelineFragment}
  query findPipeline($filter: crm_pipeline_bool_exp, $limit: Int,$order: [crm_pipeline_order_by!]) {
    records: crm_pipeline (where: $filter, limit: $limit, order_by: $order){
      ...Pipeline
    }
  }
`

export default query
