import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const query = gql`
  ${Fragment}
  query fetchAppraisalResponseType($id: Int!) {
    records: purchase_appraisal_response_type_by_pk(id: $id) {
      ...AppraisalResponseTypeForm
    }
  }
`

export default query
