import gql from 'graphql-tag'

import { SaleOrderItemFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${SaleOrderItemFragment}
  mutation insertSaleOrderItem($fields: sales_sale_order_item_insert_input!) {
    record: insert_sales_sale_order_item_one(object: $fields) {
      ...SaleOrderItem
    }
  }
`

export default mutation
