export function setValueByPath (obj: any, path: string, value: any) {
  const pathArr = path.split('.')
  let currentObj = obj

  pathArr.forEach((key, index) => {
    if (index === pathArr.length - 1) {
      currentObj[key] = value === null ? undefined : value
      return currentObj[key]
    }

    if (!currentObj[key]) {
      currentObj[key] = isNaN(parseInt(pathArr[index + 1])) ? {} : []
    }

    currentObj = currentObj[key]
  })

  return obj
}

export function findDifferences (updated: any, original: any, exclude?: Set<string>, differences: string[] = [], path = '', backup = original) {
  const type = typeof updated

  // eslint-disable-next-line valid-typeof
  if (type !== typeof original) {
    differences.push(path)
    return differences
  }

  if (type !== 'object' || updated === null || original === null) {
    if (updated !== original) {
      differences.push(path)
    }
    return differences
  }

  const keys = new Set([...Object.keys(updated), ...Object.keys(original)])

  keys.forEach(key => {
    const currentPath = path ? `${path}.${key}` : key
    if (exclude?.has(currentPath)) return

    if (original.hasOwnProperty(key)) {
      findDifferences(updated[key], original[key], exclude, differences, currentPath, backup)
    } else {
      differences.push(currentPath)
    }
  })

  return differences
}

export function extendsObj<T = Record<string, any>> (target: Partial<T>, source: Partial<T>): T {
  const result: Partial<T> = { ...target }
  if (!source) return result as T

  Object.keys(source).forEach((key: string) => {
    const _target = result[key]
    const _source = source[key]

    if (_target === null || _source === null) {
      return
    }

    if (!(key in result) || _target === undefined) {
      result[key] = _source
      return
    }

    if (Array.isArray(_target) && Array.isArray(_source)) {
      result[key] = Array.from(new Set([..._source, ..._target]))
      return
    }

    if (typeof _target === 'object' && typeof _source === 'object') {
      result[key] = extendsObj(_target, _source)
    }
  })

  return result as T
}

export function insertIntoLocalStorage (model, id, data) {
  if (!model || !id || !data) return null

  const key = `${model}:${id}`

  localStorage.setItem(key, JSON.stringify(data))
}

export function getFromLocalStorage (model, id) {
  if (!model || !id) return null

  const key = `${model}:${id}`

  const storedData = localStorage.getItem(key)
  if (!storedData) return null

  return JSON.parse(storedData)
}
