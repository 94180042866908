import Vue from 'vue'
import VueRouter from 'vue-router'

import middlewares from '@/router/middlewares'
import { Store } from 'vuex'
import { State } from '@/store/state'
import routes from '../views/router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

middlewares(router)
export function initRoutesMiddlewares (store: Store<State>): void {
  middlewares.injectStore(store)
}

export default router
