import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'

export interface Platform {
  id: number
  name: string
  status?: string
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export interface Vehicles {
  id: number
}

export function initState (): Vehicles {
  return {
    id: -1,
  }
}

export const mutations: MutationTree<Vehicles> = {}

export const getters: GetterTree<Vehicles, RootState> = {}
