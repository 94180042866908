import { Type } from 'class-transformer'
import { Entity } from '..'

import { AgreementProduct } from '../settings'
import { Offer } from './Offer'

export class OfferProduct extends Entity {
  @Type(() => Offer)
  offer: Offer;

  @Type(() => AgreementProduct)
  agreementProduct: AgreementProduct;
}
