import gql from 'graphql-tag'
import { EntityFields } from '..'
import { FileTypeFragment } from '@/graphql/fragments/files/fileType'
import { FileParameterFragment } from '@/graphql/fragments/files/fileParameter'
import { FileFragment } from '@/graphql/fragments/files/file'
import { FileValidationFragment } from '@/graphql/fragments/files/fileValidation'

export const FileProcessFragment = gql`
  ${FileTypeFragment}
  ${FileParameterFragment}
  ${FileFragment}
  ${FileValidationFragment}
  fragment FileProcess on files_file_process{
    ${EntityFields}
    comment
    valid
    idProcessRecord:id_process_record
    file{
      ...File
    }
    parameter {
      ...FileParameter
      fileType:file_type {
        ...FileType
      }
      process {
        id
      }
    }
    validation:file_validations{
      ...FileValidation
    }
  }
`
