import gql from 'graphql-tag'
import { EntityFields } from '..'
import {
  AttributeFragment,
  AutoMotiveRegistrationFragment,
  RotationIndexFragment,
  VersionFragment,
  YearFragment,
} from '.'

export const BaseVersionYearFragment = gql`
  fragment VersionYearBase on vehicle_version_year {
    ${EntityFields}
    idGenio: id_genio
  }
`
export const VersionYearFragment = gql`
  ${BaseVersionYearFragment}
  ${VersionFragment}
  ${YearFragment}
  ${AutoMotiveRegistrationFragment}
  ${AttributeFragment}
  ${RotationIndexFragment}
  fragment VersionYear on vehicle_version_year {
    ...VersionYearBase
    version {
      ...Version
    }
    year {
      ...Year
    }
    rotationIndex: rotation_index {
      ...RotationIndex
    }
  }
`
