import gql from 'graphql-tag'
import { ActivityFragment } from '@/graphql/fragments/crm'

export const mutation = gql`
  ${ActivityFragment}
  mutation insertLeadActivity($fields: crm_lead_activity_insert_input!) {
    record: insert_crm_lead_activity_one(object: $fields) {
      ...Activity
    }
  }
`

export default mutation
