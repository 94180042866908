import { Actions } from '../../interfaces'
import { Attribute } from '@/entities/vehicle'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Attribute },
  actions: { [Attribute.name]: actions },
}
