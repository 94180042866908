import gql from 'graphql-tag'
import { BasePriceFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${BasePriceFragment}
  mutation updatePrice($id: Int!,$fields: sales_price_set_input!) {
    record: update_sales_price_by_pk(pk_columns: {id: $id}, _set: $fields){
      ...BasePrice
    }
  }
`

export default mutation
