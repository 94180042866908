import { Actions } from '../../interfaces'
import { User } from '@/entities/public'

import find from './find'
import findLite from './findLite'
import create from './create'

export const actions: Actions = {
  find,
  create,
  findLite,
}

export default {
  Model: { User },
  actions: { [User.name]: actions },
}
