import gql from 'graphql-tag'
import Fragment from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation insertAppraisalResponse($fields: purchase_appraisal_response_insert_input!) {
    record: insert_purchase_appraisal_response_one(object: $fields) {
      ...AppraisalResponseForm
    }
  }
`

export default mutation
