import { Actions } from '../../interfaces'

import { BonusType } from '@/entities/sales'

import fetch from './fetch'
import find from './find'

export const actions: Actions = {
  fetch,
  find,
}

export default {
  Model: { BonusType },
  actions: { [BonusType.name]: actions },
}
