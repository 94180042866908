import { Type } from 'class-transformer'
import { Entity } from '..'

import { Product } from './Product'
import { Agreement } from '../persons'
import { Variant } from './Variant'

export class AgreementProduct extends Entity {
  @Type(() => Product)
  product: Product;

  @Type(() => Agreement)
  agreement: Agreement;

  @Type(() => Variant)
  variants: Variant[];

  active: boolean;

  get description () {
    const { product: { description } } = this
    return description
  }

  get name () {
    const { product: { name } } = this
    return name
  }
}
