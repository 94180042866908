import { Type } from 'class-transformer'
import { Entity } from '..'

import { Offer } from '../loans'
import { AgreementProduct, QuotaCharacteristic } from '.'

export class Variant extends Entity {
  @Type(() => Offer)
  offers: Offer[];

  @Type(() => QuotaCharacteristic)
  quotaCharacteristics: QuotaCharacteristic[];

  @Type(() => AgreementProduct)
  agreementProduct: AgreementProduct

  period: string;
  unit: string;
  active: boolean;
  description: string;
  purchasable: boolean;

  get isSpecial () {
    const { quotaCharacteristics } = this
    return quotaCharacteristics?.find(quotaCharacteristic => quotaCharacteristic?.type === 'special')
  }

  get isRegular () {
    const { quotaCharacteristics } = this

    return quotaCharacteristics?.find(quotaCharacteristic => quotaCharacteristic?.type === 'regular')
  }
}
