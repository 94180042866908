import { ActionTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Roles, UserFunctions } from '@/store/settings/roles/state'

import { Rol } from '@/store/settings/roles/rol'
// @ts-ignore
import { insertPermissions, removePermissions } from './mutation'
import { getRoles, rolPermissions } from '@/store/settings/roles/queries/roles'

const STATUS = [
  { id: '1', name: 'Interno' },
  { id: '2', name: 'Externo' },
]

const AVAILABILITY = [
  { id: '1', name: 'OPERATIVO' },
  { id: '2', name: 'LICENCIA' },
  { id: '3', name: 'VACACIONES' },
  { id: '4', name: 'DESVINCULADO' },
]

const AREA = [
  { id: '1', name: 'Gerencia' },
  { id: '2', name: 'Administración' },
  { id: '3', name: 'Supervisión' },
  { id: '4', name: 'Inspección' },
  { id: '5', name: 'Habilitación' },
  { id: '6', name: 'Limpieza' },
  { id: '7', name: 'Publicaciones' },
  { id: '8', name: 'Atención al cliente' },
  { id: '9', name: 'Compras' },
  { id: '10', name: 'Ventas' },
  { id: '11', name: 'Financiamiento' },
]

export const actions: ActionTree<UserFunctions, RootState> = {
  getRoles: async ({ commit, rootState: { apolloClient } }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { rol: roles },
    } = await apolloClient.query({ query: getRoles })

    const rolesInitialization = roles.map((rol: Roles) => new Rol(rol))
    commit('setRoles', rolesInitialization)
  },
  updateRolesResource: async (
    { rootState: { apolloClient } },
    rol
  ): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { permissions },
    } = await apolloClient.query({
      query: rolPermissions,
      variables: {
        rolId: rol.id,
      },
      fetchPolicy: 'network-only',
    })

    const { add, remove } = handlePermissionChange(permissions, rol)

    if (add.length) {
      add.forEach(value => {
        apolloClient.mutate({
          mutation: insertPermissions,
          variables: {
            rolId: rol.id,
            resourceId: value,
          },
        })
      })
    }
    if (remove.length) {
      remove.forEach(value => {
        apolloClient.mutate({
          mutation: removePermissions,
          variables: {
            permissionId: value,
          },
        })
      })
    }
    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: { message: 'Actualizado correctamente' },
      })
    )
  },
  getAvailability: ({ commit }): void => {
    // TODO: Get availability from DB
    commit('setAvailability', AVAILABILITY)
  },
  getArea: ({ commit }): void => {
    // TODO: Get area from DB
    commit('setArea', AREA)
  },
  getStatus: ({ commit }): void => {
    // TODO: Get status from DB
    commit('setStatus', STATUS)
  },
}

function handlePermissionChange (
  permissions: [{ id: number, resourceId: number }],
  rolUpdated: Rol
) {
  const addResource: (number | undefined)[] = []
  const removeResource: (number | undefined)[] = []

  if (permissions.length) {
    for (const permission of permissions) {
      const isMissing =
        rolUpdated.rol.permissions.findIndex(
          permissionModified =>
            permissionModified.resourceId === permission.resourceId
        ) === -1

      if (isMissing) {
        removeResource.push(permission.id)
      }
    }
  }

  if (rolUpdated.resources.length) {
    for (const resources of rolUpdated.resources) {
      const isMissing =
        permissions.findIndex(
          permission => permission.resourceId === resources
        ) === -1

      if (isMissing) {
        addResource.push(resources)
      }
    }
  }

  return {
    add: addResource,
    remove: removeResource,
  }
}
