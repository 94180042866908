import gql from 'graphql-tag'
import { ComponentValueFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${ComponentValueFragment}
  query componentValue($filter: vehicle_component_value_bool_exp) {
    records: vehicle_component_value(where: $filter) {
      ...ComponentValue
    }
  }

`
export default query
