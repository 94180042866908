import { Actions } from '../../interfaces'

import create from './create'
import fetch from './fetch'
import update from './update'
import { Quota } from '@/entities/loans'

export const actions: Actions = {
  create,
  fetch,
  update,
}

export default {
  Model: { Quota },
  actions: { [Quota.name]: actions },
}
