import { Actions } from '../../interfaces'
import { City } from '@/entities/public'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { City },
  actions: { [City.name]: actions },
}
