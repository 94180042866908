import { Actions } from '../interfaces'
import loggedActions from './loggedActions'

export const Models = {
  ...loggedActions.Model,
}

export const actions: Record<string, Actions> = {
  ...loggedActions.actions,
}

export const schema = { Models, actions }
export default schema
