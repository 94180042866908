import gql from 'graphql-tag'
import { BaseDealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const query = gql`
  ${BaseDealAutoAttributeFragment}
  query fetchDealAutoAttribute($id: Int!) {
    records: deal_auto_attribute_by_pk(id: $id) {
      ...BaseDealAutoAttribute
    }
  }
`
export default query
