import { Type } from 'class-transformer'
import { Entity } from '@/entities'
import { Maintenance } from '.'

export class MaintenanceType extends Entity {
  @Type(() => Maintenance)
  maintenances: Maintenance[]

  name: string;
  description: string;
  mileage: number
}
