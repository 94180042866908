import gql from 'graphql-tag'
import { PersonLaborDataFragment } from '@/graphql/fragments/persons'

export const mutation = gql`
  ${PersonLaborDataFragment}
  mutation createPersonLaborData($fields: persons_labor_data_insert_input!) {
    record: insert_persons_labor_data_one(object: $fields) {
      ...PersonLaborData
    }
  }
`

export default mutation
