import gql from 'graphql-tag'

export const deletePersonReference = gql`
  mutation deletePersonReference($id: Int!) {
    update_persons_reference_person(
      where: { id: { _eq: $id } }
      _set: { active: false }
    ) {
      affected_rows
    }
  }
`

export const createPersonReference = gql`
  mutation createPersonReference(
    $relation: String!
    $referenceId: Int!
    $idPerson: Int!
  ) {
    personReferenceData: createPersonReference(
      createPersonalReferenceInput: {
        generalRelationship: $relation
        idReference: $referenceId
        idPerson: $idPerson
      }
    ) {
      personReference {
        person {
          id
          firstName
          surname
        }
        reference: referencePerson {
          id
          firstName
          surname
          phone: mainPhone
        }
        active
        relation: generalRelation {
          description
        }
        id
      }
      exists
    }
  }
`

export const personReference = gql`
  mutation insert_persons_person_one(
    $phone: String!
    $name: String!
    $surname: String!
    $secondName: String
    $secondSurname: String
  ) {
    person: insert_persons_person_one(
      object: {
        main_phone: $phone
        first_name: $name
        second_name: $secondName
        second_surname: $secondSurname
        surname: $surname
      }
    ) {
      id
    }
  }
`
