import { Actions } from '../../interfaces'
import { InspectionComponent } from '@/entities/purchase'

import update from './update'
import remove from './remove'
import fetch from './fetch'
import find from './find'

export const actions: Actions = {
  find,
  fetch,
  update,
  remove,
}

export default {
  Model: { InspectionComponent },
  actions: { [InspectionComponent.name]: actions },
}
