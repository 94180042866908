import { Auto } from '@/entities/public'
import { removeAccents } from '@/utils/general'
import { AutoAttribute } from '@/entities/vehicle'

export function generateUrl (auto: Auto) {
  const { year } = auto

  let url = 'brand/model/'

  url += '$-combustible/'
  url += '#-transmisi%C3%B3n/'

  if (year) {
    url += year + '-ano/'
  }
  url += 'menos-de-100000km-kilometraje/metropolitana-de-santiago-región/'
  url += '?sort=~Price'

  return url
}

export function buildBasicUrl (url: string, auto: Auto, attributes: any[] = []) {
  const { version: { year, version: { model: { name: modelName, brand: { name } } } } } = auto

  const fuel = attributes?.[0]?.value.toLowerCase()
  const fuelFixed = fuel.length ? `/${removeAccents(fuel)}` : ``
  const transmition = attributes.length ? `/${removeAccents(attributes?.[1]?.value.toLowerCase())}` : ``
  const transmitionFixed = transmition === '/mecanica' ? '/manual' : transmition

  return url.replace('marca', name.toLowerCase()).replace('modelo', modelName.toLowerCase()) + `${fuelFixed}` + `${transmitionFixed}` + `/${year.id.toString()}` + `/_OrderId_PRICE_NoIndex_True`
}

export function buildBasicUrlAttribute (url: string, auto: Auto, attributes: AutoAttribute[] = []) {
  const { version: { year, version: { model: { name: modelName, brand: { name } } } } } = auto

  const fuel = attributes?.find(a => a.component.name === 'Combustible')
  const transmission = attributes?.find(a => a.component.name === 'Transmisión')
  const fuelFixed = fuel ? `/${removeAccents(fuel.componentValue.value)}` : ``
  const transmition = transmission ? `/${removeAccents(transmission?.componentValue.value.toLowerCase())}` : ``
  const transmitionFixed = transmition === '/mecanica' ? '/manual' : transmition

  return url.replace('marca', name.toLowerCase()).replace('modelo', modelName.toLowerCase()) + `${fuelFixed}` + `${transmitionFixed}` + `/${year.id.toString()}` + `/_OrderId_PRICE_NoIndex_True`
}
