import gql from 'graphql-tag'
import { EntityFields } from '..'

export const ProcessFragment = gql`
  fragment Process on settings_process {
    ${EntityFields}
    schema
    tableName: table_name
    description
    config
  }
`
