import gql from 'graphql-tag'

import { ConditionTypeFragment } from '@/graphql/fragments/loans'

export const query = gql`
  ${ConditionTypeFragment}
  query findConditionType($filter: loans_condition_type_bool_exp, $limit: Int) {
    records: loans_condition_type(where: $filter, limit: $limit) {
      ...ConditionType
    }
  }
`

export default query
