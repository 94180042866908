import gql from 'graphql-tag'
import { PersonAccountFragment } from '@/graphql/fragments/persons/personAccount'

export const query = gql`
  ${PersonAccountFragment}
  query findPersonAccounts($filter: persons_person_account_bool_exp, $limit: Int) {
    records: persons_person_account(where: $filter,  limit: $limit)  {
      ...PersonAccount
    }
  }
`
export default query
