import gql from 'graphql-tag'
import { BonusTypeFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${BonusTypeFragment}
  query findSaleOrderBonusType($filter: sales_bonus_type_bool_exp, $order: [sales_bonus_type_order_by!], $limit: Int) {
    records: sales_bonus_type(where: $filter, order_by: $order, limit: $limit) {
      ...BonusType
    }
  }
`
export default query
