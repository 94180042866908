import { Actions } from '../../interfaces'
import { LoggedActions } from '@/entities/audit'
import fetch from './fetch'
import aggregate from './aggregate'
import find from './find'

export const actions: Actions = {
  fetch,
  aggregate,
  find,
}

export default {
  Model: { LoggedActions },
  actions: { [LoggedActions.name]: actions },
}
