import gql from 'graphql-tag'

import { NoteFragment } from '@/graphql/fragments/crm'

export const mutation = gql`
  ${NoteFragment}
  mutation updateDealNote($id: Int!, $fields: crm_deal_note_set_input!) {
    record: update_crm_deal_note_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Note
    }
  }
`

export default mutation
