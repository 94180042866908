import gql from 'graphql-tag'

import { ProjectionPaymentFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${ProjectionPaymentFragment}
  query fetchProjectionPayment($id: Int!) {
    records: sales_projection_payment_by_pk(id: $id) {
      ...ProjectionPayment
    }
  }
`

export default query
