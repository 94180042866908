import gql from 'graphql-tag'
import { AliasFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${AliasFragment}
  query findAlias($filter: alias_bool_exp!, $limit: Int = 6) {
    records: alias(where: $filter, limit: $limit){
      ...Alias
    }
  }
`
export default query
