import axios from 'axios'

const { VUE_APP_BEARER, VUE_APP_AIRTABLE_BASE_ID, VUE_APP_UPLOAD_FILE } = process.env
const config = {
  headers: {
    Authorization: VUE_APP_BEARER || '',
  },
}

export function airtableFieldConstruction (fields) {
  return fields.map(field => `fields[]=${field}`).join('&')
}

export function filterAirtableConstructor (data, field) {
  return `&filterByFormula=AND(${field}='${data}')`
}

export async function searchAirtable ({
  table,
  fields,
  filter,
  all = false,
}) {
  try {
    const { data: { records } } = await axios.get(`https://api.airtable.com/v0/${VUE_APP_AIRTABLE_BASE_ID}/${table}?${fields}${filter}`, config)
    if (all) return records
    return records?.[0]
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export async function insertAirtable ({
  table,
  fields,
}) {
  const keys = Object.keys(fields)

  for (const key of keys) {
    if (!fields[key]) delete fields[key]
  }
  try {
    const { data: { records } } = await axios.post(`https://api.airtable.com/v0/${VUE_APP_AIRTABLE_BASE_ID}/${table}`, fields, config)

    return records?.[0]
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export async function getOrCreateModel (auto) {
  const {
    version: { version: { model: { brand: { name: brandName }, name: modelName } } },
  } = auto

  let brands
  let brand
  let model
  const modelSchema = getSchema('model')
  const brandSchema = getSchema('brand')
  const models = await searchAirtable({
    table: modelSchema.table,
    fields: airtableFieldConstruction(modelSchema.fields),
    filter: filterAirtableConstructor(modelName.toUpperCase(), modelSchema.filter),
    all: true,
  })
  if (!models?.length) {
    brands = await searchAirtable({
      table: brandSchema.table,
      fields: airtableFieldConstruction(brandSchema.fields),
      filter: filterAirtableConstructor(brandName.toUpperCase(), brandSchema.filter),
      all: true,
    })

    if (!brands?.length) {
      const fields = {
        records: [
          {
            fields: {
              Marca: brandName.toUpperCase(),
            },
          },
        ],
      }

      brand = await insertAirtable({
        table: brandSchema.table,
        fields,
      })

      model = await insertAirtable({
        table: modelSchema.table,
        fields: {
          records: [
            {
              fields: {
                Modelo: modelName.toUpperCase(),
                Marca: [brand?.id],
              },
            },
          ],
        },
      })
    } else {
      model = await insertAirtable({
        table: modelSchema.table,
        fields: {
          records: [
            {
              fields: {
                Modelo: modelName.toUpperCase(),
                Marca: [brands?.[0]?.id],
              },
            },
          ],
        },
      })
    }
  } else {
    model = models.find(m => m.fields?.brand?.[0] === brandName.toUpperCase())

    if (!model) {
      brand = await insertAirtable({
        table: brandSchema.table,
        fields: {
          records: [
            {
              fields: {
                Marca: brandName.toUpperCase(),
              },
            },
          ],
        },
      })
      model = await insertAirtable({
        table: modelSchema.table,
        fields: {
          records: [
            {
              fields: {
                Modelo: modelName.toUpperCase(),
                Marca: [brand?.id],
              },
            },
          ],
        },
      })
    }
  }
  return model
}

export async function getOrCreateVehicle (auto) {
  const {
    year: yearVehicle,
    version: { version: { name: versionName } },
    ppu,
  } = auto
  const vehicleSchema = getSchema('vehicle')
  let vehicle = await searchAirtable({
    table: vehicleSchema.table,
    fields: airtableFieldConstruction(vehicleSchema.fields),
    filter: filterAirtableConstructor(ppu.toUpperCase(), vehicleSchema.filter),
  })

  if (!vehicle) {
    const model = await getOrCreateModel(auto)

    const year = await searchYear(yearVehicle)
    vehicle = await insertAirtable({
      table: vehicleSchema.table,
      fields: {
        records: [
          {
            fields: {
              Modelo: [model?.id],
              '🚘 Placa patente': ppu,
              'Año Link': [year?.id],
              Versión: versionName,
            },
          },
        ],
      },
    })
  }

  return vehicle
}

export async function searchYear (year) {
  const yearSchema = getSchema('year')
  return searchAirtable({
    table: yearSchema.table,
    fields: airtableFieldConstruction(yearSchema.fields),
    filter: filterAirtableConstructor(year, yearSchema.filter),
  })
}

export async function getCreatePerson (formData) {
  let person
  const {
    client: {
      mainPhone,
      firstName,
      lastName,
      secondName,
      surname,
      country,
      email: personMail,
      gender,
      uid,
      type,
    },
  } = formData
  const personSchema = getSchema('person')
  person = await searchAirtable({
    table: personSchema.table,
    fields: airtableFieldConstruction(personSchema.fields),
    filter: filterAirtableConstructor(mainPhone.replace('+', '').trim(), personSchema.filter),
  })

  if (!person) {
    const fields = {
      records: [
        {
          fields: {
            '📱Nº Telefónico personal': mainPhone,
            '1° Nombre o razón social': firstName,
            '1° Apellido': lastName,
            '2ª nombre': secondName,
            '2º apellido': surname,
            Nacionalidad: country?.nationality?.toLowerCase(),
            '📧 E-mail personal': personMail?.personal,
            '📫 E-mail laboral': personMail?.work,
            Sexo: gender?.description,
            Rut: uid,
            'Tipo de Persona': type?.description,
          },
        },
      ],
    }

    person = await insertAirtable({
      table: personSchema.table,
      fields,
    })
  }

  return person
}

export const schemaConfig = {
  staging: {
    model: {
      table: 'tbl3IgToCnijS84TD',
      fields: ['fldHuVKigCEee5vfG', 'fldMxduNYk16tAnHf'],
      filter: 'fldCWQ5KyZGE8Xfl2',
    },
    brand: {
      table: 'tblkHcYB9mHUwXhtB',
      fields: ['fldx68NTk7BmGS77k'],
      filter: 'fldx68NTk7BmGS77k',
    },
    vehicle: {
      table: 'tblTfS6ypGt4CfJYg',
      fields: ['fldwnzq5ZBHs2rtTC'],
      filter: 'fldwnzq5ZBHs2rtTC',
    },
    year: {
      table: 'tblZM9Sf6PL8tLfWy',
      fields: ['fldx5AGMa1jEiqauh'],
      filter: 'fldx5AGMa1jEiqauh',
    },
    person: {
      table: 'tblSWc7R5NmkS3Ajb',
      fields: ['fldqcfgauKWUvnNxZ'],
      filter: 'fldqcfgauKWUvnNxZ',
    },
    executive: {
      table: 'tblGAWDm25F4H4qGC',
      fields: ['fld7SrAaA3ToPRJl4'],
      filter: 'fld7SrAaA3ToPRJl4',
    },
    lead: {
      table: 'tblyYg4dKoqCtUgKk',
      fields: ['fldBtYkWcJ95d347e', 'fld96Py5ioyzZLYIl', 'fld0B2l9ZYlw9vgXI'],
      filter: 'fldBtYkWcJ95d347e',
    },
    purchase: {
      table: 'tblGZJXCvz9ytBPyd',
      fields: ['fldwbCX4f8OkkiiUS'],
      filter: 'fldWTp1ceUxfYBT8R',
    },
  },

  production: {
    model: {
      table: 'tblcWN0bJqhCnhHK9',
      fields: ['fldQIsR5nFDxJe86c', 'fldVLKBA5n0pYJ0yL'],
      filter: 'fldLancxF2FXD6Scy',
    },
    brand: {
      table: 'tbltVJ5ogpGd16Uk7',
      fields: ['fldGkFUGraAFb1KYQ'],
      filter: 'fldGkFUGraAFb1KYQ',
    },
    vehicle: {
      table: 'tbl2tpdlwJsn7omPM',
      fields: ['fldFB6xS6EGLxA6K8'],
      filter: 'fldFB6xS6EGLxA6K8',
    },
    year: {
      table: 'tbl80GZ2dSKrYUSN4',
      fields: ['fldGj7Nzh4iXNzNlN'],
      filter: 'fldGj7Nzh4iXNzNlN',
    },
    person: {
      table: 'tbl1aJeEcQlDncdaH',
      fields: ['flduHFnaIu3lid540'],
      filter: 'flduHFnaIu3lid540',
    },
    executive: {
      table: 'tblPOtK998Encd3x8',
      fields: ['fldg6YHXH6SHk0mcA'],
      filter: 'fldg6YHXH6SHk0mcA',
    },
    lead: {
      table: 'tblHcNb0RrpVY3TBQ',
      fields: ['fldKHvrJjM8oIcHYK', 'fldikmFSprxSuUBzR', 'fld9PzsW61kPEETOe'],
      filter: 'fldKHvrJjM8oIcHYK',
    },
    purchase: {
      table: 'tblPdg4pCC8RYKspJ',
      fields: ['fldFp94RmbNDPrVLo'],
      filter: 'fld57W8ZlXwytKwZn',
    },
  },
}

export function getSchema (key) {
  const environment = VUE_APP_UPLOAD_FILE.includes('api.genio.dev') ? 'staging' : 'production'
  return schemaConfig[environment][key]
}
