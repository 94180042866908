import gql from 'graphql-tag'

import { BonusTypeFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${BonusTypeFragment}
  query fetchSaleOrderBonusType($id: Int!) {
    records: sales_bonus_type_by_pk(id: $id) {
      ...BonusType
    }
  }
`

export default query
