import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ProcessStatusFragment } from '../settings/processStatus'
import { NegotiationResponseFragment } from './negotiationResponse'
import { PurchaseOrderFragment } from './purchaseOrder'
import { ClosingReasonFragment } from '../settings'

export const BaseNegotiationFragment = gql`
  fragment NegotiationBase on purchase_negotiation {
    ${EntityFields}
    legalReportQualification:legal_report_qualification
    agreedAmount:agreed_amount
  }
`

export const NegotiationFragment = gql`
  ${BaseNegotiationFragment}
  ${ProcessStatusFragment}
  ${NegotiationResponseFragment}
  ${PurchaseOrderFragment}
  ${ClosingReasonFragment}
  fragment Negotiation on purchase_negotiation {
    ...NegotiationBase
    status {
      ...ProcessStatus
    }
    responses: negotiation_responses (order_by: {created_at: desc_nulls_last}){
      ...NegotiationResponse
    }
    closingReason: closing_reason{
      ...ClosingReason
    }
  }
`
