import gql from 'graphql-tag'

import { Fragment } from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation insertQuota($fields: loans_quota_insert_input!) {
    record: insert_loans_quota_one(object: $fields) {
      ...QuotaFragment
    }
  }
`

export default mutation
