import gql from 'graphql-tag'
import { DocumentIntervenerFragment } from '@/graphql/fragments/documents'

export const query = gql`
  ${DocumentIntervenerFragment}
  query findInterveners($filter: documents_intervener_bool_exp) {
    records: documents_intervener(where: $filter, order_by: {id: desc}) {
      ...DocumentIntervener
    }
  }
`
export default query
