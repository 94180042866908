import gql from 'graphql-tag'
import { DocumentFragment } from '@/graphql/fragments/documents'

export const query = gql`
  ${DocumentFragment}
  query fetchDocument($id: Int!) {
    records: documents_document_by_pk(id: $id) {
      ...Document
    }
  }
`

export default query
