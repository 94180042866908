import gql from 'graphql-tag'

export const createFullAddress = gql`
  mutation createFullAddress(
    $streetName: String!
    $streetNumber: String!
    $propertyNumber: String!
    $idCity: Int!
    $namePropertyType: String!
    $nameStreetType: String!
    $idPerson: Int!
    $nameAddressType: String!
  ) {
    fullAddress: createFullAddress(
      createFullAddressInput: {
        addressInput: {
          streetName: $streetName
          streetNumber: $streetNumber
          propertyNumber: $propertyNumber
          idCity: $idCity
          namePropertyType: $namePropertyType
          nameStreetType: $nameStreetType
        }
        personAddressInput: {
          idPerson: $idPerson
          nameAddressType: $nameAddressType
        }
      }
    ) {
      exists
      address {
        id
        streetName
        streetNumber
        propertyNumber
        idCity
        propertyType {
          name
        }
        streetType {
          name
        }
      }
      personAddress {
        id
        addressType {
          name
        }
        person {
          id
          firstName
          surname
        }
        active
      }
    }
  }
`

export const personAddressUpdateStatus = gql`
  mutation personAddressUpdateStatus($id: Int!) {
    update_persons_person_address(
      where: { id: { _eq: $id } }
      _set: { active: false }
    ) {
      affected_rows
    }
  }
`

export const isAddressRequired = gql`
  subscription isAddressRequired($id: Int!) {
    isAddress: persons_person_address(where: { id_person: { _eq: $id } }) {
      active
      id
    }
  }
`
