import gql from 'graphql-tag'

import { InspectionQualificationFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${InspectionQualificationFragment}
  mutation updateInspectionQualification($id: Int!,$fields: purchase_inspection_qualification_set_input!) {
    record: update_purchase_inspection_qualification_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...InspectionQualification
    }
  }
`

export default mutation
