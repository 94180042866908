import { Actions } from '../../interfaces'

import find from './find'
import { ValidationType } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { ValidationType },
  actions: { [ValidationType.name]: actions },
}
