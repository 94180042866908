import gql from 'graphql-tag'
import { ConcessionaireFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${ConcessionaireFragment}
  query concessionaire($filter: vehicle_concessionaire_bool_exp) {
    records: vehicle_concessionaire(where: $filter) {
      ...Concessionaire
    }
  }
`
export default query
