import gql from 'graphql-tag'

import { NegotiationResponseFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${NegotiationResponseFragment}
  query findNegotiationResponse($filter: purchase_negotiation_response_bool_exp){
    records: purchase_negotiation_response(where: $filter) {
      ...NegotiationResponse
    }
  }
`

export default query
