import gql from 'graphql-tag'
import { DealFragment, LeadFragment } from '@/graphql/fragments/crm'
import { AppraisalFragment } from '@/graphql/fragments/purchase'
import PersonFragment from '../../persons/person/fragment'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const Fragment = gql`
  ${AppraisalFragment}
  ${DealFragment}
  ${LeadFragment}
  ${PersonFragment}
  ${DealAutoAttributeFragment}
  fragment AppraisalForm on purchase_appraisal {
    ...Appraisal
    deal {
      ...Deal
      lead {
        ...Lead
        client {
          ...PersonForm
        }
      }
      autoAttributes:auto_attributes{
        ...DealAutoAttribute
      }
    }
  }
`
export default Fragment
