import gql from 'graphql-tag'
import { EnablementFragment } from '@/graphql/fragments/purchase'
import { StockViewDetailFragment } from '@/graphql/fragments/sales'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${EnablementFragment}
  ${StockViewDetailFragment}
  ${ProcessStatusFragment}
  query fetchEnablement($id: Int!) {
    records: purchase_enablement_by_pk(id: $id) {
      ...Enablement
      stock{
        id
        status {
          ...ProcessStatus
        }
      }
      idInspection:id_inspection
      inspectionView:inspection_detail(where: {inspection_component: {_or: [{component: {category: {name: {_in: ["Documentación","Identificación"]}}}}, {component_value: {value: {_eq: "Llaves"}}}]}}) {
        id
        idInspectedComponent:id_inspected_component
        date
        idAuto:id_auto
        component{
          id
          inspectionComponent:inspection_component {
            id
            component {
              category {
                id
                name
              }
              id
              name
              slug
            }
            componentValue:component_value {
              id
              value
            }
            config
          }
        }
        parameter:parameter {
          id
          order
          name
        }
        assessment {
          id
          option {
            id
            name
          }
        }
        value:qualification_value
      }
    }
  }
`

export default query
