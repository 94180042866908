import gql from 'graphql-tag'

import { SaleOrderItemFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${SaleOrderItemFragment}
  query fetchSaleOrderItem($id: Int!) {
    records: sales_sale_order_item_by_pk(id: $id) {
      ...SaleOrderItem
    }
  }
`

export default query
