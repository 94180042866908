import gql from 'graphql-tag'
import { VueApolloClient } from '@/plugins/apollo'
import { IRole, Role } from '@/store/user/models'

const userRoles = gql`
  query userRoles($id: Int!) {
    roles(where:{user:{id:{_eq:$id}}}) {
      rol {
        id
        name
        slug
        description
        icon(path: "name")
      }
    }
  }
`

const userId = gql`
  query userId($email: String!) {
    user(where: {email: {_eq: $email}}) {
      id
    }
  }
`

export async function getUserRoles (client: VueApolloClient, id): Promise<Array<Role>> {
  if (!client) return []

  const {
    data: { roles },
  } = await client.query({
    query: userRoles,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })

  return (roles as Array<{ rol: IRole }>).map(({ rol }) => new Role(rol))
}

export async function getUserId (client: VueApolloClient, { email }): Promise<number> {
  if (!client) return null

  const {
    data: {
      user,
    },
  } = await client.query({ query: userId, variables: { email } })

  return user.length ? user[0].id : null
}
