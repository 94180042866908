import gql from 'graphql-tag'
import { ProcessTrafficTicketFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${ProcessTrafficTicketFragment}
  mutation updatePurchaseTrafficTicket($id: Int!, $fields: purchase_process_traffic_ticket_set_input!) {
    record: update_purchase_process_traffic_ticket_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...ProcessTrafficTicket
    }
  }
`

export default mutation
