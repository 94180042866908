import gql from 'graphql-tag'
import Fragment from '@/graphql/forms/sales/stock/fragment'

export const mutation = gql`
  ${Fragment}
  mutation updateStock($id: Int!,$fields: sales_stock_set_input!) {
    record: update_sales_stock_by_pk(pk_columns: {id: $id}, _set: $fields){
      id
    }
  }
`

export default mutation
