import gql from 'graphql-tag'
import { ExternalOfferFragment } from '@/graphql/fragments/purchase'

export const Fragment = gql`
  ${ExternalOfferFragment}
  fragment AppraisalExternalOfferForm on purchase_appraisal_external_offer
  {
    ...ExternalOffer
  }
`
export default Fragment
