import { Actions } from '../../interfaces'
import { AppraisalResponse } from '@/entities/purchase'

import create from './create'
import fetch from './fetch'
import update from './update'

export const actions: Actions = {
  create,
  fetch,
  update,
}

export default {
  Model: { AppraisalResponse },
  actions: { [AppraisalResponse.name]: actions },
}
