import gql from 'graphql-tag'

import Fragment from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation insertDeal($fields: crm_deal_insert_input!) {
    record: insert_crm_deal_one(object: $fields) {
      ...DealForm
    }
  }
`

export default mutation
