import gql from 'graphql-tag'
import { PipelineFragment } from '@/graphql/fragments/crm'
import { AggregatePath } from '@/entities/interfaces'

const structure: Array<AggregatePath> = [
  { name: 'leads', title: 'Leads', aggregations: ['count'] },
]
const query = gql`
  ${PipelineFragment}
  query aggregates($model: crm_pipeline_bool_exp, $aggregate: crm_lead_bool_exp, $order: [crm_pipeline_order_by!]) {
    aggregates: crm_pipeline(where: $model, order_by: $order) {
      ...Pipeline
      leads: leads_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`
export const aggregate = { structure, query }
export default aggregate
