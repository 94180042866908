import { Type } from 'class-transformer'
import { Entity } from '..'

import { Brand, ConcessionaireDetails } from '.'
import { Person } from '../persons'

export class Concessionaire extends Entity {
  @Type(() => Brand)
  brand: Brand;

  @Type(() => Person)
  person: Person;

  @Type(() => ConcessionaireDetails)
  details: ConcessionaireDetails[];
}
