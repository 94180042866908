import gql from 'graphql-tag'
import { EntityFields } from '..'
import { FileTypeFragment } from '@/graphql/fragments/files/fileType'

export const FileParameterFragment = gql`
  ${FileTypeFragment}
  fragment FileParameter on files_file_parameter{
    ${EntityFields}
    name
    multiple
    description
    required
    visibility
    fileType:file_type {
      ...FileType
    }
  }
`
