<template>
  <svg
    width="221"
    height="221"
    viewBox="0 0 221 221"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_630_11828)">
      <path
        d="M14 106.5C14 53.2045 57.2045 10 110.5 10C163.795 10 207 53.2045 207 106.5C207 159.795 163.795 203 110.5 203C57.2045 203 14 159.795 14 106.5Z"
      />
      <path
        d="M135.42 113.068L140.53 106.301L144.397 110.168C146.192 111.964 149.093 112.102 151.026 110.168L164.284 96.9099C166.08 95.1145 166.08 92.0761 164.284 90.2808L126.719 52.7157C124.786 50.7822 121.886 50.9203 120.09 52.7157L106.832 65.974C104.898 67.9075 104.898 70.6696 106.832 72.6031L110.561 76.332L103.794 81.4419C92.6069 79.3703 81.5584 82.4087 73.6863 90.2808C71.7528 92.2142 71.7528 94.9764 73.6863 96.9099L93.5737 116.797L79.2106 131.16C79.0725 131.298 78.9343 131.437 78.7962 131.575L74.3768 140.414C73.6863 141.656 75.2055 143.176 76.4484 142.485L85.2873 138.066C85.4254 137.928 85.7016 137.928 85.8397 137.789L100.203 123.426L120.09 143.314C121.886 145.109 124.786 145.247 126.719 143.314C134.591 135.442 137.492 124.255 135.42 113.068ZM83.9062 93.8715C90.2591 89.7283 98.5455 89.1759 105.865 91.7999L124.786 77.2987L116.776 69.2885L123.405 62.6594L154.341 93.5953L147.712 100.224L139.701 92.2142L125.2 111.135C127.686 118.316 127.134 126.603 122.99 132.956L83.9062 93.8715Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_630_11828"
        x="0"
        y="0"
        width="221"
        height="221"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.380392 0 0 0 0 0.521569 0 0 0 0 0.858824 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_630_11828"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_630_11828"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
<style scoped>
.custom-icon {
  fill: currentColor;
}
</style>
