import { MaintenanceBackupTypeFragment } from '@/graphql/fragments/public'
import gql from 'graphql-tag'

export const query = gql`
  ${MaintenanceBackupTypeFragment}
  query findMaintenanceBackupType($filter: maintenance_backup_type_bool_exp!) {
    records: maintenance_backup_type(where: $filter) {
      ...MaintenanceBackupType
    }
  }
`
export default query
