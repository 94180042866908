import gql from 'graphql-tag'

import { ReserveFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${ReserveFragment}
  mutation updateReserve($id: Int!,$fields: sales_reserve_set_input!) {
    record: update_sales_reserve_by_pk(pk_columns: {id: $id}, _set: $fields){
      ...Reserve
      saleOrder:sale_order {
        id
      }
    }
  }
`

export default mutation
