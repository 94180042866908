import gql from 'graphql-tag'
import { BaseDocumentFragment } from '@/graphql/fragments/documents'

export const mutation = gql`
  ${BaseDocumentFragment}
  mutation insertDocument($fields: documents_document_insert_input!) {
    record: insert_documents_document_one(object: $fields) {
      ...BaseDocument
    }
  }
`

export default mutation
