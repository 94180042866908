import { Type } from 'class-transformer'
import { Entity } from '..'

import { AddressType, Address, Person } from '.'
import { Inspection } from '../purchase'

export class PersonAddress extends Entity {
  @Type(() => AddressType)
  type: AddressType;

  @Type(() => Address)
  address: Address;

  @Type(() => Person)
  person: Person;

  @Type(() => Inspection)
  inspections: Inspection[];

  active: boolean;
  default: boolean;

  get icon () {
    const { type: { icon } } = this
    return icon
  }

  toString () {
    const { address } = this
    return address.toString()
  }
}
