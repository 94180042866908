import gql from 'graphql-tag'
import { ChannelFragment } from '@/graphql/fragments/crm'
import { AggregatePath } from '@/entities/interfaces'

const structure: Array<AggregatePath> = [
  { name: 'deals', title: 'Origen', aggregations: ['count'] },
]
const query = gql`
  ${ChannelFragment}
  query aggregates($model: crm_channel_bool_exp, $aggregate: crm_deal_bool_exp, $order: [crm_channel_order_by!]) {
    aggregates: crm_channel(where: $model, order_by: $order) {
      ...Channel
      deals: deals_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`
export const aggregate = { structure, query }
export default aggregate
