import { Actions } from '../../interfaces'
import { Roles } from '@/entities/public'

import find from './find'
import remove from './remove'
import create from './create'

export const actions: Actions = {
  find,
  remove,
  create,
}

export default {
  Model: { Roles },
  actions: { [Roles.name]: actions },
}
