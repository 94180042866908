import gql from 'graphql-tag'
import { EntityFields } from '..'
import { DealFragment } from '@/graphql/fragments/crm/deal'

export const NoteFragment = gql`
  ${DealFragment}
  fragment Note on crm_deal_note {
    ${EntityFields}
    note
    idDeal:id_deal
    deal {
      ...Deal
    }
  }
`
