import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { Files, getters, initState } from './state'
import { mutations } from './mutations'
import { actions } from './actions'

const module: Module<Files, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
