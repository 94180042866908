import gql from 'graphql-tag'

import { EntityFields } from '..'
import { BonusFragment } from '.'

export const BasePriceFragment = gql`
  fragment BasePrice on sales_price{
    ${EntityFields}
    amount
    margin
    comment
    expiration
    postComment: post_comment
  }
`

export const PriceFragment = gql`
  ${BasePriceFragment}
  ${BonusFragment}
  fragment Price on sales_price{
    ...BasePrice
    bonuses: bonus {
      ...Bonus
    }
  }
`
