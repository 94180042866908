<template>
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_508_4358)">
      <path
        d="M17.801 8.83007H8.90097C8.74583 8.82984 8.59243 8.86272 8.45102 8.92653C8.30961 8.99034 8.18345 9.0836 8.08097 9.20007C8.04622 9.25969 8.01933 9.32355 8.00097 9.39007V9.45007C7.91975 9.60047 7.87844 9.76917 7.88097 9.94007V16.6201C7.88033 16.78 7.91805 16.9377 7.99097 17.0801C8.07647 17.2739 8.21658 17.4385 8.39418 17.554C8.57177 17.6694 8.77915 17.7306 8.99097 17.7301H17.891C18.1854 17.7301 18.4677 17.6131 18.6759 17.405C18.884 17.1968 19.001 16.9145 19.001 16.6201V9.94007C19.0015 9.78641 18.9701 9.63432 18.9087 9.49343C18.8474 9.35253 18.7575 9.22591 18.6447 9.12157C18.5319 9.01722 18.3987 8.93744 18.2534 8.88726C18.1082 8.83709 17.9541 8.81761 17.801 8.83007ZM17.801 12.7201H8.90097V11.0601H17.801V12.7201Z"
      />
      <path
        d="M7.28 9.48999C7.23911 9.63646 7.21891 9.78793 7.22 9.93999V16.57H0.57C0.418827 16.57 0.273845 16.5099 0.166949 16.403C0.0600534 16.2961 0 16.1512 0 16L0 14C0 11.73 3 9.89999 7 9.51999L7.28 9.48999Z"
      />
      <path
        d="M12.5703 3.99998C12.5703 5.06085 12.1489 6.07826 11.3987 6.82841C10.6486 7.57855 9.63118 7.99998 8.57031 7.99998C8.3219 8.00196 8.07394 7.9785 7.83031 7.92998C7.08392 7.78947 6.39296 7.43937 5.83823 6.92062C5.28349 6.40187 4.88791 5.7359 4.69773 5.0006C4.50756 4.26529 4.53066 3.49104 4.76433 2.76838C4.998 2.04572 5.43258 1.40452 6.01725 0.91976C6.60192 0.434999 7.31253 0.126712 8.06596 0.0309491C8.8194 -0.0648138 9.58453 0.0559054 10.2719 0.378991C10.9592 0.702076 11.5404 1.21417 11.9474 1.8554C12.3544 2.49663 12.5705 3.24048 12.5703 3.99998Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_508_4358">
        <rect width="18.91" height="17.73" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
