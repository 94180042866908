import gql from 'graphql-tag'
import { ActivityFragment, DealFragment, LeadFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${ActivityFragment}
  ${LeadFragment}
  ${DealFragment}
  query fetchLeadActivity($id: Int!) {
    records: crm_lead_activity_by_pk(id: $id) {
      ...Activity
      lead{
        ...Lead
        deals{
          ...Deal
        }
      }
    }
  }
`

export default query
