import gql from 'graphql-tag'
import { BaseDealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const mutation = gql`
  ${BaseDealAutoAttributeFragment}
  mutation updateDealAutoAttribute($id: Int!, $fields: deal_auto_attribute_set_input!) {
    record: update_deal_auto_attribute_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...BaseDealAutoAttribute
    }
  }
`

export default mutation
