import { Type } from 'class-transformer'
import { Entity } from '..'

import { ProcessStatus, Status } from '../settings'
import { Deal } from '../crm'
import { FileProcess } from '../files'
import { TrafficTicket } from '../public'

export class ProcessTrafficTicket extends Entity {
  @Type(() => Deal)
  deal: Deal;

  @Type(() => FileProcess)
  fileProcess: FileProcess;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => TrafficTicket)
  trafficTicket: TrafficTicket;

  get statusName (): string {
    const { status } = this

    return status?.name
  }

  get isToConfirm () {
    const { statusName } = this
    return statusName === Status.toConfirm
  }

  get isUnpaid () {
    const { statusName } = this
    return statusName === Status.unpaid
  }

  get isPending () {
    const { statusName } = this
    return statusName === Status.pending
  }

  get exist () {
    const { isToConfirm, isUnpaid, isPending } = this

    if (isPending) {
      return null
    }

    if (isUnpaid) {
      return false
    }

    return isToConfirm
  }
}
