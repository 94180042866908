import gql from 'graphql-tag'

import { EnablementFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${EnablementFragment}
  query findEnablement($filter: purchase_enablement_bool_exp){
    records: purchase_enablement(where: $filter) {
      ...Enablement
    }
  }
`

export default query
