import { Actions } from '../interfaces'

import person from './person'
import personType from './personType'
import gender from './gender'
import maritalStatus from './maritalStatus'
import bankData from './bankData'
import addressType from './addressType'
import address from './address'
import personAddress from './personAddress'
import agreement from './agreement'
import responsibleAgreement from './responsibleAgreement'
import personAccount from './personAccount'
import companyType from './companyType'
import laborData from './laborData'

export const Models = {
  ...person.Model,
  ...personType.Model,
  ...gender.Model,
  ...maritalStatus.Model,
  ...bankData.Model,
  ...addressType.Model,
  ...address.Model,
  ...personAddress.Model,
  ...agreement.Model,
  ...responsibleAgreement.Model,
  ...personAccount.Model,
  ...companyType.Model,
  ...laborData.Model,
}

export const actions: Record<string, Actions> = {
  ...person.actions,
  ...personType.actions,
  ...gender.actions,
  ...maritalStatus.actions,
  ...bankData.actions,
  ...addressType.actions,
  ...address.actions,
  ...personAddress.actions,
  ...agreement.actions,
  ...responsibleAgreement.actions,
  ...personAccount.actions,
  ...companyType.actions,
  ...laborData.actions,
}

export const schema = { Models, actions }
export default schema
