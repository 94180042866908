import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const query = gql`
  ${Fragment}
  query fetchPurchase($id: Int!) {
    records: purchase_purchase_order_by_pk(id: $id) {
      ...PurchaseForm
    }
  }
`

export default query
