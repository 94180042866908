<template>
  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0833 2.75C9.11087 2.75 8.17824 3.13631 7.49061 3.82394C6.80298 4.51157 6.41667 5.44421 6.41667 6.41667C6.41667 7.38913 6.80298 8.32176 7.49061 9.00939C8.17824 9.69703 9.11087 10.0833 10.0833 10.0833C11.0558 10.0833 11.9884 9.69703 12.6761 9.00939C13.3637 8.32176 13.75 7.38913 13.75 6.41667C13.75 5.44421 13.3637 4.51157 12.6761 3.82394C11.9884 3.13631 11.0558 2.75 10.0833 2.75ZM10.0833 11.9167C6.03167 11.9167 2.75 13.5575 2.75 15.5833V17.4167H11.4583C11.1558 16.6905 11 15.9117 11 15.125C11.0039 14.0381 11.3051 12.973 11.8708 12.045C11.2933 11.9717 10.7067 11.9167 10.0833 11.9167Z" />
    <path d="M12.833 16.7758H13.933C13.933 17.3698 14.6865 17.8758 15.583 17.8758C16.4795 17.8758 17.233 17.3698 17.233 16.7758C17.233 16.1708 16.661 15.9508 15.451 15.6593C14.285 15.3678 12.833 15.0048 12.833 13.4758C12.833 12.4913 13.6415 11.6553 14.758 11.3748V10.1758H16.408V11.3748C17.5245 11.6553 18.333 12.4913 18.333 13.4758H17.233C17.233 12.8818 16.4795 12.3758 15.583 12.3758C14.6865 12.3758 13.933 12.8818 13.933 13.4758C13.933 14.0808 14.505 14.3008 15.715 14.5923C16.881 14.8838 18.333 15.2468 18.333 16.7758C18.333 17.7603 17.5245 18.5963 16.408 18.8768V20.0758H14.758V18.8768C13.6415 18.5963 12.833 17.7603 12.833 16.7758Z" />
  </svg>

</template>

<script>
  export default {}
</script>
