import { Items } from '@/utils/generalInterface'
import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'

export interface Catalogue {
  years: Array<Items>
  brands: Array<Items>
  models: Array<Items>
  versions: Array<Items>
  attributes: Array<Items>
  skus: Array<Items>
  versionYear: number | null
  equipment: Array<Items>
}

export function initState (): Catalogue {
  return {
    brands: [],
    models: [],
    versions: [],
    years: [],
    attributes: [],
    skus: [],
    versionYear: null,
    equipment: [],
  }
}

export const mutations: MutationTree<Catalogue> = {
  setBrands: (state, brands) => {
    state.brands = brands
  },
  setModels: (state, models) => {
    state.models = models
  },
  setVersions: (state, versions) => {
    state.versions = versions
  },
  setVersionYear: (state, versionYear) => {
    state.versionYear = versionYear
  },
  setYears: (state, years) => {
    state.years = years
  },
  setAttributes: (state, attributes) => {
    state.attributes = attributes
  },
  setSkus: (state, skus) => {
    state.skus = skus
  },
  setEquipment: (state, equipment) => {
    state.equipment = equipment
  },
}

export const getters: GetterTree<Catalogue, RootState> = {
  brands: ({ brands }): Array<Items> => {
    return brands
  },
  models: ({ models }): Array<Items> => {
    return models
  },
  versions: ({ versions }): Array<Items> => {
    return versions
  },
  years: ({ years }): Array<Items> => {
    return years
  },
  attributes: ({ attributes }): Array<Items> => {
    return attributes
  },
  skus: ({ skus }): Array<Items> => {
    return skus
  },
  versionYear: ({ versionYear }): number | null => {
    return versionYear
  },
  equipment: ({ equipment }): Array<Items> => {
    return equipment
  },
}
