import { Actions } from '../../interfaces'
import { AddressType } from '@/entities/persons'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { AddressType },
  actions: { [AddressType.name]: actions },
}
