import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const query = gql`
  ${Fragment}
  query fetchQuota($id: Int!) {
    records: loans_quota_by_pk(id: $id) {
      ...QuotaFragment
    }
  }
`

export default query
