import gql from 'graphql-tag'

export const getRoles = gql`
  query getRoles {
    rol {
      id
      description
      name
      slug
      roles {
        userId
      }
      permissions {
        resourceId
        id
      }
    }
  }
`

export const rolPermissions = gql`
  query rolPermissions($rolId: Int!) {
    permissions(where: { rolId: { _eq: $rolId } }) {
      id
      resourceId
    }
  }
`
