import gql from 'graphql-tag'
import Fragment from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation insertAppraisalExternalOffer($fields: purchase_appraisal_external_offer_insert_input!) {
    record: insert_purchase_appraisal_external_offer_one(object: $fields) {
      ...AppraisalExternalOfferForm
    }
  }
`

export default mutation
