import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const BaseExpenseTypeFragment = gql`
  fragment ExpenseTypeBase on settings_expense_type {
    ${EntityFields}
    ${EnumFields}
  }
`

export const ExpenseTypeFragment = gql`
  ${BaseExpenseTypeFragment}
  fragment ExpenseType on settings_expense_type {
    ...ExpenseTypeBase
  }
`
