import gql from 'graphql-tag'
import { ResourceFragment, ResourceGroupFragment } from '../../fragments/public'

export const query = gql`
  ${ResourceFragment}
  ${ResourceGroupFragment}
  query userResources {
    resources: resource(order_by: {order: asc_nulls_last}) {
      ...Resource
      group {
        ...ResourceGroup
      }
    }
  }
`
export default query
