import gql from 'graphql-tag'
import { ActivityFragment, LeadFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${LeadFragment}
  ${ActivityFragment}
  query findLeadActivity($filter: crm_lead_activity_bool_exp, $order: [crm_lead_activity_order_by!], $limit: Int, $offset: Int) {
    records: crm_lead_activity(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...Activity
    }
  }
`

export default query
