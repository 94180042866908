import { Type } from 'class-transformer'
import { Entity } from '..'

import { Person, GeneralRelation } from '.'

export class ReferencePerson extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => GeneralRelation)
  generalRelation: GeneralRelation;

  active: boolean;
}
