<template>
  <svg width="22" height="22" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1952_87448)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.76335 14.935V13.5536C4.76335 13.3567 4.76335 12.7593 4.11613 12.7593C3.70909 12.7593 3.43232 13.0396 3.43232 13.5492V14.935H2.94531V11.543L3.43232 11.544V12.6632H3.44218C3.67626 12.3236 4.06504 12.3236 4.16747 12.3236C4.57083 12.3236 4.89872 12.4933 5.08625 12.7779C5.18897 12.9431 5.24601 13.1454 5.24931 13.4298V14.935H4.76335Z" />
      <mask
        id="mask0_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1952_87448)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.40234 14.9645H6.86234V11.5508H6.40234V14.9645Z" />
      </g>
      <mask
        id="mask1_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask1_1952_87448)">
        <path d="M5.58594 14.9645H6.04594V12.4086H5.58594V14.9645ZM5.58594 12.1136H6.04594V11.5508H5.58594V12.1136Z" />
      </g>
      <mask
        id="mask2_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask2_1952_87448)">
        <path d="M9.19708 13.5647C9.17434 13.217 8.84335 12.8693 8.39691 12.8693C7.94942 12.8693 7.61908 13.217 7.59681 13.5647H9.19708ZM7.59681 13.9082C7.7131 14.3677 8.09737 14.5562 8.41058 14.5562C8.75412 14.5562 9.00433 14.3629 9.11605 14.1573H9.58124C9.34427 14.7708 8.77636 14.9644 8.39219 14.9644C7.69058 14.9644 7.13184 14.3846 7.13184 13.7106C7.13184 12.9981 7.70375 12.4609 8.40151 12.4609C9.07215 12.4609 9.65741 12.9851 9.66184 13.6806C9.66184 13.7578 9.6532 13.8782 9.64838 13.9082H7.59681Z" />
      </g>
      <mask
        id="mask3_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask3_1952_87448)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00804 13.3151C1.93207 13.1651 1.74936 12.8685 1.30129 12.8685C0.690758 12.8685 0.474031 13.3488 0.474031 13.7106C0.474031 14.1228 0.749407 14.5566 1.29134 14.5566C1.64564 14.5566 1.88525 14.3639 2.00328 14.1182H2.53195C2.34155 14.6463 1.85135 14.9644 1.29134 14.9644C0.465318 14.9644 0.00195312 14.3248 0.00195312 13.7106C0.00195312 13.0651 0.501598 12.4609 1.29569 12.4609C2.00804 12.4609 2.43681 12.9627 2.53195 13.3151H2.00804Z" />
      </g>
      <mask
        id="mask4_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask4_1952_87448)">
        <path d="M11.1554 12.8681C10.6045 12.8681 10.3381 13.3452 10.3381 13.718C10.3381 14.1485 10.6776 14.5561 11.1508 14.5561C11.6204 14.5561 11.9824 14.1732 11.9824 13.718C11.9824 13.2116 11.6166 12.8681 11.1554 12.8681ZM11.9738 14.9035V14.5337H11.9657C11.7933 14.8263 11.4795 14.9644 11.1554 14.9644C10.3513 14.9644 9.89062 14.3497 9.89062 13.7235C9.89062 13.1425 10.2991 12.4609 11.1554 12.4609C11.4878 12.4609 11.7933 12.5973 11.9657 12.859H11.9738V12.5246H12.4206V14.9035H11.9738Z" />
      </g>
      <mask
        id="mask5_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask5_1952_87448)">
        <path d="M18.1716 12.8681C17.6304 12.8681 17.2704 13.2839 17.2704 13.7109C17.2704 14.1389 17.635 14.5561 18.1716 14.5561C18.6888 14.5561 19.072 14.1568 19.072 13.7053C19.072 13.284 18.7113 12.8681 18.1716 12.8681ZM18.1716 14.9644C17.3126 14.9644 16.791 14.2987 16.791 13.6962C16.791 13.0482 17.3768 12.4609 18.1716 12.4609C18.9484 12.4609 19.551 13.0269 19.551 13.7053C19.551 14.285 19.0386 14.9644 18.1716 14.9644Z" />
      </g>
      <mask
        id="mask6_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask6_1952_87448)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3687 12.4609V13.7828C13.3687 14.3869 13.6543 14.5455 14.015 14.5455C14.4179 14.5455 14.694 14.3031 14.694 13.77V12.4609H15.1818V14.9019H14.7373V14.6366H14.7275C14.5965 14.8491 14.291 14.9644 13.9639 14.9644C13.6681 14.9644 13.4101 14.8759 13.2238 14.7225C13.0074 14.5455 12.8818 14.273 12.8818 13.7828V12.4609H13.3687Z" />
      </g>
      <mask
        id="mask7_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask7_1952_87448)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1337 13.1342C20.1383 13.0378 20.2217 12.8442 20.5174 12.8442C20.8149 12.8442 20.9136 13.09 20.9257 13.1693L21.3705 13.1708C21.3705 12.9848 21.1742 12.4609 20.5208 12.4609C19.8685 12.4609 19.7175 12.9094 19.7073 13.1178C19.6715 13.8917 20.9865 13.7544 20.9639 14.2565C20.9597 14.35 20.8666 14.5751 20.488 14.5751C20.1174 14.5751 20 14.2764 19.9911 14.1559H19.5508C19.5749 14.3895 19.7474 14.9644 20.494 14.9644C21.2386 14.9644 21.3827 14.4504 21.39 14.2595C21.4271 13.453 20.1174 13.5023 20.1337 13.1342Z" />
      </g>
      <mask
        id="mask8_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask8_1952_87448)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3515 14.9645H15.8481V12.8471H15.4121V12.4088H15.8481V11.5508H16.3515V12.4088H16.7921V12.8471H16.3515V14.9645Z" />
      </g>
      <mask
        id="mask9_1952_87448"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="7"
        width="23"
        height="8"
      >
        <path d="M0.00195312 14.9654H22.082V7H0.00195312V14.9654Z" fill="white" />
      </mask>
      <g mask="url(#mask9_1952_87448)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5371 11.7793C19.6018 10.0541 16.1753 9.90737 16.1419 9.90687L15.9875 9.90293L15.8738 9.80172C15.8516 9.78271 13.6191 7.81758 10.5211 7.81758C6.82622 7.81758 4.31712 10.5098 4.29216 10.5379L4.15163 10.688L0.231445 10.3203L0.35478 9.52395L3.81722 9.83782C4.44293 9.22881 6.93618 7 10.5211 7C13.5681 7 15.7919 8.6719 16.3178 9.09865C17.0436 9.14185 20.1382 9.4304 22.0814 11.1613L21.5371 11.7793Z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1952_87448">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>

</template>

<script>
  export default {}
</script>
