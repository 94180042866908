import gql from 'graphql-tag'
import { BaseDocumentFragment } from '@/graphql/fragments/documents'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${BaseDocumentFragment}
  ${PersonFragment}
  query fetchLiteDocument($id: Int!) {
    records: documents_document_by_pk(id: $id) {
      ...BaseDocument
      documentType:type{
        id
      }
      interveners{
        id
        field {
          id
          name
        }
        person{
          ...Person
        }
      }
    }
  }
`

export default query
