import { Type } from 'class-transformer'
import { EnumEntity } from '..'

import { Deal, ActivityType } from '.'
import { DealTypeEnum } from '@/graphql'

export class DealType extends EnumEntity {
  public static readonly sale: DealTypeEnum = 'sale'
  public static readonly purchase: DealTypeEnum = 'purchase'

  @Type(() => ActivityType)
  activityTypes: ActivityType[];

  @Type(() => Deal)
  deals: Deal[];

  declare name: DealTypeEnum
}
