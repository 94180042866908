import gql from 'graphql-tag'
import { ProjectionPaymentFragment } from '@/graphql/fragments/sales/projectionPayment'

export const query = gql`
  ${ProjectionPaymentFragment}
  query findProjectionPayment($filter: sales_projection_payment_bool_exp, $order: [sales_projection_payment_order_by!], $limit: Int) {
    records: sales_projection_payment(where: $filter, order_by: $order, limit: $limit) {
      ...ProjectionPayment
    }
  }
`
export default query
