import gql from 'graphql-tag'
import { AliasFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${AliasFragment}
  query fetchAlias($id: Int!) {
    records: alias_by_pk(id: $id) {
      ...Alias
    }
  }
`
export default query
