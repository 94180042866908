import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ProductFragment } from '@/graphql/fragments/settings/product'

export const BaseAgreementProductFragment = gql`
  fragment BaseAgreementProduct on settings_agreement_product{
    ${EntityFields}
    active
  }
`

export const AgreementProductFragment = gql`
  ${BaseAgreementProductFragment}
  ${ProductFragment}
  fragment AgreementProduct on settings_agreement_product{
    ...BaseAgreementProduct
    product {
      ...Product
    }
    agreement {
      id
      person{
        id
        alias
      }
    }
  }
`
