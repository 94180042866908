import gql from 'graphql-tag'
import { BusinessApproachFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${BusinessApproachFragment}
  query findBusinessApproach($filter: settings_business_approach_bool_exp, $order: [settings_business_approach_order_by!], $limit: Int){
    records: settings_business_approach(where: $filter, order_by: $order, limit: $limit) {
      ...BusinessApproach
    }
  }
`

export default query
