import gql from 'graphql-tag'
import { ProcessFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ProcessFragment}
  query findSettingsProcess($filter: settings_process_bool_exp, $order: [settings_process_order_by!]){
    records: settings_process(where:$filter, order_by: $order) {
      ...Process
      statuses(where: {status: {name: {_eq: "closed"}}}) {
        closingReasons:closing_reasons {
          type {
            id
            name
            description
          }
        }
      }
    }
  }
`

export default query
// TODO: Fix this please
