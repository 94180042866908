import gql from 'graphql-tag'
import { AddressFragment } from '@/graphql/fragments/persons/address'

export const query = gql`
  ${AddressFragment}
  query findAddress($filter: persons_address_bool_exp) {
    records: persons_address(where: $filter) {
      ...Address
    }
  }
`
export default query
