import { Type } from 'class-transformer'
import { Entity } from '..'

import { ProcessStatus } from '.'

export class AllowedStatus extends Entity {
  @Type(() => ProcessStatus)
  from: ProcessStatus;

  @Type(() => ProcessStatus)
  to: ProcessStatus;

  get status () {
    const { to } = this
    return to
  }

  get previous () {
    const { from } = this
    return from
  }
}
