import { Actions } from '../../interfaces'
import { StockAdvertiser } from '@/entities/sales'

import find from './find'
import fetch from './fetch'

export const actions: Actions = {
  find,
  fetch,
}

export default {
  Model: { StockAdvertiser },
  actions: { [StockAdvertiser.name]: actions },
}
