import { Actions } from '../../interfaces'

import { FinancedItem } from '@/entities/loans'

import find from './find'
import create from './create'

export const actions: Actions = {
  find,
  create,
}

export default {
  Model: { FinancedItem },
  actions: { [FinancedItem.name]: actions },
}
