import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const RotationIndexFragment = gql`
  fragment RotationIndex on vehicle_rotation_index {
    ${EntityFields}
    ${EnumFields}
    value
    color
    icon
    marketPricePercentage: market_price_percentage
  }
`
