import axios from 'axios'

// Función para detectar el tipo de dispositivo
export function getDeviceType (): string {
  const userAgent = navigator.userAgent || navigator.vendor

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS'
  }

  if (/Windows Phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  return 'Web'
}

// Función para detectar el tipo de navegador
function getBrowserType () {
  const userAgent = navigator.userAgent

  if (/OPR\/|Opera\//.test(userAgent)) {
    return 'Opera'
  }

  if (/Edg\//.test(userAgent)) {
    return 'Edge'
  }

  if (/Chrome\//.test(userAgent)) {
    return 'Chrome'
  }

  if (/Safari\//.test(userAgent) && !/Chrome\//.test(userAgent)) {
    return 'Safari'
  }

  if (/Firefox\//.test(userAgent)) {
    return 'Firefox'
  }

  return `Desconocido ${userAgent}`
}

export async function sendError (errorInfo) {
  const device = getDeviceType()
  let browser = 'N/A' // Valor por defecto

  if (device === 'Web') {
    browser = getBrowserType()
  }

  const errorWithDevice = {
    ...errorInfo,
    device,
    browser,
  }

  try {
    await axios.post(
      `${process.env.VUE_APP_BASE_URL}/frontend-notification/api/v1/frontend-notification-service`,
      errorWithDevice,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  } catch (error) {
    console.error('Error al enviar la notificación:', error)
    // Opcional: Manejo adicional de errores
  }
}
