import { Type } from 'class-transformer'
import { Entity } from '..'

import { Person, Phone, Photo } from '../persons'
import { LeadActivity, Lead } from '../crm'
import {
  Appraisal,
  AppraisalResponse,
  Enablement,
  Inspection,
  InspectionQualification,
  NegotiationResponse,
} from '../purchase'

export class Employee extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => Lead)
  executiveLeads: Lead[];

  @Type(() => Lead)
  forwardedLeads: Lead[];

  @Type(() => LeadActivity)
  leadActivities: LeadActivity[];

  @Type(() => Appraisal)
  appraisals: Appraisal[];

  @Type(() => AppraisalResponse)
  appraisalResponses: AppraisalResponse[];

  @Type(() => Enablement)
  enablements: Enablement[];

  @Type(() => Inspection)
  inspectorInspections: Inspection[];

  @Type(() => Inspection)
  supervisorInspections: Inspection[];

  @Type(() => InspectionQualification)
  inspectionQualifications: InspectionQualification[];

  @Type(() => NegotiationResponse)
  negotiationResponses: NegotiationResponse[];

  _selected: boolean

  get name (): string {
    const { person } = this
    return person?.name
  }

  get photo (): Photo {
    const { person } = this
    return person?.photo
  }

  get phone (): string {
    const { person } = this

    return person?.secondaryPhone?.work
  }

  get email (): string {
    const { person } = this
    return person?.email?.toString()
  }

  get whatsappMe (): string {
    const { person } = this
    return person?.whatsappMeWork
  }

  get available (): boolean {
    // TODO: determinate current employee availability.
    return true
  }

  get selected (): boolean {
    const { _selected } = this
    // TODO: Compute the selected value
    return _selected
  }

  get secondaryPhone (): Phone {
    const { person } = this

    return person?.secondaryPhone
  }

  get admissionDate () {
    const { createdAt } = this
    return createdAt.format('DD/MM/YYYY')
  }
}
