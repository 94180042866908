import gql from 'graphql-tag'

import { Fragment } from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation insertOffer($fields: loans_offer_insert_input!) {
    record: insert_loans_offer_one(object: $fields) {
      ...OfferForm
    }
  }
`

export default mutation
