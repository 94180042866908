import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const InfractionTypeFragmentBase = gql`
  fragment InfractionTypeBase on settings_infraction_type{
    ${EntityFields}
    ${EnumFields}
  }
`

export const InfractionTypeFragment = gql`
  ${InfractionTypeFragmentBase}
  fragment InfractionType on settings_infraction_type{
    ...InfractionTypeBase
  }
`
