import gql from 'graphql-tag'
import { FinancialCategoryFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${FinancialCategoryFragment}
  query findFinancialCategory($filter: finance_financial_category_bool_exp) {
    records: finance_financial_category(where: $filter) {
      ...FinancialCategory
    }
  }
`
export default query
