import gql from 'graphql-tag'

import { EvaluationRequirementFragment } from '@/graphql/fragments/loans'

export const query = gql`
  ${EvaluationRequirementFragment}
  query fetchEvaluationRequirement($id: Int!) {
    records: loans_evaluation_requirement_by_pk(id: $id) {
      ...EvaluationRequirement
    }
  }
`

export default query
