import { Type } from 'class-transformer'
import { Entity } from '..'

import { ClosingReason, ProcessStatus } from '../settings'
import { PaymentOrderItem } from '../finance'
import { Component } from '../vehicle'
import { Enablement, EnablementTaskType } from '.'

export class EnablementTask extends Entity {
  @Type(() => PaymentOrderItem)
  paymentOrderItems: PaymentOrderItem[]

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  @Type(() => Component)
  component: Component;

  @Type(() => Enablement)
  enablement: Enablement;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => EnablementTaskType)
  type: EnablementTaskType;
}
