import { Metadata } from '.'
import { ICondition } from '@/utils/conditional'
import { Api, Detail, FormDetails } from '../interfaces/form'
import { UnimplementedMethod } from '@/errors/core'

export class ToolKit extends Metadata {
  readonly api: Api;
  readonly details: FormDetails

  get model () {
    const { api: { model } } = this
    return model
  }

  get queries () {
    throw new UnimplementedMethod()
  }

  get condition (): ICondition {
    const { details: { condition } } = this
    return condition
  }

  get children (): Array<Detail> {
    const { details: { children } } = this
    return children
  }
}
