import gql from 'graphql-tag'
import { FileFragment } from '@/graphql/fragments/files'

export const query = gql`
  ${FileFragment}
  query findFile($filter: files_file_bool_exp) {
    records: files_file(where: $filter, order_by: {id: desc}) {
      ...File
    }
  }
`
export default query
