import { GetterTree, MutationTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { Resource } from '@/entities/public'

export interface Resources {
  active?: Resource
  content?: any
  tool?: number
}

export function initState (): Resources {
  return {
    active: null,
    content: null,
    tool: -1,
  }
}

export const mutations: MutationTree<Resources> = {
  setActive: (state, active) => state.active = active,
  setContent: (state, content) => state.content = content,
  setTool: (state, tool) => state.tool = tool,
}

export const getters: GetterTree<Resources, RootState> = {
  resource: ({ active }) => active,
  content: ({ content }) => content,
  tool: ({ tool }) => tool,
}
