import { Type } from 'class-transformer'
import { Entity } from '..'

import { InspectionAssessment, InspectionComponent, InspectionQualification } from '.'
import { Expense } from '../settings'

export class InspectionParameter extends Entity {
  @Type(() => InspectionAssessment)
  inspectionAssessments: InspectionAssessment[];

  @Type(() => Expense)
  expense: Expense;

  @Type(() => InspectionComponent)
  inspectionComponent: InspectionComponent;

  @Type(() => InspectionQualification)
  inspectionQualifications: InspectionQualification[];

  name: string;
  order: number
  multiple: boolean

  get assessment () {
    const { inspectionAssessments } = this
    return inspectionAssessments?.[0]
  }

  get options () {
    const { inspectionAssessments } = this
    return inspectionAssessments?.map(assessment => {
      return { ...assessment.option, score: assessment.score, idAssessment: assessment.id }
    })
  }
}
