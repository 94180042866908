import { Type } from 'class-transformer'

import { AutoAttribute } from '../vehicle'
import { Deal } from '../crm'
import { Process } from '../settings'

export class DealAutoAttribute extends AutoAttribute {
  @Type(() => Deal)
  deal: Deal;

  @Type(() => Process)
  process: Process;

  record: number;
}
