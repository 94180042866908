import gql from 'graphql-tag'
import { FileProcessFragment } from '@/graphql/fragments/files'

export const query = gql`
  ${FileProcessFragment}
  query findFileProcess($filter: files_file_process_bool_exp) {
    records: files_file_process(where: $filter, order_by: {id: desc}) {
      ...FileProcess
    }
  }
`
export default query
