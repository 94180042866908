import gql from 'graphql-tag'

import { RequirementTypeFragment } from '@/graphql/fragments/loans'

export const query = gql`
  ${RequirementTypeFragment}
  query findRequirementType($filter: loans_requirement_type_bool_exp, $limit: Int) {
    records: loans_requirement_type(where: $filter, limit: $limit) {
      ...RequirementType
    }
  }
`

export default query
