import gql from 'graphql-tag'
import { FileParameterFragment } from '@/graphql/fragments/files'

export const query = gql`
  ${FileParameterFragment}
  query findFileParameter($filter: files_file_parameter_bool_exp) {
    records: files_file_parameter(where: $filter) {
      ...FileParameter
    }
  }
`
export default query
