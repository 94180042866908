import gql from 'graphql-tag'

export const getRegionAbbreviation = gql`
  query getRegionAbbreviation($id: Int!) {
    region(where: { id: { _eq: $id } }) {
      abbreviation
    }
  }
`

export const getRegion = gql`
  query loadRegion {
    region(order_by: [{default: asc_nulls_last}, {name: asc_nulls_last}]) {
      id
      name
    }
  }
`

export const getAddressType = gql`
  query getAddressType($type: persons_person_type_enum!) {
    addressType: persons_address_type(where: {person_type: {_eq: $type}}) {
      description
      name
    }
  }
`

export const getCity = gql`
  query getCity($id: Int!) {
    city(where: { id_region: { _eq: $id } }, order_by: {name: asc}) {
      id
      name
    }
  }
`

export const getStreetType = gql`
  query getStreetType {
    streetType: persons_street_type {
      description
      name
    }
  }
`

export const getPropertyType = gql`
  query getPropertyType {
    propertyType: persons_property_type {
      description
      name
    }
  }
`

export const addressExists = gql`
  query addressExists(
    $city: Int!
    $type: persons_property_type_enum!
    $streetType: persons_street_type_enum!
    $propertyNumber: String!
    $streetName: String!
    $streetNumber: String!
  ) {
    addressFinded: persons_address(
      where: {
        _and: {
          id_city: { _eq: $city }
          name_property_type: { _eq: $type }
          name_street_type: { _eq: $streetType }
          property_number: { _eq: $propertyNumber }
          street_name: { _eq: $streetName }
          street_number: { _eq: $streetNumber }
        }
      }
    ) {
      id
    }
  }
`
