import { Actions } from '../../interfaces'

import { FileType } from '@/entities/files'
import fetch from './fetch'
import find from './find'

export const actions: Actions = {
  fetch,
  find,
}

export default {
  Model: { FileType },
  actions: { [FileType.name]: actions },
}
