import { Actions, Aggregates } from '../interfaces'

import stock from './stock'
import saleOrder from './saleOrder'
import saleOrderItem from './saleOrderItem'
import reserve from './reserve'
import saleProduct from './saleProduct'
import projectionPayment from './projectionPayment'
import itemType from './itemType'
import price from './price'
import bonus from './bonus'
import bonusType from './bonusType'
import stockViewDetail from './stockViewDetail'
import stockAdvertiser from './stockAdvertiser'

export const Models = {
  ...stock.Model,
  ...saleOrder.Model,
  ...saleOrderItem.Model,
  ...reserve.Model,
  ...saleProduct.Model,
  ...projectionPayment.Model,
  ...itemType.Model,
  ...price.Model,
  ...bonus.Model,
  ...bonusType.Model,
  ...stockViewDetail.Model,
  ...stockAdvertiser.Model,
}

export const actions: Record<string, Actions> = {
  ...stock.actions,
  ...saleOrder.actions,
  ...saleOrderItem.actions,
  ...reserve.actions,
  ...saleProduct.actions,
  ...projectionPayment.actions,
  ...itemType.actions,
  ...price.actions,
  ...bonus.actions,
  ...bonusType.actions,
  ...stockViewDetail.actions,
  ...stockAdvertiser.actions,
}

export const aggregates: Record<string, Aggregates> = {
  ...stock.aggregates,
}

export const schema = { Models, actions, aggregates }
export default schema
