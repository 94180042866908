import gql from 'graphql-tag'
import { VersionFragment } from '@/graphql/fragments/vehicle'

export const mutation = gql`
  ${VersionFragment}
  mutation insertVersion($fields: vehicle_version_insert_input!) {
    record: insert_vehicle_version_one(object: $fields) {
      ...Version
    }
  }
`

export default mutation
