import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment } from '../persons'

export const EmployeeFragment = gql`
  ${PersonFragment}
  fragment Employee on hr_employee{
    ${EntityFields}
  }
`
