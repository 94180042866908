import gql from 'graphql-tag'

export const getTeams = gql`
  query getTeams {
    teams: user {
      id
      email
      roles {
        id
        rol {
          id
          name
        }
      }
    }
  }
`
