import gql from 'graphql-tag'

import { EntityFields } from '..'
import { ComponentFragment, ComponentValueFragment } from '.'
import { CountryFragment } from '../public'

export const BaseAttributeFragment = gql`
  fragment AttributeBase on vehicle_attribute {
    ${EntityFields}
    value
    validated
  }
`

export const AttributeFragment = gql`
  ${BaseAttributeFragment}
  ${CountryFragment}
  ${ComponentFragment}
  ${ComponentValueFragment}
  fragment Attribute on vehicle_attribute {
    ...AttributeBase
    component {
      ...Component
    }
    componentValue: component_value {
      ...ComponentValue
    }
  }
`
