import gql from 'graphql-tag'
import { StockViewDetailFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${StockViewDetailFragment}
  query findStockView($filter: sales_stock_view_bool_exp, $order: [sales_stock_view_order_by!], $limit: Int) {
    records: sales_stock_view(where: $filter, order_by: $order, limit: $limit) {
      ...StockViewDetail
    }
  }
`
export default query
