import { Type } from 'class-transformer'
import { EnumEntity } from '..'

import { VehicleGamaEnum } from '@/graphql'
import { Brand } from '@/entities/vehicle/Brand'

export class VehicleGama extends EnumEntity {
  static readonly high: VehicleGamaEnum = 'high'
  static readonly emergent: VehicleGamaEnum = 'emergent'

  @Type(() => Brand)
  brands: Brand[];

  declare name: VehicleGamaEnum
}
