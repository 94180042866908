import { GetterTree } from 'vuex'

import { State as RootState } from '@/store/state'

export interface Files {
  filesStorage?: Array<number>
}

export function initState (): Files {
  return {
    filesStorage: [],
  }
}

export const getters: GetterTree<Files, RootState> = {
  filesStorage: state => {
    return state.filesStorage
  },
}
