import { Type } from 'class-transformer'
import { Entity } from '..'

import { AgreementProduct } from './AgreementProduct'
import { BusinessApproach } from './BusinessApproach'

export class ProductApproach extends Entity {
  @Type(() => AgreementProduct)
  agreementProduct: AgreementProduct;

  @Type(() => BusinessApproach)
  businessApproach: BusinessApproach;
}
