import { Type } from 'class-transformer'
import { Entity } from '..'

import {
  InspectionAssessment,
  InspectionInspectedComponent,
  InspectionParameter,
} from '.'
import { Employee } from '../hr'

export class InspectionQualification extends Entity {
  @Type(() => Employee)
  responsible: Employee;

  @Type(() => InspectionAssessment)
  assessment: InspectionAssessment;

  @Type(() => InspectionInspectedComponent)
  component: InspectionInspectedComponent;

  @Type(() => InspectionParameter)
  parameter: InspectionParameter;

  value?: string;
  validated: boolean;

  get idInspectedComponent () {
    const { component } = this

    return component?.id
  }
}
