import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { SaleOrder } from './SaleOrder'
import { InspectionInspectedComponent } from '../purchase'
import dayjs, { Dayjs } from 'dayjs'

export class ExtraGuarantee extends Entity {
  @Type(() => SaleOrder)
  saleOrder: SaleOrder;

  @Type(() => InspectionInspectedComponent)
  inspectedComponent: InspectionInspectedComponent

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  expiration: Dayjs

  idInspectedComponent: number;

  idSaleOrder: number;
}
