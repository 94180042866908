<template>
  <svg width="531" height="436" viewBox="0 0 531 436" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4_132)">
      <path
        d="M182.38 113.605C123.831 113.605 65.2656 113.621 6.70052 113.589C4.46164 113.589 2.23888 113.379 0 113.267V208.613H189.338V113.605H182.38ZM65.3784 189.534C56.5839 189.76 47.7573 189.76 38.9468 189.551C32.4234 189.39 28.3645 185.348 28.5094 179.794C28.6544 174.287 32.8583 170.649 39.4461 170.471C42.297 170.407 45.148 170.423 47.9989 170.439C49.2875 170.439 50.576 170.455 51.8807 170.455C53.1048 170.455 54.3451 170.455 55.5692 170.439C58.8389 170.423 62.0925 170.391 65.3623 170.504C71.6762 170.729 75.5902 174.319 75.7352 179.761C75.8963 185.477 72.0306 189.373 65.3784 189.534ZM160.571 145.145C158.751 150.314 154.563 151.666 149.409 151.65C120.899 151.57 92.3737 151.618 63.8643 151.618C55.7464 151.618 47.6284 151.666 39.4944 151.602C32.6972 151.553 28.3806 147.689 28.5094 141.925C28.6222 136.306 32.6972 132.796 39.4139 132.78C58.0658 132.732 76.7338 132.78 95.4019 132.78C114.07 132.78 132.384 132.716 150.874 132.829C158.268 132.861 162.842 138.721 160.571 145.162V145.145Z"
        fill="#6185DB"
      />
      <path
        d="M182.943 227.354C121.962 227.338 60.9812 227.306 0 227.274V322.637C2.23888 322.524 4.46164 322.299 6.70052 322.299C65.4267 322.283 124.169 322.283 182.911 322.283H189.354V227.354H182.943ZM66.377 303.075C61.5449 303.236 56.6967 303.107 51.8646 303.107C47.0325 303.107 42.5225 303.236 37.8676 303.075C32.3751 302.865 28.6383 299.098 28.5094 293.994C28.3806 288.745 32.2785 284.479 37.9159 284.366C47.4191 284.157 56.9222 284.157 66.4092 284.366C72.0789 284.495 75.8801 288.713 75.7513 294.026C75.6063 299.146 71.8856 302.882 66.377 303.075ZM148.764 265.432C130.628 265.464 112.475 265.448 94.3388 265.448C76.2023 265.448 59.0805 265.464 41.4595 265.416C39.2367 265.416 36.9173 265.287 34.8234 264.611C30.555 263.242 28.4128 259.926 28.5738 255.53C28.7349 251.119 31.0704 247.979 35.4515 246.9C37.5938 246.369 39.8971 246.305 42.136 246.305C77.217 246.272 112.282 246.272 147.363 246.272H148.925C156.818 246.401 161.07 249.798 161.022 255.933C160.974 262.003 156.641 265.416 148.764 265.432Z"
        fill="#6185DB"
      />
      <path
        d="M0 341.297V412.815C7.90854 431.556 14.3836 435.807 35.0167 435.807H189.354V341.297H0ZM66.1193 416.905C56.7933 417.066 47.4674 417.082 38.1414 416.905C32.4234 416.792 28.5094 412.735 28.5094 407.47C28.5094 402.205 32.3751 398.325 38.1736 398.164C43.0057 398.019 47.8378 398.132 52.6699 398.132C57.1638 398.132 61.6577 398.019 66.1515 398.164C71.9661 398.357 75.7513 402.157 75.7352 407.502C75.7191 412.799 71.8695 416.792 66.1193 416.905ZM150.472 378.924C133.882 379.02 117.291 378.956 100.701 378.956H94.4838C76.1701 378.956 57.8564 378.924 39.5427 378.988C35.2583 378.988 31.6503 377.91 29.5564 373.868C26.8182 368.587 29.5081 362.324 35.3066 360.602C36.9334 360.119 38.7213 360.054 40.4286 360.054C76.7177 360.022 113.007 360.022 149.28 360.038C155.9 360.038 159.782 362.598 160.796 367.428C162.182 373.997 157.945 378.876 150.472 378.924Z"
        fill="#6185DB"
      />
      <path
        d="M186.31 0.112694C133.64 0.0965939 80.9539 0.0804934 28.2678 0.160996C25.3847 0.160996 22.3726 0.579607 19.6183 1.46513C9.00382 4.89453 3.23751 12.8803 0 23.088V94.6062H189.274V0.354201C188.033 0.241498 187.164 0.112694 186.31 0.112694ZM65.926 75.8009C61.2711 76.0102 56.6001 75.8492 51.929 75.8492C47.258 75.8492 42.9413 75.9941 38.4636 75.817C32.4556 75.5916 28.3806 71.486 28.4933 66.1084C28.6061 60.8758 32.4878 57.0922 38.3669 56.9956C47.5318 56.8507 56.6806 56.8507 65.8294 56.9956C71.8212 57.0922 75.6063 60.7148 75.7352 66.0279C75.8963 71.5182 71.9823 75.5594 65.926 75.8009ZM160.893 29.8985C160.184 34.8897 156.544 37.7394 150.44 37.7555C134.542 37.8199 118.644 37.7877 102.747 37.7877C81.6626 37.7877 60.5785 37.7394 39.4944 37.8199C34.5012 37.836 30.6194 36.2904 29.0087 31.251C26.8665 24.5693 31.5697 18.9824 39.4139 18.9502C58.0658 18.8858 76.7338 18.9341 95.4019 18.9341C114.07 18.9341 132.029 18.8858 150.359 18.9663C157.559 18.9824 161.811 23.5227 160.893 29.8985Z"
        fill="#6185DB"
      />
      <path
        d="M303.408 319.433C303.408 323.152 300.557 322.218 298.527 322.218C278.474 322.266 258.437 322.25 238.384 322.25C230.266 322.25 222.148 322.25 214.014 322.234C212.339 322.234 210.648 322.073 208.747 321.977V284.64H303.408C303.408 296.361 303.424 307.905 303.408 319.433Z"
        fill="#6185DB"
      />
      <path d="M303.037 170.745H208.956V208.195H303.037V170.745Z" fill="#6185DB" />
      <path d="M530.694 284.559H436.565V321.928H530.694V284.559Z" fill="#6185DB" />
      <path d="M530.485 170.906H436.452V208.147H530.485V170.906Z" fill="#6185DB" />
      <path d="M416.817 284.64H322.607V321.848H416.817V284.64Z" fill="#6185DB" />
      <path d="M416.64 170.842H322.591V208.179H416.64V170.842Z" fill="#6185DB" />
      <path d="M303.021 57.1727H208.876V94.4613H303.021V57.1727Z" fill="#6185DB" />
      <path d="M303.102 398.486H208.715V435.42H303.102V398.486Z" fill="#6185DB" />
      <path d="M416.721 57.2209H322.801V94.4935H416.721V57.2209Z" fill="#6185DB" />
      <path d="M530.485 57.1727H436.548V94.3808H530.485V57.1727Z" fill="#6185DB" />
      <path d="M416.769 398.47H322.704V435.533H416.769V398.47Z" fill="#6185DB" />
      <path
        d="M499.785 435.984C480.633 435.179 461.434 435.807 442.266 435.807H436.452V398.422H530.259C534.785 422.443 517.824 436.741 499.785 435.984Z"
        fill="#6185DB"
      />
      <path d="M246.26 227.676H208.827V265.142H246.26V227.676Z" fill="#6185DB" />
      <path d="M530.533 114.136H493.261V151.392H530.533V114.136Z" fill="#6185DB" />
      <path d="M302.957 113.959H265.685V151.248H302.957V113.959Z" fill="#6185DB" />
      <path d="M302.941 227.692H265.798V265.174H302.941V227.692Z" fill="#6185DB" />
      <path d="M530.485 227.692H493.342V265.174H530.485V227.692Z" fill="#6185DB" />
      <path d="M246.164 114.104H208.65V151.296H246.164V114.104Z" fill="#6185DB" />
      <path d="M246.276 0.402527H208.795V37.4657H246.276V0.402527Z" fill="#6185DB" />
      <path d="M303.134 0.386414H265.862V37.4979H303.134V0.386414Z" fill="#6185DB" />
      <path d="M359.976 227.644H322.672V265.029H359.976V227.644Z" fill="#6185DB" />
      <path d="M416.608 227.757H379.578V264.997H416.608V227.757Z" fill="#6185DB" />
      <path d="M473.611 227.644H436.484V265.045H473.611V227.644Z" fill="#6185DB" />
      <path d="M416.737 113.943H379.642V151.36H416.737V113.943Z" fill="#6185DB" />
      <path d="M359.799 113.975H322.624V151.328H359.799V113.975Z" fill="#6185DB" />
      <path d="M246.212 341.555H208.779V378.666H246.212V341.555Z" fill="#6185DB" />
      <path d="M302.973 341.362H265.846V378.666H302.973V341.362Z" fill="#6185DB" />
      <path d="M473.74 113.991H436.565V151.328H473.74V113.991Z" fill="#6185DB" />
      <path d="M530.501 341.362H493.245V378.65H530.501V341.362Z" fill="#6185DB" />
      <path d="M359.847 0.499084H322.72V37.5462H359.847V0.499084Z" fill="#6185DB" />
      <path d="M473.659 0.322021H436.516V37.3852H473.659V0.322021Z" fill="#6185DB" />
      <path d="M416.689 0.386414H379.771V37.5784H416.689V0.386414Z" fill="#6185DB" />
      <path d="M359.911 341.474H322.688V378.586H359.911V341.474Z" fill="#6185DB" />
      <path d="M416.64 341.523H379.562V378.65H416.64V341.523Z" fill="#6185DB" />
      <path d="M473.691 341.474H436.484V378.586H473.691V341.474Z" fill="#6185DB" />
      <path d="M530.468 37.5301H493.261V0.837201C516.02 -3.81583 534.35 11.4635 530.468 37.5301Z" fill="#6185DB" />
    </g>
    <defs>
      <clipPath id="clip0_4_132">
        <rect width="531" height="436" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
