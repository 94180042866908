import { Type } from 'class-transformer'
import { Entity } from '..'

import { File, FileParameter } from '.'
import { FileValidation } from './FileValidation'

export class FileProcess extends Entity {
  @Type(() => File)
  file: File;

  @Type(() => FileParameter)
  parameter: FileParameter;

  @Type(() => FileValidation)
  validation: FileValidation;

  record: number;
  description: string;
  comment: string;
  valid: boolean;
  idProcessRecord: number;

  get url () {
    const { file } = this
    return `${file.uri}`
  }
}
