import {
  Entity as IEntity,
  AggregatePath as IAggregatePath,
} from './interfaces'
import dayjs from 'dayjs'

export class Entity implements IEntity {
  public static readonly Aggregate: IAggregatePath;

  id: number;
  createdAt?: string;
  updatedAt?: string;

  constructor (data: IEntity) {
    if (!data) return

    const { id, createdAt, updatedAt } = data
    this.id = id
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  formatDate (date?: string, format = 'DD/MM/YYYY HH:mm A'): string {
    // TODO: If no date is returning NOW
    return dayjs(date).format(format)
  }

  get created (): string {
    const { createdAt } = this
    return this.formatDate(createdAt)
  }

  get modified (): string {
    const { updatedAt } = this
    return this.formatDate(updatedAt)
  }
}
