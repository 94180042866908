import gql from 'graphql-tag'

import { InspectedComponentFragment } from '@/graphql/fragments/purchase'
import { ComponentFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${InspectedComponentFragment}
  ${ComponentFragment}
  query findInspectionInspectedComponent($filter: purchase_inspection_inspected_component_bool_exp) {
    records: purchase_inspection_inspected_component(where: $filter) {
      ...InspectedComponent
      inspectionComponent:inspection_component {
        id
        component {
          ...Component
        }
      }
    }
  }
`

export default query
