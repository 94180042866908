import gql from 'graphql-tag'
import { PlatformFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${PlatformFragment}
  query findPlatform($filter: platform_bool_exp!, $limit: Int = 6) {
    records: platform(where: $filter, limit: $limit){
      ...Platform
    }
  }
`
export default query
