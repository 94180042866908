import gql from 'graphql-tag'

import { NegotiationResponseFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${NegotiationResponseFragment}
  query fetchNegotiationResponse($id: Int!) {
    records: purchase_negotiation_response_by_pk(id: $id) {
      ...NegotiationResponse
    }
  }
`

export default query
