import gql from 'graphql-tag'

import { SaleProductFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${SaleProductFragment}
  query fetchSaleProduct($id: Int!) {
    records: sales_sale_product_by_pk(id: $id) {
      ...SaleProduct
    }
  }
`

export default query
