import gql from 'graphql-tag'
import { ChannelFragment } from '@/graphql/fragments/crm/channel'

export const query = gql`
  ${ChannelFragment}
  query findChannel($filter: crm_channel_bool_exp, $limit: Int,$order: [crm_channel_order_by!]) {
    records: crm_channel(where: $filter, limit: $limit, order_by: $order) {
      ...Channel
    }
  }
`

export default query
