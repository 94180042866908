import { Entity } from '@/models/interfaces'
import { Vehicle } from '@/models/vehicle/interfaces'
import { Appraisal } from '@/models/appraisal/interfaces'
import { Financing } from '@/models/financing/interfaces'

export interface Bonus {
  name: string
  amount: number
}

export enum DealType {
  sell,
  buy,
}

export interface Deal extends Entity {
  type: DealType
  vehicle: Vehicle
  appraisal?: Appraisal
  financing?: Array<Financing>
  bonuses?: Array<Bonus>
}
