import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { Address } from '@/store/persons/address/state'
import {
  addressExists,
  getAddressType,
  getCity,
  getPropertyType,
  getRegion,
  getRegionAbbreviation,
  getStreetType,
} from '@/store/persons/address/queries/info'
import {
  createFullAddress,
  personAddressUpdateStatus,
} from '@/store/persons/address/mutation/address'
import {
  AddressData,
  AddressInterface,
} from '@/store/persons/address/addressInterfaces'

const buildAddressData = (
  address: AddressInterface
): {
  streetName: string
  idCity: number
  namePropertyType: string
  nameStreetType: string
  propertyNumber: string
  streetNumber: string
  nameAddressType: string
} => {
  return {
    streetName: address.streetName,
    idCity: parseInt(address.commune),
    namePropertyType: address.propertyType,
    nameStreetType: address.streetType,
    propertyNumber: address.propertyNumber,
    streetNumber: address.streetNumeration,
    nameAddressType: address.type,
  }
}

export const actions: ActionTree<Address, RootState> = {
  addressUpdateStatus: async (
    { rootState: { apolloClient } },
    id
  ): Promise<void> => {
    if (!id || !apolloClient) {
      return
    }
    await apolloClient.mutate({
      mutation: personAddressUpdateStatus,
      variables: {
        id,
      },
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        // @ts-ignore
        detail: {
          message: '¡Se ha desvinculado exitosamente!',
          type: 'success',
        },
      })
    )
  },
  findAddressData: async (
    { rootState: { apolloClient } },
    { city, type, streetType, propertyNumber, streetName, streetNumber }
  ): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { addressFinded },
    } = await apolloClient.query({
      query: addressExists,
      variables: {
        city,
        type,
        streetType,
        propertyNumber,
        streetName,
        streetNumber,
      },
    })

    if (addressFinded.length) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          // @ts-ignore
          detail: {
            message: 'Hacer flujo de cuenta bipersonal',
            type: 'error',
          },
        })
      )
    }
  },
  loadRegion: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { region },
    } = await apolloClient.query({
      query: getRegion,
    })

    commit('setRegion', region)
  },
  loadAddressType: async ({
    commit,
    rootState: { apolloClient },
  }, payload): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { addressType },
    } = await apolloClient.query({
      query: getAddressType,
      variables: {
        type: payload,
      },
    })
    commit('setAddressType', addressType)
  },
  loadCommunes: async (
    { commit, rootState: { apolloClient } },
    id
  ): Promise<void> => {
    if (!apolloClient || !id) return

    const {
      data: { city },
    } = await apolloClient.query({
      query: getCity,
      variables: { id },
    })

    commit('setCommunes', city)
  },
  loadStreetType: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { streetType },
    } = await apolloClient.query({
      query: getStreetType,
    })

    commit('setStreetType', streetType)
  },
  loadPropertyType: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { propertyType },
    } = await apolloClient.query({
      query: getPropertyType,
    })

    commit('setPropertyType', propertyType)
  },
  createAddress: async (
    { commit, rootState: { apolloClient } },
    payload: AddressData
  ): Promise<void> => {
    if (!apolloClient) return
    commit('setAddDirectionState', false)
    const dataFormatted = buildAddressData(payload.address)

    const {
      data: { fullAddress },
    } = await apolloClient.mutate({
      mutation: createFullAddress,
      variables: {
        ...dataFormatted,
        idPerson: payload.personId,
      },
    })

    if (fullAddress.exists) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          // @ts-ignore
          detail: {
            message: 'Esta Dirección ya esta asociado a este usuario',
            type: 'error',
          },
        })
      )
      commit('setAddDirectionState', false)
      return
    }

    const {
      data: { region },
    } = await apolloClient.query({
      query: getRegionAbbreviation,
      variables: {
        id: payload.address.region,
      },
    })
    window.dispatchEvent(
      new CustomEvent('notification-message', {
        // @ts-ignore
        detail: {
          message: 'Dirección creada exitosamente',
          type: 'success',
        },
      })
    )

    commit('setAddDirectionState', true)
    commit('setDirectionCreated', {
      abbreviation: region[0].abbreviation,
      idPersonAddress: fullAddress.personAddress.id,
    })
  },
}
