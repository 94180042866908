import gql from 'graphql-tag'

export const query = gql`
  query findLiteUser($filter: user_bool_exp!) {
    records: user(where: $filter){
      id
    }
  }
`
export default query
