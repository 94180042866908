import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Component, Items } from '@/utils/generalInterface'

export interface Information {
  brands: Array<Items>
  models: Array<Items>
  modelsFilter: Array<Items>
  versions: Array<Items>
  versionsFilter: Array<Items>
  tractions: Array<Items>
  transmissions: Array<Items>
  years: Array<Items>
  fuels: Array<Items>
  bodyWorks: Array<Items>
  nVelocity: Array<Items>
  places: Array<Items>
  keyType: Array<Items>
  airbags: Array<Items>
  comfortEquipment: Array<Items>
  securityEquipment: Array<Items>
  components: Array<Items>
  doors: Array<Items>
  color: Array<Items>
}

export function initState (): Information {
  return {
    brands: [],
    models: [],
    modelsFilter: [],
    versions: [],
    versionsFilter: [],
    tractions: [],
    transmissions: [],
    years: [],
    fuels: [],
    bodyWorks: [],
    nVelocity: [],
    places: [],
    keyType: [],
    airbags: [],
    comfortEquipment: [],
    securityEquipment: [],
    components: [],
    doors: [],
    color: [],
  }
}

export const mutations: MutationTree<Information> = {
  setBrands: (state, brands) => {
    state.brands = brands
  },
  setModels: (state, models) => {
    state.models = models
  },
  setModelsFilters: (state, models) => {
    state.modelsFilter = models
  },
  setVersions: (state, versions) => {
    state.versions = versions
  },
  setVersionsFilters: (state, versions) => {
    state.versionsFilter = versions
  },
  setYears: (state, years) => {
    state.years = years
  },
  setComponents: (state, components) => {
    components.forEach((component: Component) => {
      // @ts-ignore
      state[COMPONENTS[component.name]] = component.componentValue
    })
    state.components = components
  },
}

export const getters: GetterTree<Information, RootState> = {
  brands: ({ brands }): Array<Items> => {
    return brands
  },
  models: ({ models }): Array<Items> => {
    return models
  },
  modelsFilter: ({ modelsFilter }): Array<Items> => {
    return modelsFilter
  },
  versions: ({ versions }): Array<Items> => {
    return versions
  },
  versionsFilter: ({ versionsFilter }): Array<Items> => {
    return versionsFilter
  },
  tractions: ({ tractions }): Array<Items> => {
    return tractions
  },
  transmissions: ({ transmissions }): Array<Items> => {
    return transmissions
  },
  years: ({ years }): Array<Items> => {
    return years
  },
  fuels: ({ fuels }): Array<Items> => {
    return fuels
  },
  bodyWorks: ({ bodyWorks }): Array<Items> => {
    return bodyWorks
  },
  nVelocity: ({ nVelocity }): Array<Items> => {
    return nVelocity
  },
  places: ({ places }): Array<Items> => {
    return places
  },
  keyType: ({ keyType }): Array<Items> => {
    return keyType
  },
  airbags: ({ airbags }): Array<Items> => {
    return airbags
  },
  comfortEquipment: ({ comfortEquipment }): Array<Items> => {
    return comfortEquipment
  },
  securityEquipment: ({ securityEquipment }): Array<Items> => {
    return securityEquipment
  },
  components: ({ components }): Array<Items> => {
    return components
  },
  doors: ({ doors }): Array<Items> => {
    return doors
  },
  color: ({ color }): Array<Items> => {
    return color
  },
}
