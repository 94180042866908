import gql from 'graphql-tag'

import { ItemTypeFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${ItemTypeFragment}
  query fetchSaleOrderItemType($id: Int!) {
    records: sales_item_type_by_pk(id: $id) {
      ...ItemType
    }
  }
`

export default query
