import gql from 'graphql-tag'

export const allPlatforms = gql`
  query allPlatforms {
    platform {
      id
      name
    }
  }
`
