import { Type } from 'class-transformer'
import { Entity } from '..'

import { ProcessExpense, ExpenseType } from '.'
import { InspectionParameter } from '../purchase'

export class Expense extends Entity {
  @Type(() => ExpenseType)
  type: ExpenseType;

  @Type(() => InspectionParameter)
  inspectionParameters: InspectionParameter[];

  @Type(() => ProcessExpense)
  processExpenses: ProcessExpense[];

  name: string;
  description: string;
  deductible: boolean;
}
