<template>
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.5804 10.5998L20.6304 15.4198L21.8004 22.2398C21.8465 22.5148 21.8152 22.7971 21.7099 23.0552C21.6045 23.3133 21.4294 23.5369 21.204 23.701C20.9787 23.865 20.7121 23.9631 20.4341 23.9841C20.1561 24.0051 19.8778 23.9482 19.6304 23.8198L13.5004 20.6098L7.37037 23.8198C7.12293 23.9482 6.84461 24.0051 6.56665 23.9841C6.28868 23.9631 6.02207 23.865 5.79672 23.701C5.57137 23.5369 5.3962 23.3133 5.29086 23.0552C5.18553 22.7971 5.15419 22.5148 5.20037 22.2398L6.37037 15.4198L1.37037 10.5998C1.15624 10.404 1.00353 10.1502 0.930748 9.86924C0.857968 9.58831 0.868246 9.29229 0.960336 9.01709C1.05243 8.74188 1.22237 8.49929 1.44957 8.31873C1.67677 8.13818 1.95147 8.02741 2.24037 7.99985L9.09037 6.99985L12.1504 0.839847C12.2743 0.588914 12.4659 0.377643 12.7036 0.229919C12.9412 0.0821938 13.2155 0.00390625 13.4954 0.00390625C13.7752 0.00390625 14.0495 0.0821938 14.2872 0.229919C14.5249 0.377643 14.7165 0.588914 14.8404 0.839847L17.9604 6.99985L24.8004 7.99985C25.0819 8.04192 25.3456 8.16323 25.5608 8.3496C25.7759 8.53597 25.9336 8.77971 26.0154 9.05235C26.0972 9.32498 26.0997 9.61526 26.0227 9.88929C25.9456 10.1633 25.7922 10.4098 25.5804 10.5998Z"
      fill="#BABABA"
    />
    <g clip-path="url(#clip0_3412_359761)">
      <path
        d="M25.1315 10.2318L25.1239 10.2386L25.1166 10.2458L20.1973 15.0689L20.0105 15.252L20.0544 15.5099L21.2169 22.3326C21.2169 22.3329 21.217 22.3332 21.217 22.3334C21.2475 22.5173 21.2266 22.706 21.1568 22.8784C21.0868 23.051 20.9706 23.2001 20.8218 23.3092C20.673 23.4183 20.4972 23.4833 20.3143 23.4972C20.1317 23.5111 19.9487 23.4735 19.7857 23.3886C19.7854 23.3884 19.7852 23.3883 19.785 23.3882L13.6946 20.177L13.4614 20.0541L13.2282 20.177L7.13782 23.3882C7.13759 23.3883 7.13737 23.3884 7.13714 23.3886C6.97413 23.4735 6.79112 23.5111 6.60848 23.4972C6.42558 23.4833 6.24985 23.4183 6.10102 23.3092C5.95216 23.2001 5.83601 23.051 5.76604 22.8784C5.69615 22.706 5.67525 22.5172 5.7058 22.3333C5.70584 22.3331 5.70588 22.3329 5.70591 22.3326L6.86839 15.5099L6.9126 15.2505L6.72374 15.0672L1.75466 10.244L1.75478 10.2439L1.74516 10.235C1.60353 10.1046 1.50213 9.9352 1.45374 9.74715C1.40535 9.55908 1.4122 9.36088 1.4734 9.17671C1.5346 8.99257 1.64736 8.83077 1.79752 8.71062C1.94763 8.59051 2.12875 8.5171 2.31884 8.49884L2.33133 8.49764L2.34375 8.49581L9.15139 7.49516L9.41098 7.45701L9.52707 7.2217L12.5681 1.05771L12.5687 1.05662C12.6509 0.888904 12.7779 0.748187 12.9348 0.650007C13.0916 0.551848 13.2723 0.5 13.4564 0.5C13.6406 0.5 13.8212 0.551848 13.9781 0.650007C14.135 0.748187 14.2619 0.888904 14.3442 1.05662L14.3442 1.05663L14.3464 1.06117L17.4471 7.22517L17.5639 7.4573L17.821 7.49515L24.6171 8.49556C24.6173 8.49559 24.6175 8.49563 24.6178 8.49566C24.8028 8.52363 24.9765 8.60402 25.1185 8.72792C25.2608 8.85199 25.3655 9.01463 25.4198 9.19708C25.4742 9.37956 25.4759 9.57393 25.4247 9.75734C25.3735 9.94074 25.2716 10.1052 25.1315 10.2318Z"
        fill="#6185DB"
        stroke="#4964A4"
      />
      <path
        opacity="0.55"
        d="M22.4403 11.5103L18.937 14.9267L19.765 19.7607C19.7977 19.9555 19.7755 20.1556 19.701 20.3386C19.6264 20.5215 19.5024 20.68 19.343 20.7963C19.1835 20.9126 18.9948 20.9821 18.7981 20.997C18.6013 21.0119 18.4044 20.9716 18.2292 20.8806L13.8908 18.6053L9.55241 20.8806C9.37728 20.9716 9.18031 21.0119 8.98359 20.997C8.78686 20.9821 8.59817 20.9126 8.43868 20.7963C8.27919 20.68 8.15522 20.5215 8.08067 20.3386C8.00612 20.1556 7.98394 19.9555 8.01662 19.7607L8.84467 14.9267L5.306 11.5103C5.15445 11.3715 5.04637 11.1916 4.99486 10.9925C4.94335 10.7934 4.95063 10.5835 5.0158 10.3885C5.08098 10.1934 5.20126 10.0215 5.36205 9.89349C5.52285 9.76551 5.71727 9.687 5.92173 9.66747L10.7697 8.95867L12.9354 4.59251C13.0231 4.41465 13.1587 4.2649 13.3269 4.1602C13.4951 4.05549 13.6892 4 13.8873 4C14.0853 4 14.2795 4.05549 14.4477 4.1602C14.6159 4.2649 14.7515 4.41465 14.8392 4.59251L17.0473 8.95867L21.8882 9.66747C22.0875 9.69729 22.2741 9.78327 22.4264 9.91537C22.5786 10.0475 22.6902 10.2202 22.7481 10.4135C22.806 10.6067 22.8078 10.8125 22.7533 11.0067C22.6988 11.2009 22.5902 11.3756 22.4403 11.5103Z"
        fill="url(#paint0_linear_3412_359761)"
        fill-opacity="0.75"
      />
      <path
        opacity="0.55"
        d="M20.0704 11.6327L17.443 14.195L18.064 17.8205C18.0885 17.9666 18.0719 18.1167 18.016 18.2539C17.9601 18.3911 17.8671 18.51 17.7475 18.5972C17.6278 18.6845 17.4863 18.7366 17.3388 18.7477C17.1912 18.7589 17.0435 18.7287 16.9122 18.6604L13.6583 16.954L10.4045 18.6604C10.2732 18.7287 10.1255 18.7589 9.97792 18.7477C9.83038 18.7366 9.68886 18.6845 9.56925 18.5972C9.44963 18.51 9.35665 18.3911 9.30074 18.2539C9.24482 18.1167 9.22819 17.9666 9.2527 17.8205L9.87374 14.195L7.21973 11.6327C7.10607 11.5286 7.02501 11.3937 6.98638 11.2444C6.94775 11.095 6.95321 10.9377 7.00209 10.7914C7.05097 10.6451 7.14118 10.5161 7.26177 10.4201C7.38237 10.3241 7.52818 10.2652 7.68153 10.2506L11.3175 9.719L12.9418 6.44438C13.0075 6.31099 13.1092 6.19868 13.2354 6.12015C13.3616 6.04162 13.5072 6 13.6557 6C13.8042 6 13.9498 6.04162 14.076 6.12015C14.2022 6.19868 14.3039 6.31099 14.3696 6.44438L16.0257 9.719L19.6564 10.2506C19.8058 10.273 19.9458 10.3375 20.06 10.4365C20.1742 10.5356 20.2579 10.6652 20.3013 10.8101C20.3447 10.955 20.3461 11.1093 20.3052 11.255C20.2643 11.4007 20.1829 11.5317 20.0704 11.6327Z"
        fill="url(#paint1_linear_3412_359761)"
        fill-opacity="0.75"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3412_359761"
        x1="9.5413"
        y1="4.96667"
        x2="20.8923"
        y2="17.6914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFFFD" />
        <stop offset="1" stop-color="#FFF492" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3412_359761"
        x1="10.3962"
        y1="6.725"
        x2="15.602"
        y2="17.9426"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.322917" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_3412_359761">
        <rect width="12.5408" height="24" fill="white" transform="translate(1)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
