import gql from 'graphql-tag'
import { AgreementTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${AgreementTypeFragment}
  query findAgreementType($filter: settings_agreement_type_bool_exp, $order: [settings_agreement_type_order_by!], $limit: Int){
    records: settings_agreement_type(where: $filter, order_by: $order, limit: $limit) {
      ...AgreementType
    }
  }
`

export default query
