import { Type } from 'class-transformer'
import { Entity } from '..'

import { ConditionType } from './ConditionType'
import { Evaluation } from './Evaluation'

export class EvaluationCondition extends Entity {
  @Type(() => ConditionType)
  conditionType: ConditionType;

  @Type(() => Evaluation)
  evaluation: Evaluation;

  get description () {
    const { conditionType: { description } } = this
    return description
  }

  get name (): string {
    const { conditionType: { name } } = this

    return name
  }

  get isIncreaseInitial () {
    const { name } = this

    return name === ConditionType.increaseInitial
  }
}
