import { Type } from 'class-transformer'
import { Entity } from '..'
import { Inspection, NegotiationResponse, PurchaseOrder } from '.'
import { ClosingReason, ProcessStatus } from '../settings'

export class Negotiation extends Entity {
  @Type(() => Inspection)
  inspection: Inspection;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => NegotiationResponse)
  responses: NegotiationResponse[];

  @Type(() => PurchaseOrder)
  purchaseOrder: PurchaseOrder;

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  cavValidation: Record<string, any> | null
  legalReportQualification: number
  agreedAmount: number
  taxAssessment: number

  get response () {
    const { responses } = this
    return responses && responses[responses.length - 1]
  }

  get lastResponse () {
    const { responses } = this

    const response = responses?.find(resp => resp.isExpectedOffer)

    return response?.[0]
  }

  get lastResponseConsignment () {
    const { responses } = this

    const response = responses?.find(resp => resp.isExpectedOfferConsignment)

    return response?.[0]
  }

  get auto () {
    const { inspection: { appraisal: { deal: { auto } } } } = this

    return auto
  }

  get pipeline () {
    const { inspection: { appraisal: { deal: { lead: { pipeline } } } } } = this

    return pipeline
  }

  get client () {
    const { inspection: { appraisal: { deal: { lead: { client } } } } } = this
    return client
  }

  get executive () {
    const { inspection: { appraisal: { deal: { lead: { executive } } } } } = this
    return executive
  }

  get appraisalPrice () {
    const { inspection: { appraisal: { price: value } } } = this
    return { value }
  }

  get dtPrice () {
    const { inspection: { appraisal: { price, priceConsignment } } } = this
    return { value: price || priceConsignment }
  }

  get inspectionCost () {
    const { inspection: { supervisorMetadata } } = this

    return { value: supervisorMetadata?.totalAmount }
  }

  get authorizedAmount () {
    const { inspection: { supervisorMetadata } } = this

    return { value: supervisorMetadata?.authorizedAmount }
  }

  get inspectionAmount () {
    const { inspectionCost, authorizedAmount } = this

    const value = authorizedAmount?.value ? authorizedAmount?.value : inspectionCost?.value
    return { value }
  }

  get offerPostInspection () {
    const { inspection, inspectionAmount } = this
    if (!inspection) return 0

    const { appraisal: { agreedAmount } } = inspection

    return agreedAmount - inspectionAmount.value
  }

  get authorizePostInspection () {
    const { inspection, inspectionAmount } = this
    if (!inspection) return 0

    const { appraisal: { lastAppraisalResponse } } = inspection

    if (lastAppraisalResponse?.value) {
      return lastAppraisalResponse.value - inspectionAmount.value
    }
    return 0
  }

  get authorizePostInspectionConsignment () {
    const { inspection, inspectionAmount } = this
    if (!inspection) return 0

    const { appraisal: { lastAppraisalConsignmentResponse } } = inspection

    if (lastAppraisalConsignmentResponse?.value) {
      return lastAppraisalConsignmentResponse.value - inspectionAmount.value
    }
    return 0
  }

  get negotiationResponseAmount () {
    const { responses } = this
    const response = responses?.find(resp => resp.isExpectedOffer)
    if (!response) return { value: 0 }

    return { value: response.amount, id: response.id }
  }

  get negotiationResponseAmountConsignment () {
    const { responses } = this

    const response = responses?.find(resp => resp.isExpectedOfferConsignment)
    if (!response) return { value: 0 }

    return { value: response.amount, id: response.id }
  }

  get negotiationResponse () {
    const { responses } = this

    const response = responses?.find(resp => resp.isExpectedOffer)

    if (!response) return { value: 0 }

    return { value: response.amount }
  }

  get negotiationResponseDt () {
    const { negotiationResponseAmount, negotiationResponseAmountConsignment } = this

    return { value: negotiationResponseAmount.value || negotiationResponseAmountConsignment.value }
  }

  get dtNegotiationResponse () {
    const { negotiationResponse, negotiationResponseConsignment } = this

    return { value: negotiationResponse.value || negotiationResponseConsignment.value }
  }

  get negotiationResponseConsignment () {
    const { responses, isConsignment } = this

    const response = responses?.find(resp => resp.isExpectedOfferConsignment)

    if (!response) return { value: 0 }

    return { value: response.amount }
  }

  get dtNegotiationResponseAppealed () {
    const { negotiationResponseAppealed, negotiationResponseAppealedConsignment } = this

    return { value: negotiationResponseAppealed.value || negotiationResponseAppealedConsignment.value }
  }

  get negotiationResponseAppealed () {
    const { responses } = this

    const response = responses?.find(resp => resp.isAppealed)
    if (!response) return { value: 0, id: null }

    return { value: response.amount, id: response.id }
  }

  get negotiationResponseAppealedConsignment () {
    const { responses } = this

    const response = responses?.find(resp => resp.isAppealedConsignment)
    if (!response) return { value: 0 }

    return { value: response.amount, id: response.id }
  }

  get responseAppealed () {
    const { negotiationResponseAppealed } = this

    return negotiationResponseAppealed?.value
  }

  get responseAppealedConsignment () {
    const { negotiationResponseAppealedConsignment } = this

    return negotiationResponseAppealedConsignment?.value
  }

  get agreementPrice () {
    const { inspection: { appraisal: { deal: { negotiationAmount } } } } = this

    return { value: negotiationAmount }
  }

  get rating () {
    const { legalReportQualification } = this

    return legalReportQualification || 0
  }

  get buttonPurchase () {
    const { id, status } = this

    return {
      disabled: !status.isApproved,
      parent: { id, model: 'Negotiation' },
      constructor: { name: 'PurchaseOrder' },
    }
  }

  get alert () {
    const { status } = this
    if (!status) return null

    if (status?.isToUpdating || status?.isPending || status?.isApproved || status?.isRejected) {
      return {
        icon: status.icon,
        color: '#FFFFFF',
        tooltip: `Negociación ${status.description}`,
        background: status.color,
      }
    }

    return undefined
  }

  get isConsignment () {
    const { inspection } = this

    if (!inspection?.appraisal) return false

    const { lastAppraisalResponse, agreementAmountPreInspection } = inspection?.appraisal

    return Number(agreementAmountPreInspection) <= Number(lastAppraisalResponse?.value)
  }
}
