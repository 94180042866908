import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment, AgreementFragment, ResponsibleAgreementFragment } from '../persons'
import { EvaluationConditionFragment } from './evaluationCondition'
import { EvaluationRequirementFragment } from './evaluationRequirement'
import { OfferFragment } from '@/graphql/fragments/loans/offer'
import { BusinessApproachFragment, ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { FinancedItemFragment } from '@/graphql/fragments/loans/financedItem'

export const BaseEvaluationFragment = gql`
  fragment BaseEvaluation on loans_evaluation{
    ${EntityFields}
    comment
    operationNumber:operation_number
    approvalDate:approval_date
    signedDate:signed_date
  }
`

export const EvaluationFragment = gql`
  ${BaseEvaluationFragment}
  ${AgreementFragment}
  ${PersonFragment}
  ${ClosingReasonFragment}
  ${ResponsibleAgreementFragment}
  ${ProcessStatusFragment}
  ${EvaluationConditionFragment}
  ${EvaluationRequirementFragment}
  ${BusinessApproachFragment}
  ${OfferFragment}
  ${FinancedItemFragment}
  fragment Evaluation on loans_evaluation{
    ...BaseEvaluation
    agreement {
      ...Agreement
    }
    backer {
      ...Person
    }
    closingReason:closing_reason {
      ...ClosingReason
    }
    responsibleAgreement:responsible_agreement {
      ...ResponsibleAgreement
    }
    status{
      ...ProcessStatus
    }
    conditions {
      ...EvaluationCondition
    }
    requirements {
      ...EvaluationRequirement
    }
    businessApproach: business_approach {
      ...BusinessApproach
    }
    offers{
      ...Offer
    }
    financing {
      id
      initial
      financedItems:financed_items{
        ...FinancedItem
      }
      warrantyAmount:warranty_amount
      applicant {
        id
      }
    }
    interveners{
      id
      person {
        ...Person
      }
    }
  }
`
