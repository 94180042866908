import { Actions } from '../../interfaces'
import { LaborData } from '@/entities/persons'

import find from './find'
import update from './update'
import create from './create'
import fetch from './fetch'

export const actions: Actions = {
  find,
  update,
  create,
  fetch,
}

export default {
  Model: { LaborData },
  actions: { [LaborData.name]: actions },
}
