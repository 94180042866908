import gql from 'graphql-tag'
import { EntityFields } from '../index'

export const DeductibleTypeFragment = gql`
  fragment DeductibleType on settings_deductible_type {
    ${EntityFields}
    name
    description
  }
`
