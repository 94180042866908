import gql from 'graphql-tag'

import { PersonLaborDataFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${PersonLaborDataFragment}
  query fetchPersonLaborData($id: Int!) {
    records: persons_labor_data_by_pk(id: $id) {
      ...PersonLaborData
    }
  }
`
export default query
