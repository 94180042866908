import { Actions } from '../../interfaces'
import { Bonus } from '@/entities/sales'

import find from './find'
import fetch from './fetch'
import update from './update'
import create from './create'

export const actions: Actions = {
  find,
  fetch,
  update,
  create,
}

export default {
  Model: { Bonus },
  actions: { [Bonus.name]: actions },
}
