import { Actions } from '../../interfaces'
import { AppraisalResponseType } from '@/entities/purchase'

import create from './create'
import fetch from './fetch'

export const actions: Actions = {
  create,
  fetch,
}

export default {
  Model: { AppraisalResponseType },
  actions: { [AppraisalResponseType.name]: actions },
}
