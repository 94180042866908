import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ProcessStatusFragment } from '../settings/processStatus'
import { VersionYearFragment } from '../vehicle/versionYear'
import { MaintenanceFragment } from '../public/maintenance'
import { BaseAutoMotiveRegistrationFragment } from '../vehicle'

export const BaseAutoFragment = gql`
  fragment AutoBase on auto {
    ${EntityFields}
    registrationPlate: registration_plate
    lastPrice: last_price
  }
`

export const AutoFragment = gql`
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${ProcessStatusFragment}
  ${MaintenanceFragment}
  ${BaseAutoMotiveRegistrationFragment}
  fragment Auto on auto {
    ...AutoBase
    version: version_year {
      ...VersionYear
    }
    generation{
      id
      sku
    }
    status: process_status {
      ...ProcessStatus
    }
    maintenances (order_by: {type: {mileage: desc}}){
      ...Maintenance
    }
  }
`
