import gql from 'graphql-tag'
import { EntityFields } from '..'

export const RolesFragment = gql`
  fragment Roles on roles {
    ${EntityFields}
    rol {
      id
      slug
    }
  }
`
