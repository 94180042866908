import gql from 'graphql-tag'
import { EntityFields } from '..'
import { StatusFragment } from '../settings/status'
import { ProcessFragment } from '../settings/process'

export const BaseProcessStatusFragment = gql`
  fragment ProcessStatusBase on settings_process_status {
    ${EntityFields}
    _description: description
    _icon:icon
    _color:color
    order
  }
`

export const ProcessStatusFragment = gql`
  ${BaseProcessStatusFragment}
  ${StatusFragment}
  ${ProcessFragment}
  fragment ProcessStatus on settings_process_status {
    ...ProcessStatusBase
    status {
      ...Status
    }
  }
`
