import { Type } from 'class-transformer'
import { Entity } from '..'

import { Process, Expense } from '.'

export class ProcessExpense extends Entity {
  @Type(() => Expense)
  expense: Expense;

  @Type(() => Process)
  process: Process;
}
