import { Actions } from '../../interfaces'

import { ItemType } from '@/entities/sales'

import fetch from './fetch'
import find from './find'

export const actions: Actions = {
  fetch,
  find,
}

export default {
  Model: { ItemType },
  actions: { [ItemType.name]: actions },
}
