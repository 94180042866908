import gql from 'graphql-tag'
import { InspectionQualificationFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${InspectionQualificationFragment}
  query findInspectionQualification($filter: purchase_inspection_qualification_bool_exp){
    records: purchase_inspection_qualification(where: $filter) {
      ...InspectionQualification
    }
  }
`

export default query
