import { Module } from 'vuex'

import { State as RootState } from '@/store/state'

import {
  Datatable,
  initState,
  getters,
  mutations,
} from './state'
import { actions } from './actions'

const module: Module<Datatable, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
