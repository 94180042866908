import gql from 'graphql-tag'
import { ProcessTrafficTicketFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${ProcessTrafficTicketFragment}
  query fetchProcessTrafficType($id: Int!) {
    records: purchase_process_traffic_ticket_by_pk(id: $id) {
      ...ProcessTrafficTicket
    }
  }
`

export default query
