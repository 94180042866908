import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Details } from '@/utils/generalInterface'
import { Address, bankDataFull, PersonFormatted } from '@/store/persons/person'

export interface Person {
  id: number
  firstName: string
  secondName: string
  surname: string
  secondSurname: string
  rut?: string
}

export interface PersonsDetails {
  id?: number | null
  age?: number | null | string
  firstName?: string | null
  name?: string | null
  rut?: string | null
  lastName?: string | null
  secondName?: string | null
  surname?: string | null
  secondSurname?: string | null
  lastSurname?: string | null
  companyName?: string | null
  alias?: string | null
  email?: string | null
  emailWork?: string | null
  phone?: string | null
  phoneWork?: string | null
  address?: string | null
  birthDate?: string | null
  birthDateDisplay?: string | null
  phoneWorkDisplay?: string | null
  website?: string | null
  socialNetworks?: string | null
  communicationService?: string | null
  emergencyContact?: string | null
  namePersonType?: string | null
  maritalStatus?: string | null
  idCompanyType?: number | null
  idCountry?: number | null
  // eslint-disable-next-line camelcase
  create_at?: string | null
  // eslint-disable-next-line camelcase
  create_by?: Details | null
  type?: string | null
  nationality?: number | null
  gender?: string | null
  bankData?: Array<bankDataFull>
  directionData?: Array<Address>
  laborData?: Array<any>
  phoneReferences?: Array<PersonsDetails>
  genderName?: string | null
  nationalityName?: string | null
  relationship?: string | null
  phoneReference?: PersonsDetails | null
  personType?: string | null
}

export interface Persons {
  persons: Array<PersonsDetails>
  personType: Array<Details>
  gender: Array<Details>
  civilStatus: Array<Details>
  foundPerson: PersonFormatted | undefined
  countries: Array<Details>
  rutInfo?: ConsultRutInterface
  id?: number | null
  disabledReferenceField?: boolean
  referencePerson?: PersonFormatted | undefined
  accountRequired: boolean
  addressRequired: boolean
  referenceRequired: boolean
  laborDataRequired: boolean
  laborId?: number
  rutError: boolean
  embedPerson?: any
  resetPhone: string
  loading: boolean
  type: string
}

export interface ConsultRutInterface {
  firstName: string
  firstLastName: string
  secondName: string
  secondLastName: string
  otherNames?: null
  rut: string
}

export function initState (): Persons {
  return {
    persons: [],
    personType: [],
    gender: [],
    civilStatus: [],
    foundPerson: undefined,
    countries: [],
    rutInfo: undefined,
    id: null,
    disabledReferenceField: true,
    referencePerson: undefined,
    accountRequired: false,
    addressRequired: false,
    referenceRequired: false,
    laborDataRequired: false,
    laborId: -1,
    rutError: false,
    embedPerson: null,
    resetPhone: '',
    loading: false,
    type: '',
  }
}

export const mutations: MutationTree<Persons> = {
  setType: (state, payload) => state.type = payload,
  setLoading: (state, payload) => state.loading = payload,
  setRutError (state, payload) {
    state.rutError = payload
  },
  setLaborDataRequired: (state, payload) => {
    state.laborDataRequired = payload
  },
  setLaborId: (state, id) => {
    state.laborId = id
  },
  setReferenceRequired: (state, payload) => {
    state.referenceRequired = payload
  },
  setAddressRequired: (state, payload) => {
    state.addressRequired = payload
  },
  setAccountRequired: (state, isRequired: boolean) => {
    state.accountRequired = isRequired
  },
  setReferencePerson (state, person: PersonFormatted): void {
    state.referencePerson = person
  },
  setDisabledReferenceField: (state, payload) => {
    state.disabledReferenceField = payload
  },
  setPersonTypeValue: (state, payload) => {
    state.personType = payload
  },
  setId: (state, payload) => {
    state.id = payload
  },
  setCountries: (state, countries) => {
    state.countries = countries
  },
  setFoundPerson: (state, foundPerson) => {
    state.foundPerson = foundPerson
  },
  setResetPhone: (state, phone) => {
    state.resetPhone = phone
  },
  setPersons: (state, persons) => {
    state.persons = persons
  },
  setPersonType: (state, personType) => {
    state.personType = personType
  },
  setGender: (state, gender) => {
    state.gender = gender
  },
  setCivilStatus: (state, civilStatus) => {
    state.civilStatus = civilStatus
  },
  setRutInfo: (state, rutInfo) => {
    state.rutInfo = rutInfo
  },
  setEmbedPerson: (state, person) => {
    state.embedPerson = person
  },
}

export const getters: GetterTree<Persons, RootState> = {
  isLaborDataRequired: state => state.laborDataRequired,
  isReferenceRequired: state => {
    return state.referenceRequired
  },
  isAccountRequired: ({ accountRequired }) => {
    return accountRequired
  },
  isAddressRequired: ({ addressRequired }) => {
    return addressRequired
  },
  persons: ({ persons }): Array<PersonsDetails> => {
    return persons
  },
  personType: ({ personType }): Array<Details> => {
    return personType
  },
  gender: ({ gender }): Array<Details> => {
    return gender
  },
  civilStatus: ({ civilStatus }): Array<Details> => {
    return civilStatus
  },
  getFoundPerson: ({ foundPerson }) => {
    return foundPerson
  },
  countries: ({ countries }) => {
    return countries
  },
  rutInfo: ({ rutInfo }) => {
    return rutInfo
  },
  personId: ({ id }) => {
    return id
  },
  disabledReferenceField: ({ disabledReferenceField }) => {
    return disabledReferenceField
  },
  referencePerson: ({ referencePerson }) => {
    return referencePerson
  },
  laborId: ({ laborId }) => {
    return laborId
  },
  rutError: state => state.rutError,
  getEmbedPerson: state => state.embedPerson,
  resetPhone: state => state.resetPhone,
  loading: state => state.loading,
  typeInfo: state => state.type,
}
