import { Actions } from '../../interfaces'

import { SaleOrderItem } from '@/entities/sales'
import create from './create'
import fetch from './fetch'
import update from './update'
import find from './find'

export const actions: Actions = {
  create,
  fetch,
  update,
  find,
}

export default {
  Model: { SaleOrderItem },
  actions: { [SaleOrderItem.name]: actions },
}
