import gql from 'graphql-tag'
import { PersonFragment } from '@/graphql/fragments/persons'
import { BaseFinancingFragment, EvaluationFragment, FinancedItemFragment } from '@/graphql/fragments/loans'
import { BasePriceFragment, SaleOrderFragment, SaleOrderItemFragment } from '@/graphql/fragments/sales'
import { LeadFragment } from '@/graphql/fragments/crm'

export const Fragment = gql`
  ${BaseFinancingFragment}
  ${PersonFragment}
  ${EvaluationFragment}
  ${SaleOrderFragment}
  ${LeadFragment}
  ${SaleOrderItemFragment}
  ${FinancedItemFragment}
  ${BasePriceFragment}
  fragment FinancingFragment on loans_financing{
    ...BaseFinancing
    applicant {
      ...Person
    }
    evaluations{
      ...Evaluation
    }
    saleOrder:sale_order {
      id
      saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
        ...SaleOrderItem
      }
      deal{
        id
        stock{
          id
          prices(order_by: {expiration: asc_nulls_first}, limit: 2) {
            ...BasePrice
          }
        }
        lead{
          ...LeadBase
          executive {
            ...Employee
            person{
              ...Person
            }
          }
        }
      }
      document {
        id
        interveners{
          id
          person {
            ...Person
          }
          field {
            id
            name
          }
        }
      }
    }
    financedItems:financed_items {
      ...FinancedItem
    }
  }
`
