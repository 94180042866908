import gql from 'graphql-tag'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${VersionYearFragment}
  query fetchVersionYear($id: Int!) {
    records: vehicle_version_year_by_pk(id: $id) {
      ...VersionYear
      attributes{
        id
        value
        componentValue: component_value {
          id
          value
        }
        component {
          id
          slug
          name
          favorite
        }
      }
      generations {
        id
        sku
      }
    }
  }
`

export default query
