import gql from 'graphql-tag'
import { PipelineFragment } from '@/graphql/fragments/crm'
import { AggregatePath } from '@/entities/interfaces'

const structure: Array<AggregatePath> = [
  { name: 'purchase', aggregations: ['count'] },
  { name: 'renewal', aggregations: ['count'] },
  { name: 'financed_renovation', aggregations: ['count'] },
]
const query = gql`
  ${PipelineFragment}
  query aggregates($model: crm_pipeline_bool_exp, $aggregate: purchase_appraisal_bool_exp = {}, $order: [crm_pipeline_order_by!]) {
    aggregates: crm_pipeline(where: $model, order_by: $order) {
      ...Pipeline
    }
    purchase: purchase_appraisal_aggregate(where: {_and: [$aggregate, {deal: {lead: {pipeline: {name: {_eq: "purchase"}}}}}]}) {
      aggregate {
        count(distinct: true)
      }
    }
    renewal: purchase_appraisal_aggregate(where: {_and: [$aggregate, {deal: {lead: {pipeline: {name: {_eq: "renewal"}}}}}]}) {
      aggregate {
        count(distinct: true)
      }
    }
    financed_renovation: purchase_appraisal_aggregate(where: {_and: [$aggregate, {deal: {lead: {pipeline: {name: {_eq: "financed_renovation"}}}}}]}) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`
export const aggregate = { structure, query, global: true }
export default aggregate
