import { Actions } from '../../interfaces'

import { Payment } from '@/entities/finance'
import create from './create'
import fetch from './fetch'
import find from './find'
import findLite from './findLite'
import update from './update'

export const actions: Actions = {
  create,
  fetch,
  find,
  findLite,
  update,
}

export default {
  Model: { Payment },
  actions: { [Payment.name]: actions },
}
