import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ClosingReasonTypeFragment } from './closingReasonType'

export const ClosingReasonFragmentBase = gql`
  fragment ClosingReasonBase on settings_closing_reason{
    ${EntityFields}
    _description: description
    _icon:icon
    _color:color
    order
  }
`

export const ClosingReasonFragment = gql`
  ${ClosingReasonFragmentBase}
  ${ClosingReasonTypeFragment}
  fragment ClosingReason on settings_closing_reason{
    ...ClosingReasonBase
    type {
      ...ClosingReasonType
    }
  }
`
