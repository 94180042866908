<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" fill="white" />
    <path
      d="M3.81823 3.44238V4.63273H2.90088H1.98352V9.9893V15.3459H2.90088H3.81823V18.3217V21.2976H8.40501H12.9918V20.7024V20.1073H9.01658H5.04137V17.7266V15.3459H8.40501H11.7686V9.9893V4.63273H8.40501H5.04137V4.03755V3.44238H9.32236H13.6034V5.82308V8.20377H16.0496H18.4959V8.79895V9.39412H19.1075H19.7191V8.37861V7.36681L17.0893 4.80756L14.4634 2.25203H9.13889H3.81823V3.44238ZM16.2408 5.65568L17.6359 7.01343H16.2293H14.8265V5.65568C14.8265 4.908 14.8303 4.29794 14.838 4.29794C14.8418 4.29794 15.4725 4.908 16.2408 5.65568ZM10.5455 8.93658V12.0538L9.63579 11.1685L8.72226 10.2794L6.76141 12.2175L4.79674 14.1555H4.0017H3.20666V9.9893V5.82308H6.87608H10.5455V8.93658ZM9.63579 12.9168L10.5455 13.8021V13.977V14.1555H8.53114H6.51296L7.60232 13.0954C8.20243 12.5113 8.69933 12.0352 8.71079 12.0352C8.72226 12.0352 9.13889 12.4332 9.63579 12.9168Z"
    />
    <path
      d="M5.46936 6.49259C5.07184 6.59303 4.75459 6.77158 4.46792 7.05057C3.61172 7.88381 3.60789 9.23411 4.46409 10.0674C5.02215 10.6105 5.80955 10.815 6.58548 10.6216C6.99447 10.5212 7.23145 10.3873 7.5487 10.0822C7.8736 9.76977 8.03796 9.49822 8.13734 9.11136C8.55779 7.51555 7.09385 6.07969 5.46936 6.49259ZM6.34467 7.66434C7.00211 7.89125 7.20852 8.70217 6.73837 9.20807C6.44787 9.52054 5.90511 9.59866 5.52287 9.37919C4.97628 9.06672 4.8769 8.33019 5.32411 7.89497C5.59932 7.63086 5.9892 7.54159 6.34467 7.66434Z"
    />
    <path
      d="M15.545 10.0265C14.3525 10.2385 13.4542 11.0159 13.0988 12.1542C13.0147 12.422 13.0147 12.4667 12.9994 14.8809C12.9841 17.5033 12.9956 17.734 13.1714 18.318C13.76 20.3118 15.7897 21.558 17.934 21.2418C18.8169 21.1116 19.5776 20.7508 20.2274 20.1481C20.7434 19.6683 21.1027 19.1252 21.3244 18.4817C21.5384 17.853 21.5537 17.641 21.5537 15.1859V12.9651H20.9422H20.3344L20.3229 15.3161C20.3076 17.6298 20.3076 17.6745 20.2236 17.9423C19.8987 18.9727 19.1342 19.7129 18.0869 20.0031C17.6932 20.1147 16.8905 20.1221 16.5083 20.018C15.4342 19.7315 14.6506 18.9838 14.3219 17.9423C14.2378 17.6745 14.2378 17.6335 14.2225 15.2342C14.2111 12.5113 14.2149 12.463 14.4671 12.0501C15.0673 11.0606 16.5121 10.8857 17.3492 11.7004C17.59 11.9347 17.7735 12.2621 17.8423 12.5857C17.869 12.7159 17.8843 13.5268 17.8843 15.0222C17.8843 17.1611 17.8805 17.269 17.8079 17.4215C17.6206 17.8232 16.9249 17.8232 16.7376 17.4215C16.665 17.269 16.6612 17.1611 16.6612 15.1115V12.9651H16.0496H15.438V15.1412C15.438 16.5845 15.4533 17.3806 15.4801 17.5108C15.6253 18.1878 16.1872 18.7346 16.8829 18.876C17.8958 19.0806 18.8781 18.4593 19.0692 17.4847C19.0998 17.3248 19.1074 16.5622 19.0998 14.8437C19.0845 12.4667 19.0845 12.422 19.0004 12.1542C18.6793 11.1275 17.9111 10.3836 16.8829 10.1009C16.5694 10.0116 15.8432 9.97066 15.545 10.0265Z"
    />
  </svg>
</template>

<script>
  export default {}
</script>
