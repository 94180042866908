import { Route, RouteRecord } from 'vue-router'
import { Store } from 'vuex'
import { State } from '@/store/state'
import { Dictionary } from 'vue-router/types/router'

export async function firebaseIsLoaded (store: Store<State>): Promise<Boolean> {
  if (!store.getters.isReady) {
    return new Promise(resolve =>
      setTimeout(() => firebaseIsLoaded(store).then(resolve), 1000)
    )
  }

  return true
}

export function toRouteRecord (to: Route | RouteRecord, params?: Dictionary<string>): RouteRecord {
  if (!params && 'params' in to) params = to.params
  const _route = 'matched' in to ? to.matched.find(({ name }) => name === to.name) : to

  if (!_route?.meta.inherit || !_route.parent) {
    const route = _route || to as RouteRecord
    const { meta: { auth } } = route
    if (auth?.param && params) route.name = params[auth.param]
    return route
  }

  return toRouteRecord(_route.parent, params)
}
