import { Module } from 'vuex'
import { getters, initState, Settings } from '@/store/settings/state'
import { State as RootState } from '@/store/state'
import { mutations } from '@/store/settings/mutations'
import { actions } from '@/store/settings/actions'
import Roles from '@/store/settings/roles'
import Resources from '@/store/settings/resources'
import Teams from '@/store/settings/team'

const module: Module<Settings, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
  modules: {
    roles: Roles,
    resources: Resources,
    teams: Teams,
  },
}

export default module
