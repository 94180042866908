import gql from 'graphql-tag'

import { OfferFragment } from '@/graphql/fragments/loans'

export const Fragment = gql`
  ${OfferFragment}
  fragment OfferForm on loans_offer {
    ...Offer
  }
`
export default Fragment
