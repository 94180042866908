import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { Files } from '@/store/files/state'

export const actions: ActionTree<Files, RootState> = {
  insertId: ({ commit }, payload) => {
    commit('insertId', payload)
  },
  deleteId: ({ commit }, payload) => {
    commit('deleteId', payload)
  },
}
