import { Type } from 'class-transformer'
import { Entity } from '..'
import { ProductApproach } from './ProductApproach'
import { Evaluation } from '@/entities/loans'

export class BusinessApproach extends Entity {
  @Type(() => ProductApproach)
  products: ProductApproach[];

  @Type(() => Evaluation)
  evaluations: Evaluation[]

  name: string;
  description: string;
}
