import { GetterTree, MutationTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { Resource } from '@/entities/public'
import { getObjectValueByPath, setValueByPath } from '@/utils/vuetify/helpers'

export interface IForms {
  [key: string]: Record<string, Resource>
}

export interface Crumb {
  resource: Resource
  title?: string
  path?: string
}

export interface Forms {
  path: string
  value: {}
  crumbs: Array<Crumb>
  backup: Record<string, any>
  auxiliaryBackup: Record<string, any>
  staticForm: boolean
}

export function initState (): Forms {
  return {
    path: '',
    value: {},
    crumbs: [],
    backup: null,
    staticForm: false,
    auxiliaryBackup: null,
  }
}

export const mutations: MutationTree<Forms> = {
  setStaticForm: (state, staticForm) => state.staticForm = staticForm,
  setBackup: (state, backup) => state.backup = backup,
  updateForm: (state, form) => {
    if (state.path) setValueByPath(state.value, state.path, form)
    else state.value = form
  },
  setCrumbs: (state, crumbs) => {
    state.crumbs = crumbs.map(crumb => {
      if ('title' in crumb) return crumb

      const { resource: { name }, path } = crumb
      Object.defineProperty(crumb, 'title', {
        get () {
          const item = !path ? state.value : getObjectValueByPath(state.value, path)
          return `${item?.id ? 'Editar' : 'Crear'} ${name}`
        },
      })

      return crumb
    })
    state.path = state.crumbs.map(({ path }) => path).filter(_ => _).join('.')
    localStorage.setItem('crumbs', JSON.stringify(state.crumbs))
  },
}

export const getters: GetterTree<Forms, RootState> = {
  active: ({ value, path }) => !path ? value : getObjectValueByPath(value, path),
  crumbs: ({ crumbs, value }) => {
    if (!crumbs.length) {
      const localCrumbs = localStorage.getItem('crumbs')
      if (localCrumbs) {
        crumbs = JSON.parse(localCrumbs)
      }
    }
    return crumbs
  },
  path: ({ path }) => path,
  resource: ({ crumbs }) => crumbs[crumbs.length - 1]?.resource,
  backup: ({ backup }) => backup,
  staticForm: ({ staticForm }) => staticForm,
}
