import gql from 'graphql-tag'
import { EntityFields } from '..'

export const BaseKeyIdentifierFragment = gql`
  fragment BaseKeyIdentifier on sales_key_identifier{
    ${EntityFields}
    keyId:key_id
  }
`

export const KeyIdentifierFragment = gql`
  ${BaseKeyIdentifierFragment}
  fragment KeyIdentifier on sales_key_identifier{
    ...BaseKeyIdentifier
  }
`
