import { Actions } from '../../interfaces'

import find from './find'
import { ClosingReason } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { ClosingReason },
  actions: { [ClosingReason.name]: actions },
}
