import { Type } from 'class-transformer'
import { Entity } from '..'

import { ItemType } from './ItemType'
import { SaleOrder } from './SaleOrder'

export class SaleOrderItem extends Entity {
  @Type(() => ItemType)
  type: ItemType;

  @Type(() => SaleOrder)
  saleOrder: SaleOrder;

  quantity?: number;
  amount: number;
  tax?: number;

  get itemName () {
    const { type: { name } } = this
    return name
  }

  get isProduct () {
    const { itemName } = this
    return itemName === ItemType.product
  }

  get isTransfer () {
    const { itemName } = this
    return itemName === ItemType.transfer
  }

  get isPostFinancing () {
    const { itemName } = this
    return itemName === ItemType.postFinancingDiscount
  }

  get isDiscount () {
    const { itemName } = this
    return itemName === ItemType.discount
  }

  get subTotal () {
    const { quantity = 1, amount, tax = 0, type: { negative } } = this
    if (!amount) return 0

    let subtotal = amount * quantity
    subtotal += subtotal * tax

    return negative && subtotal > 0 ? -subtotal : subtotal
  }
}
