import { Type } from 'class-transformer'
import { Entity } from '..'
import { Person } from '@/entities/persons'
import { Field, Document } from '.'

export class Intervener extends Entity {
    @Type(() => Document)
    document: Document;

    @Type(() => Field)
    field: Field;

    @Type(() => Person)
    person: Person;
}
