import { Type } from 'class-transformer'
import { Entity } from '..'

import { Model, VersionYear } from '.'

export class Version extends Entity {
  @Type(() => Model)
  model: Model;

  @Type(() => VersionYear)
  years: VersionYear[];

  name: string;
  discontinued: boolean;

  get title () {
    const { model, name } = this
    return `${model.title} ${name}`
  }
}
