<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8987 15.6324C13.8995 15.541 13.9 15.4754 13.9 15.4417L13.8999 15.334C13.9861 14.3036 14.9391 14.0739 15.35 14.15V13.4006C15.7148 13.3923 16.1713 13.4008 16.7423 13.4115L16.8 13.4126V14.1H19.5V13.4354H21V14.1H21.9V12.6L21.875 12.6222V12.5917L19.7083 6.34165C19.5 5.73749 18.9167 5.29999 18.2292 5.29999H6.77083C6.08333 5.29999 5.5 5.73749 5.29167 6.34165L3.125 12.5917V20.925C3.125 21.4979 3.59375 21.9667 4.16667 21.9667H5.20833C5.78125 21.9667 6.25 21.4979 6.25 20.925V19.8833L13.9 19.9917C13.8584 19.717 13.8833 17.2019 13.895 16.0218L13.895 16.0211C13.8964 15.8726 13.8977 15.7453 13.8986 15.6463L13.9 15.65C13.8995 15.6441 13.8991 15.6382 13.8987 15.6324ZM18.2292 6.86249H6.77083L5.20833 11.55H19.7917L18.2292 6.86249ZM5.20833 15.1958C5.20833 16.0604 5.90625 16.7583 6.77083 16.7583C7.63542 16.7583 8.33333 16.0604 8.33333 15.1958C8.33333 14.3312 7.63542 13.6333 6.77083 13.6333C5.90625 13.6333 5.20833 14.3312 5.20833 15.1958Z"
    />
    <path
      d="M16.9333 17.445V18.255H16.0889V17.445H16.9333ZM18.6222 17.445V18.255H17.7778V17.445H18.6222ZM20.3111 17.445V18.255H19.4667V17.445H20.3111ZM21.1555 14.61C21.3795 14.61 21.5943 14.6953 21.7527 14.8472C21.911 14.9991 22 15.2052 22 15.42V21.09C22 21.3048 21.911 21.5108 21.7527 21.6627C21.5943 21.8146 21.3795 21.9 21.1555 21.9H15.2444C14.7758 21.9 14.4 21.5355 14.4 21.09V15.42C14.4 15.2052 14.489 14.9991 14.6473 14.8472C14.8057 14.6953 15.0205 14.61 15.2444 14.61H15.6667V13.8H16.5111V14.61H19.8889V13.8H20.7333V14.61H21.1555ZM21.1555 21.09V16.635H15.2444V21.09H21.1555ZM16.9333 19.065V19.875H16.0889V19.065H16.9333ZM18.6222 19.065V19.875H17.7778V19.065H18.6222ZM20.3111 19.065V19.875H19.4667V19.065H20.3111Z"
    />
  </svg>
</template>

<script>
  export default {}
</script>
