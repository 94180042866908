import gql from 'graphql-tag'
import { TransferTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${TransferTypeFragment}
  query findTransferType($filter: settings_transfer_type_bool_exp, $order: [settings_transfer_type_order_by!], $limit: Int){
    records: settings_transfer_type(where: $filter, order_by: $order, limit: $limit) {
      ...TransferType
    }
  }
`

export default query
