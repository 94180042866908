import { Actions } from '../../interfaces'
import { CompanyType } from '@/entities/persons'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { CompanyType },
  actions: { [CompanyType.name]: actions },
}
