import { Actions } from '../../interfaces'
import { Maintenance } from '@/entities/public'

import find from './find'
import create from './create'
import update from './update'
import remove from './remove'

export const actions: Actions = {
  find,
  create,
  update,
  remove,
}

export default {
  Model: { Maintenance },
  actions: { [Maintenance.name]: actions },
}
