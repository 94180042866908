import gql from 'graphql-tag'
import { ClosingReasonTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ClosingReasonTypeFragment}
  query findClosingReasonType{
    records: settings_closing_reason{
      ...ClosingReasonType
    }
  }
`

export default query
