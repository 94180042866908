import gql from 'graphql-tag'

import { EvaluationConditionFragment } from '@/graphql/fragments/loans'

export const query = gql`
  ${EvaluationConditionFragment}
  query fetchEvaluationCondition($id: Int!) {
    records: loans_evaluation_condition_by_pk(id: $id) {
      ...EvaluationCondition
    }
  }
`

export default query
