import gql from 'graphql-tag'
import { EntityFields } from '..'

export const RegionFragment = gql`
  fragment Region on region {
    ${EntityFields}
    name
    abbreviation
  }
`
