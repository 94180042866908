import gql from 'graphql-tag'
import { ExchangeRateFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ExchangeRateFragment}
  query findExchange($filter: settings_exchange_rate_bool_exp!,$order: [settings_exchange_rate_order_by!], $limit: Int){
    records:settings_exchange_rate(where: $filter,order_by: $order, limit: $limit) {
      ...ExchangeRate
    }
  }
`

export default query
