import { Actions } from '../../interfaces'

import { Intervener } from '@/entities/documents'

import create from './create'
import remove from './remove'
import find from './find'

export const actions: Actions = {
  create,
  remove,
  find,
}

export default {
  Model: { Intervener },
  actions: { [Intervener.name]: actions },
}
