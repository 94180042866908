import { Actions } from '../../interfaces'

import { PaymentType } from '@/entities/finance'

import fetch from './fetch'
import find from './find'

export const actions: Actions = {
  fetch,
  find,
}

export default {
  Model: { PaymentType },
  actions: { [PaymentType.name]: actions },
}
