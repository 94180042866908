import gql from 'graphql-tag'
import { MaritalStatusFragment } from '@/graphql/fragments/persons/maritalStatus'

export const query = gql`
  ${MaritalStatusFragment}
  query findMaritalStatus($filter: persons_marital_status_bool_exp, $limit: Int) {
    records: persons_marital_status(where: $filter, limit: $limit) {
      ...MaritalStatus
    }
  }
`
export default query
