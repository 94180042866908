import gql from 'graphql-tag'

export const query = gql`
  query findUserLoggedActions($filter: audit_logged_actions_bool_exp!) {
    records: audit_logged_actions(distinct_on: hasura_user, where: $filter) {
      hasuraUser:hasura_user
    }
  }
`

export default query
