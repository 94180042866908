import gql from 'graphql-tag'
import { RotationIndexFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${RotationIndexFragment}
  query rotationIndex($filter: vehicle_rotation_index_bool_exp,, $order: [vehicle_rotation_index_order_by!]) {
    records: vehicle_rotation_index(where: $filter, order_by: $order){
      ...RotationIndex
    }
  }
`
export default query
