import { Type } from 'class-transformer'
import { Entity } from '..'

import { FileProcess, FileType } from '.'
import { Process } from '../settings'

class FileParameterVisibility {
  form: {
    order: number
  }
}

export class FileParameter extends Entity {
  @Type(() => FileType)
  fileType: FileType;

  @Type(() => Process)
  process: Process;

  @Type(() => FileProcess)
  files: FileProcess[];

  @Type(() => FileParameterVisibility)
  visibility: FileParameterVisibility;

  name: string;
  required: boolean;
  multiple?: boolean;
}
