import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'
import { ResourceTypeFragment } from './resourceType'

export const ResourceFragment = gql`
  ${ResourceTypeFragment}
  fragment Resource on resource {
    ${EntityFields}
    ${EnumFields}
    slug
    metadata
    icon
    order
    resourceType: resource_type {
      ...ResourceType
    }
  }
`
