import { Actions } from '../../interfaces'
import { Enablement } from '@/entities/purchase'

import create from './create'
import fetch from './fetch'
import update from './update'
import find from './find'

export const actions: Actions = {
  create,
  fetch,
  update,
  find,
}

export default {
  Model: { Enablement },
  actions: { [Enablement.name]: actions },
}
