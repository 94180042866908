import { Type } from 'class-transformer'
import { Entity } from '..'

import { Service } from './Service'
import { Agreement } from '../persons'

export class AgreementType extends Entity {
  @Type(() => Service)
  serviceType: Service;

  @Type(() => Agreement)
  agreements: Agreement[];

  name: string;

  description: string;
}
