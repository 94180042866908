import { Module } from 'vuex'

import { State as RootState } from '@/store/state'

import { initState, getters, mutations, Resources } from './state'
import { actions } from './actions'

import details from './details'

const module: Module<Resources, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
  modules: {
    details,
  },
}

export default module
