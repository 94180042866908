import { ActionTree } from 'vuex'
import { State as RootState } from '@/store/state'

import {
  // @ts-ignore
  createResourceGroup,
  // @ts-ignore
  createResource,
  // @ts-ignore
  deleteResource,
  // @ts-ignore
  updateResource,
  // @ts-ignore
  findResource,
} from './mutation'
import { Resource, Resources } from '@/store/settings/resources/state'
import { ResourcesClass } from '@/store/settings/resources/resources'
import {
  getResource,
  getResourcesForRoles,
  getResourcesGroup,
  getTotalResources,
} from '@/store/settings/resources/queries/resources'

export const actions: ActionTree<Resources, RootState> = {
  getResources: async (
    { commit, rootState: { apolloClient } },
    { offset = 0, limit = 5, search = '%%' }
  ): Promise<void> => {
    if (!apolloClient) return

    const observer = await apolloClient.subscribe({
      query: getResource,
      variables: { limit, offset, search },
    })

    observer.subscribe({
      next ({ data: { resources } }) {
        const resourcesInitialized = resources.map(
          (resource: Resource) => new ResourcesClass(resource)
        )

        commit('setResources', resourcesInitialized)
      },
    })
  },
  getResourcesGroup: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const observer = apolloClient.subscribe({ query: getResourcesGroup })
    observer.subscribe({
      next ({ data: { resourceGroup } }) {
        const resourcesInitialized = resourceGroup.map(
          (resource: Resource) => new ResourcesClass(resource)
        )
        commit('setResourcesGroup', resourcesInitialized)
      },
    })
  },
  createGroup: async (
    { commit, rootState: { apolloClient } },
    group
  ): Promise<void> => {
    if (!apolloClient) return

    const name = group.name.toLowerCase().split(' ').join('-')
    const {
      data: {
        group: { id },
      },
    } = await apolloClient.mutate({
      mutation: createResourceGroup,
      variables: {
        name,
        title: group.name,
        icon: group.icon,
        description: group.description,
      },
    })

    commit('setGroupIdCreated', id)

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: { message: 'Grupo Creado Correctamente' },
      })
    )
  },

  createResource: async (
    { rootState: { apolloClient } },
    resource
  ): Promise<void> => {
    if (!apolloClient) return

    const slug = resource.name.toLowerCase().split(' ').join('-')
    const position = resource.position !== -1 ? resource.position : null

    await apolloClient.mutate({
      mutation: createResource,
      variables: {
        description: resource.description,
        positionId: position,
        icon: resource.icon,
        groupId: resource.groupId,
        metadata: JSON.stringify(resource.metadata),
        name: resource.name,
        slug,
      },
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: { message: 'Recurso creado Correctamente' },
      })
    )
  },

  deleteResource: async (
    { rootState: { apolloClient } },
    resourceIds
  ): Promise<void> => {
    if (!apolloClient) return

    await apolloClient.mutate({
      mutation: deleteResource,
      variables: {
        resources: resourceIds,
      },
    })

    if (resourceIds.length > 1) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: 'Recursos eliminados Correctamente' },
        })
      )
    } else {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: 'Recurso eliminado Correctamente' },
        })
      )
    }
  },
  updateResource: async (
    { rootState: { apolloClient } },
    resource
  ): Promise<void> => {
    if (!apolloClient) return

    const slug = resource.name.toLowerCase().split(' ').join('-')
    const position = resource.position !== -1 ? resource.position : null

    await apolloClient.mutate({
      mutation: updateResource,
      variables: {
        id: resource.id,
        description: resource.description,
        positionId: position,
        icon: resource.icon,
        groupId: resource.groupId,
        metadata: JSON.stringify(resource.metadata),
        name: resource.name,
        slug,
      },
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: { message: 'Recurso actualizado Correctamente' },
      })
    )
  },
  findResource: async (
    { commit, rootState: { apolloClient } },
    id
  ): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { resource },
    } = await apolloClient.query({
      query: findResource,
      variables: { id },
    })

    commit('setResource', resource[0])
  },
  getTotalResources: async (
    { commit, rootState: { apolloClient } },
    search
  ): Promise<void> => {
    if (!apolloClient) return

    const observer = await apolloClient.subscribe({
      query: getTotalResources,
      variables: { search },
    })

    observer.subscribe({
      next ({
        data: {
          totalResources: {
            aggregate: { count },
          },
        },
      }) {
        commit('setTotalResources', count)
      },
    })
  },
  getResourcesForRoles: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const observer = await apolloClient.subscribe({
      query: getResourcesForRoles,
    })

    observer.subscribe({
      next ({ data: { resources } }) {
        commit('setResourcesForRoles', resources)
      },
    })
  },
}
