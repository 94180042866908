import gql from 'graphql-tag'
import { EntityFields } from '..'
import { CompanyFragment } from '../persons'

export const BaseMarketPriceFragment = gql`
  fragment MarketPriceBase on purchase_appraisal_market_price {
    ${EntityFields}
    link
    mileageFrom: mileage_from
    mileageTo: mileage_to
    vehiclesNumber: vehicles_number
    minimumLowPrice: minimum_low_price
    medianLowPrice: median_low_price
    maximumLowPrice: maximum_low_price
    minimumMediumPrice: minimum_medium_price
    medianMediumPrice: median_medium_price
    maximumMediumPrice: maximum_medium_price
    minimumHighPrice: minimum_high_price
    medianHighPrice: median_high_price
    maximumHighPrice: maximum_high_price
  }
`

export const MarketPriceFragment = gql`
  ${BaseMarketPriceFragment}
  ${CompanyFragment}
  fragment MarketPrice on purchase_appraisal_market_price {
    ...MarketPriceBase
    company {
      ...Company
    }
  }
`
