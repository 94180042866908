import { ActionTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Catalogue } from '@/store/vehicles/catalogue/state'
import {
  getAttributesWithOutSku,
  getAttributesWithSku,
  getBrands,
  getModels,
  getSkus,
  getVersions,
  getVersionYear,
  getYears,
} from '@/store/vehicles/catalogue/queries/catalogueInfo'

const actions: ActionTree<Catalogue, RootState> = {
  loadYears: async ({ commit, rootState: { apolloClient } }): Promise<void> => {
    if (!apolloClient) return
    commit('setYears', [])
    const observer = apolloClient.subscribe({
      query: getYears,
      fetchPolicy: 'network-only',
    })

    observer.subscribe({
      next ({ data: { years } }) {
        commit('setYears', years)
      },
    })
  },
  loadBrands: async (
    { commit, rootState: { apolloClient } },
    year
  ): Promise<void> => {
    if (!apolloClient || !year || year === -1) return
    commit('setBrands', [])
    const observer = apolloClient.subscribe({
      query: getBrands,
      variables: { year },
      fetchPolicy: 'network-only',
    })

    observer.subscribe({
      next ({ data: { brands } }) {
        commit('setBrands', brands)
      },
    })
  },
  loadModels: async (
    { commit, rootState: { apolloClient } },
    { brand, year }
  ): Promise<void> => {
    if (!apolloClient || !year || year === -1 || !brand || brand === -1) return
    commit('setModels', [])
    const observer = apolloClient.subscribe({
      query: getModels,
      variables: { brand, year },
      fetchPolicy: 'network-only',
    })

    observer.subscribe({
      next ({ data: { models } }) {
        commit('setModels', models)
      },
    })
  },
  loadVersions: async (
    { commit, rootState: { apolloClient } },
    { model, year }
  ): Promise<void> => {
    if (!apolloClient || !year || year === -1 || !model || model === -1) return
    commit('setVersions', [])
    const observer = apolloClient.subscribe({
      query: getVersions,
      variables: { model, year },
      fetchPolicy: 'network-only',
    })

    observer.subscribe({
      next ({ data: { versions } }) {
        commit('setVersions', versions)
      },
    })
  },
  loadDetails: async (
    { commit, rootState: { apolloClient } },
    { year, version }
  ): Promise<void> => {
    if (!apolloClient || !year || !version || year === -1 || version === -1) return
    commit('setSkus', [])
    commit('setVersionYear', null)

    const {
      data: { versionYear },
    } = await apolloClient.query({
      query: getVersionYear,
      variables: { year, version },
      fetchPolicy: 'network-only',
    })

    if (versionYear.length) {
      commit('setVersionYear', versionYear[0].id)

      const {
        data: { skus },
      } = await apolloClient.query({
        query: getSkus,
        variables: { versionYear: versionYear[0].id },
        fetchPolicy: 'network-only',
      })

      if (skus.length) {
        commit('setSkus', skus)
      } else {
        const {
          data: { attributes },
        } = await apolloClient.query({
          query: getAttributesWithOutSku,
          variables: { versionYear: versionYear[0].id },
          fetchPolicy: 'network-only',
        })
        commit('setAttributes', attributes)
      }
    }
  },
  loadDetailsWithSku: async (
    { commit, rootState: { apolloClient } },
    { sku, versionYear }
  ): Promise<void> => {
    if (!apolloClient || !sku || !versionYear) return
    commit('setAttributes', [])
    commit('setEquipment', [])

    const {
      data: { attributes },
    } = await apolloClient.query({
      query: getAttributesWithSku,
      variables: { sku, versionYear },
      fetchPolicy: 'network-only',
    })

    commit(
      'setAttributes',
      // @ts-ignore
      attributes.filter(att => !att.component.name.includes('Equipamiento'))
    )
    // @ts-ignore
    const equipment = attributes.filter(att =>
      att.component.name.includes('Equipamiento')
    )

    commit('setEquipment', equipment)
  },
}

export default actions
