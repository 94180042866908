import gql from 'graphql-tag'
import { GenerationFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${GenerationFragment}
  query generation($filter: vehicle_generation_bool_exp, $order: [vehicle_generation_order_by!]) {
    records: vehicle_generation(where: $filter, order_by: $order) {
      ...Generation
    }
  }

`
export default query
