import { Actions } from '../../interfaces'

import { DocumentType } from '@/entities/documents'
import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { DocumentType },
  actions: { [DocumentType.name]: actions },
}
