import gql from 'graphql-tag'

import { SaleProductFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${SaleProductFragment}
  mutation insertSaleProduct($fields: sales_sale_product_insert_input!) {
    record: insert_sales_sale_product_one(object: $fields) {
      ...SaleProduct
    }
  }
`

export default mutation
