import gql from 'graphql-tag'

import { SaleOrderItemFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${SaleOrderItemFragment}
  query fetchSaleOrderItem($filter: sales_sale_order_item_bool_exp, $order: [sales_sale_order_item_order_by!], $limit: Int){
    records: sales_sale_order_item(where: $filter, order_by: $order, limit: $limit) {
      ...SaleOrderItem
    }
  }
`

export default query
