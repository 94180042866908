import gql from 'graphql-tag'

import { NegotiationResponseFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${NegotiationResponseFragment}
  mutation updateNegotiationResponse($id: Int!, $fields: purchase_negotiation_response_set_input!) {
    record: update_purchase_negotiation_response_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...NegotiationResponse
    }
  }
`

export default mutation
