import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const StatusFragment = gql`
  fragment Status on settings_status {
    ${EntityFields}
    ${EnumFields}
    icon
    color
  }
`
