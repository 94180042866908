import { Actions } from '../../interfaces'

import { File } from '@/entities/files'
import fetch from './fetch'
import find from './find'
import create from './create'
import update from './update'

export const actions: Actions = {
  create,
  fetch,
  find,
  update,
}

export default {
  Model: { File },
  actions: { [File.name]: actions },
}
