import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PaymentTypeFragment } from '@/graphql/fragments/finance'

export const BaseProjectionPaymentFragment = gql`
  fragment BaseProjectionPayment on sales_projection_payment {
    ${EntityFields}
  }
`

export const ProjectionPaymentFragment = gql`
  ${BaseProjectionPaymentFragment}
  ${PaymentTypeFragment}
  fragment ProjectionPayment on sales_projection_payment {
    ...BaseProjectionPayment
    payment {
      ...PaymentType
    }
  }
`
