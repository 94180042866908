import gql from 'graphql-tag'

export const versionYearAggregates = gql`
  query versionYearAggregates($aggregate: vehicle_version_year_bool_exp, $order: [vehicle_version_year_order_by!]) {
    versionYears:vehicle_version_year_aggregate(where: $aggregate, order_by: $order) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`
