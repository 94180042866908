import gql from 'graphql-tag'

import { PersonLaborDataFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${PersonLaborDataFragment}
  query findPersonLaborData($filter: persons_labor_data_bool_exp) {
    records: persons_labor_data(where: $filter) {
      ...PersonLaborData
    }
  }
`
export default query
