import gql from 'graphql-tag'

import { SaleOrderFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${SaleOrderFragment}
  mutation updateSaleOrder($id: Int!,$fields: sales_sale_order_set_input!) {
    record: update_sales_sale_order_by_pk(pk_columns: {id: $id}, _set: $fields){
      id
    }
  }
`

export default mutation
