import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const PipelineFragment = gql`
  fragment Pipeline on crm_pipeline{
    ${EntityFields}
    ${EnumFields}
    active
    color
    icon
  }
`
