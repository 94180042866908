<template>
  <svg width="20" height="22" viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7366 15.9113V5.67879C19.5312 5.04912 18.8347 4.52434 18.5121 4.34066C16.82 3.38301 13.0963 1.27488 11.7374 0.50351C10.0388 -0.460705 8.71544 0.22802 8.35992 0.385443C8.0755 0.511381 3.42212 3.24529 1.13098 4.5965C0.135516 5.24194 -0.0343449 6.256 0.00515758 6.68236C0.0314899 8.99778 0.0802042 13.9041 0.0644032 15.0061C0.0486022 16.108 0.808366 17.0526 1.19022 17.3871L9.24873 21.9327C10.2955 22.1885 11.3555 21.6506 11.7176 21.4014L18.2553 17.6232C19.0611 17.1825 19.5786 16.2983 19.7366 15.9113Z" fill="white" />
    <path d="M9.9785 0.867188C10.3121 0.878549 10.6383 0.968175 10.9309 1.12883L18.315 5.3378C18.4628 5.4157 18.5912 5.52576 18.6909 5.65988C18.7905 5.794 18.8588 5.94877 18.8908 6.11278C18.9089 6.20332 18.9007 6.24587 18.7885 6.20332C18.4164 6.06661 18.0787 6.14357 17.7392 6.34184C15.4764 7.65942 13.2103 8.97127 10.9409 10.2774C10.3017 10.6468 9.65348 10.6622 9.00796 10.2855C6.69872 8.93959 4.39038 7.59213 2.08294 6.24315C1.58047 5.94892 1.58409 5.39031 2.08747 5.10151C4.3943 3.77728 6.70143 2.45366 9.00887 1.13064C9.30621 0.966174 9.63881 0.875803 9.9785 0.867188Z" />
    <path d="M9.71973 11.0576C9.87561 11.0934 10.0334 11.1203 10.1923 11.1382C10.45 11.1461 10.7043 11.0774 10.9229 10.9408C13.2642 9.5822 15.6066 8.22417 17.9503 6.86675C18.0227 6.82511 18.0933 6.78165 18.1685 6.74725C18.2421 6.70739 18.3247 6.68684 18.4085 6.68752C18.4923 6.68819 18.5745 6.71006 18.6475 6.7511C18.7206 6.79214 18.782 6.851 18.8262 6.9222C18.8703 6.9934 18.8957 7.07461 18.9 7.15828C18.9036 7.22069 18.9036 7.28327 18.9 7.34568C18.9 9.84897 18.8936 12.3532 18.9 14.8601C18.9 15.6939 18.5713 16.2923 17.838 16.7124C15.5973 17.9962 13.3701 19.3035 11.1384 20.6054C11.0577 20.6592 10.9691 20.7002 10.8759 20.7268C10.53 20.8055 10.2702 20.6172 10.2421 20.2614C10.2367 20.1926 10.2421 20.1229 10.2421 20.0541C10.2421 17.5291 10.2331 15.0049 10.2421 12.4808C10.2485 11.9403 10.1824 11.4523 9.71973 11.0576Z" />
    <path d="M10.2523 21.036C9.96076 21.1537 9.68915 21.103 9.42479 20.9455C8.5629 20.4439 7.7001 19.9441 6.8364 19.4462C5.26048 18.5366 3.68427 17.6258 2.10775 16.7139C1.44866 16.3345 1.10282 15.7542 1.09919 15.01C1.08561 12.1129 1.09286 9.21575 1.09195 6.31863C1.09124 6.21426 1.10807 6.11051 1.14175 6.01172C1.19245 6.01172 1.21055 6.04612 1.22413 6.08596C1.34092 6.41279 1.58627 6.61378 1.87598 6.78217C4.13212 8.09614 6.38734 9.41101 8.64166 10.7268C9.35417 11.1414 9.69821 11.7525 9.6973 12.5737C9.6973 15.1811 9.6973 17.7885 9.6973 20.3959C9.68587 20.5486 9.71955 20.7013 9.79417 20.835C9.901 21.0052 10.064 21.0423 10.2523 21.036Z" />
    <path d="M14.7333 3.88247C14.4943 4.02371 14.2807 4.15408 14.0652 4.27992C14.4644 4.65926 14.4617 4.84214 13.9909 5.12914C13.1345 5.65152 12.259 6.14494 11.3917 6.65012C10.6022 7.11004 9.80461 7.55547 9.026 8.03531C8.2474 8.51515 7.47333 8.52239 6.68024 8.10412C6.21703 7.86953 5.77 7.60428 5.34214 7.31012C4.7274 6.87194 4.72831 6.49531 5.37744 6.11235C6.83325 5.2414 8.30263 4.39218 9.76658 3.53572C10.1731 3.29761 10.5814 3.06222 11.0006 2.84947C11.0859 2.80466 11.1808 2.78125 11.2772 2.78125C11.3735 2.78125 11.4684 2.80466 11.5537 2.84947C12.1196 3.10206 12.6184 3.46873 13.1508 3.77836C13.2956 3.86165 13.417 3.88609 13.541 3.77111C13.8823 3.45243 14.2173 3.48593 14.555 3.77111C14.6118 3.81234 14.6714 3.84955 14.7333 3.88247Z" fill="#FEFEFE" />
    <path d="M16.8599 9.28516C16.8599 9.82837 16.868 10.329 16.8554 10.8243C16.8554 10.9763 16.9025 11.028 17.0437 11.0506C17.4466 11.114 17.8467 11.1973 18.2505 11.2579C18.3845 11.2778 18.4053 11.3349 18.4216 11.458C18.4606 11.7568 18.3673 11.9641 18.0812 12.0736C17.6077 12.2547 17.481 12.5897 17.5118 13.0885C17.5606 13.8698 17.5235 14.6557 17.5262 15.4424C17.5339 15.4894 17.5269 15.5375 17.5061 15.5803C17.4854 15.6231 17.4519 15.6584 17.4104 15.6815C16.4471 16.2428 15.4883 16.8113 14.5277 17.3781C14.5017 17.3881 14.4751 17.3965 14.448 17.4034V14.5878C13.9773 14.835 13.5427 15.0676 13.0991 15.2967C13.0085 15.3456 13.0384 15.428 13.0384 15.4977C13.0384 16.3442 13.033 17.1898 13.0384 18.0326C13.0448 18.0929 13.0314 18.1536 13.0004 18.2057C12.9694 18.2577 12.9224 18.2983 12.8664 18.3215C12.5043 18.517 12.1421 18.7261 11.7519 18.948V16.1233L10.9072 16.6094C10.9072 16.2862 10.9072 15.992 10.9072 15.6987C10.9072 15.6317 10.9606 15.5873 10.9978 15.5384C12.1765 13.9504 13.3523 12.3612 14.525 10.7708C14.5502 10.7234 14.591 10.6861 14.6405 10.6653C14.69 10.6445 14.7451 10.6415 14.7966 10.6568C15.0872 10.7183 15.3842 10.7536 15.6766 10.8061C15.7952 10.8279 15.835 10.7944 15.8305 10.6712C15.8215 10.4431 15.8305 10.214 15.8305 9.98499C15.8305 9.92343 15.8187 9.85824 15.8876 9.82022L16.8599 9.28516Z" fill="#FEFEFE" />
    <path d="M8.28499 14.6351C8.37552 14.3055 8.51676 14.2666 8.75396 14.5083C8.8746 14.6238 8.97176 14.7615 9.04005 14.9139C9.10433 15.0697 9.07445 15.1855 8.89429 15.2308L8.85445 15.2426C8.55206 15.3503 8.52309 15.4065 8.68334 15.6699C8.78303 15.8354 8.83241 16.0264 8.82548 16.2195C8.81643 16.9827 8.82548 17.7459 8.82548 18.5091C8.82548 18.5996 8.84811 18.7164 8.74219 18.7671C8.63626 18.8178 8.55478 18.7445 8.47601 18.6911C8.16795 18.4766 7.89545 18.2151 7.66844 17.9161C7.31451 17.4632 6.86864 17.0905 6.36021 16.8224C5.52186 16.3616 4.6998 15.8673 3.87774 15.3793C3.73158 15.2877 3.56122 15.2423 3.38885 15.2489C3.04211 15.2589 2.69626 15.2444 2.36762 15.1104C2.03898 14.9764 1.92853 14.8171 1.92762 14.4613C1.92762 13.6709 1.92762 12.8805 1.92762 12.0893C1.92762 11.9462 1.9584 11.8511 2.09873 11.8023C2.20737 11.7642 2.22367 11.7036 2.15577 11.6022C2.01815 11.3948 1.88416 11.183 1.76285 10.9684C1.70038 10.8589 1.60079 10.7231 1.7375 10.6126C1.8742 10.5022 2.02811 10.5348 2.16301 10.6289C2.36128 10.7674 2.40836 10.9911 2.46268 11.2047C2.46676 11.2745 2.48624 11.3425 2.51972 11.4039C2.56861 11.2464 2.60573 11.0943 2.66186 10.9512C2.79585 10.6045 2.95791 10.5158 3.32095 10.5755C3.67385 10.6365 4.0178 10.7411 4.3449 10.8869C5.48874 11.3887 6.53178 12.0942 7.42309 12.9693C7.91832 13.4509 8.11478 14.0557 8.20803 14.7139C8.21976 14.7319 8.226 14.753 8.226 14.7745C8.226 14.7961 8.21976 14.8171 8.20803 14.8352C8.23721 14.7699 8.2629 14.7031 8.28499 14.6351Z" fill="#FEFEFE" />
    <path d="M8.28538 14.6367C8.29512 14.7151 8.27366 14.7941 8.22563 14.8567C8.20364 14.8136 8.1991 14.7637 8.21295 14.7173L8.28538 14.6367Z" />
    <path d="M10.6136 6.23626C10.4743 6.23419 10.3384 6.19276 10.2216 6.11675C9.72635 5.8379 9.23294 5.55815 8.74224 5.27297C8.52948 5.14893 8.53038 5.06021 8.74224 4.93799C9.46652 4.52696 10.1908 4.12317 10.887 3.6705C10.9786 3.60452 11.0876 3.56708 11.2003 3.56289C11.3131 3.55871 11.4246 3.58795 11.5208 3.64696C12.0522 3.94391 12.5845 4.23996 13.1115 4.54597C13.298 4.65461 13.2925 4.74153 13.0915 4.85832C12.5909 5.15255 12.0848 5.43955 11.5832 5.73379C11.3804 5.8533 11.184 5.98367 10.9839 6.10679C10.8763 6.18618 10.7472 6.23129 10.6136 6.23626Z" />
    <path d="M5.73047 6.71388C6.3117 6.35174 6.14421 6.33906 6.72635 6.71388C6.34792 7.06878 6.18677 7.07059 5.73047 6.71388Z" />
    <path d="M7.3125 5.8016L7.50896 5.68572C7.85662 5.48202 7.9725 5.49197 8.2767 5.74366C8.33283 5.79074 8.34913 5.82243 8.2767 5.86317C8.15629 5.93107 8.03769 5.99988 7.91456 6.06959C7.89155 6.08508 7.86444 6.09335 7.8367 6.09335C7.80896 6.09335 7.78185 6.08508 7.75884 6.06959C7.62032 5.9863 7.47999 5.9021 7.3125 5.8016Z" />
    <path d="M7.54633 6.26495C7.08008 6.61441 7.00312 6.61351 6.55859 6.27219C6.90896 5.92273 7.09818 5.9191 7.54633 6.26495Z" />
    <path d="M9.11014 7.1777C8.61673 7.52716 8.55788 7.52625 8.11426 7.1777C8.47911 6.84272 8.67557 6.84091 9.11014 7.1777Z" />
    <path d="M9.9426 6.71194C9.76153 6.81696 9.61486 6.9075 9.46276 6.98354C9.31066 7.05959 9.23733 6.92017 9.13321 6.86766C9.05535 6.82964 8.93042 6.80247 8.92589 6.71828C8.92136 6.63408 9.05988 6.62231 9.12416 6.56527C9.44647 6.2819 9.65289 6.59153 9.9426 6.71194Z" />
    <path d="M8.30447 7.64962C8.14875 7.74015 8.0048 7.83069 7.8518 7.90583C7.69879 7.98098 7.62456 7.84336 7.52225 7.78904C7.25065 7.63966 7.25608 7.63513 7.51229 7.49028C7.8346 7.30649 7.89616 7.31736 8.30447 7.64962Z" />
    <path d="M7.35449 6.71183C7.92396 6.34244 7.77729 6.34969 8.32774 6.71183C7.95293 7.05948 7.77186 7.05948 7.35449 6.71183Z" />
    <path d="M7.52708 7.17549C7.02914 7.52676 6.92865 7.52495 6.54297 7.17005C6.95943 6.82783 7.06173 6.82783 7.52708 7.17549Z" />
    <path d="M8.12012 6.25721C8.27493 6.16668 8.40802 6.08429 8.54201 6.00552C8.57128 5.98662 8.60539 5.97656 8.64024 5.97656C8.67509 5.97656 8.7092 5.98662 8.73847 6.00552C8.8453 6.06799 8.95032 6.13408 9.05715 6.19655C9.13049 6.24091 9.14316 6.27894 9.05715 6.32511C8.96553 6.37223 8.87666 6.42452 8.79098 6.48174C8.74585 6.51917 8.68881 6.5392 8.63018 6.5382C8.57156 6.53721 8.51523 6.51525 8.47139 6.47631C8.36728 6.39935 8.2523 6.33688 8.12012 6.25721Z" />
    <path d="M15.161 15.8175C15.161 15.2987 15.161 14.7935 15.161 14.2884C15.158 14.2613 15.1631 14.2339 15.1757 14.2097C15.1882 14.1856 15.2077 14.1657 15.2316 14.1526C15.7621 13.8438 16.2927 13.5324 16.8612 13.1992C16.8612 13.5143 16.8612 13.7931 16.8612 14.072C16.8612 14.3209 16.9319 14.6152 16.8232 14.8053C16.7146 14.9954 16.4249 15.0833 16.2121 15.2127C15.8871 15.4101 15.5593 15.6047 15.2325 15.7994C15.2095 15.8082 15.1855 15.8143 15.161 15.8175Z" />
    <path d="M7.87593 14.5689C7.75824 14.4857 7.66227 14.4196 7.56812 14.3508C6.144 13.3105 4.64293 12.3988 3.0124 11.7135C2.85396 11.6474 2.84762 11.5686 2.89832 11.4346C3.01058 11.134 3.15816 11.0345 3.48318 11.058C3.80074 11.0906 4.11051 11.1766 4.39939 11.3124C5.42318 11.748 6.35661 12.371 7.15165 13.1494C7.54684 13.5269 7.8022 14.0274 7.87593 14.5689Z" />
    <path d="M3.89345 13.4159C3.89345 13.5789 3.84004 13.6495 3.69247 13.6242C3.27872 13.5535 2.66942 12.9207 2.61963 12.5079C2.60695 12.4047 2.58794 12.2906 2.68934 12.2236C2.79074 12.1566 2.88037 12.2236 2.96094 12.2707C3.34728 12.5071 3.65601 12.8514 3.84909 13.2611C3.86812 13.3114 3.88296 13.3632 3.89345 13.4159Z" />
    <path d="M8.13613 15.6518C8.13613 15.9062 8.01843 16.0067 7.76856 15.9542C7.45458 15.8965 7.17181 15.7278 6.97185 15.4789C6.88131 15.3603 6.82156 15.2182 6.8958 15.0742C6.97004 14.9303 7.11671 14.9221 7.25794 14.9267C7.4068 14.9289 7.55336 14.9638 7.68726 15.0289C7.82116 15.094 7.93916 15.1876 8.03292 15.3033C8.10986 15.4024 8.1467 15.5268 8.13613 15.6518Z" />
  </svg>

</template>

<script>
  export default {}
</script>
