import { GetterTree } from 'vuex'
import { State as RootState } from '@/store/state'

export interface Resources {
  resources: Array<Resource>
  resourcesGroup: Array<Resource>
  groupIdCreated: number
  positions: Array<ResourcePosition>
  resource: Resource
  totalResources: number
  resourcesForRoles: Array<Resource>
}

export interface ResourcePosition {
  id: number
  position: number
  available: boolean
}

export interface Resource {
  id?: number
  name?: string
  slug?: string
  icon?: string
  groupId?: number
  title?: string
  description?: string
  position?: number | null | undefined
  metadata?: null | string | Record<string, unknown>
  resourceGroup?: Resource
}

export interface ResourcesFormatted {
  id: number
  icon: string
  name: string
  description: string
  position: string
  resourceGroup: string
}

export function initState (): Resources {
  return {
    resources: [],
    resourcesGroup: [],
    groupIdCreated: -1,
    positions: [],
    resource: {},
    totalResources: 0,
    resourcesForRoles: [],
  }
}

export const getters: GetterTree<Resources, RootState> = {
  resources: ({ resources }): Array<Resource> => {
    return resources
  },
  resourcesGroup: ({ resourcesGroup }): Array<Resource> => {
    return resourcesGroup
  },
  groupIdCreated: ({ groupIdCreated }): number => {
    return groupIdCreated
  },
  positions: ({ positions }): Array<ResourcePosition> => {
    return positions
  },
  resourceFound: ({ resource }): Resource => {
    return resource
  },
  totalResources: ({ totalResources }): number => {
    return totalResources
  },
  resourcesForRoles: ({ resourcesForRoles }): Array<Resource> => {
    return resourcesForRoles
  },
}
