import gql from 'graphql-tag'
import { Fragment } from './fragment'
import { BasePersonAccountFragment } from '@/graphql/fragments/persons/personAccount'
import { BaseBankDataFragment } from '@/graphql/fragments/persons/bankData'
import { AccountTypeFragment } from '@/graphql/fragments/persons/accountType'
import { PersonAddressFragment } from '@/graphql/fragments/persons/personAddress'
import { PersonLaborDataFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${Fragment}
  ${BasePersonAccountFragment}
  ${BaseBankDataFragment}
  ${AccountTypeFragment}
  ${PersonAddressFragment}
  ${PersonLaborDataFragment}
  query fetchPerson($id: Int!) {
    records: persons_person_by_pk(id: $id) {
      id
      uid
      autos{
        id
      }
    }
  }
`

export default query
