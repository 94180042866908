import gql from 'graphql-tag'
import { PriceFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${PriceFragment}
  query findPrice($filter: sales_price_bool_exp, $order: [sales_price_order_by!], $limit: Int) {
    records: sales_price(where: $filter, order_by: $order, limit: $limit) {
      ...Price
    }
  }
`
export default query
