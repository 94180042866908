import { Type } from 'class-transformer'
import { Entity } from '..'

import { PersonAddress, PersonType } from '.'
import { Icon } from '@/entities/settings'

export class AddressType extends Entity {
  @Type(() => PersonAddress)
  personAddresses: PersonAddress[];

  @Type(() => PersonType)
  personType: PersonType;

  @Type(() => Icon)
  icon: Icon;

  name: string
  description: string
}
