import gql from 'graphql-tag'
import { EntityFields } from '..'
import { TrafficTicketFragment } from '@/graphql/fragments/public'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

export const BaseProcessTrafficTicketFragment = gql`
  fragment BaseProcessTrafficTicket on purchase_process_traffic_ticket{
    ${EntityFields}
  }
`

export const ProcessTrafficTicketFragment = gql`
  ${BaseProcessTrafficTicketFragment}
  ${TrafficTicketFragment}
  ${ProcessStatusFragment}
  fragment ProcessTrafficTicket on purchase_process_traffic_ticket{
    ...BaseProcessTrafficTicket
    trafficTicket:traffic_ticket {
      ...TrafficTicket
    }
    status: process_status{
      ...ProcessStatus
    }
  }
`
