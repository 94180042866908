import { Type } from 'class-transformer'
import { Entity } from '..'

import { InspectionAssessmentOption, InspectionParameter, InspectionQualification } from '.'

export class InspectionAssessment extends Entity {
  @Type(() => InspectionAssessmentOption)
  option: InspectionAssessmentOption;

  @Type(() => InspectionParameter)
  inspectionParameter: InspectionParameter;

  @Type(() => InspectionQualification)
  inspectionQualifications: InspectionQualification[];

  score: number;

  get name () {
    return this.option.name
  }
}
