import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ServiceFragment } from './service'

export const BaseProductFragment = gql`
  fragment BaseProduct on settings_product{
    ${EntityFields}
    name
    description
  }
`

export const ProductFragment = gql`
  ${BaseProductFragment}
  ${ServiceFragment}
  fragment Product on settings_product{
    ...BaseProduct
    serviceType:service_type {
      ...Service
    }
  }
`
