import { GetterTree } from 'vuex'
import { State as RootState } from '@/store/state'

export interface Roles {
  id: number
  name: string
  description: string
  slug: string
  user: Array<UserRoleId>
  permissions: Array<Permissions>
}

export interface Permissions {
  resourceId?: number
  id?: number
}

export interface UserRoleId {
  userId: number
}

export interface BasicInfo {
  id: string
  name: string
}

export interface UserFunctions {
  roles: Array<Roles>
  rolesCopy: Array<Roles>
  availability: Array<BasicInfo>
  area: Array<BasicInfo>
  status: Array<BasicInfo>
}

export function initState (): UserFunctions {
  return {
    roles: [],
    rolesCopy: [],
    availability: [],
    area: [],
    status: [],
  }
}

export const getters: GetterTree<UserFunctions, RootState> = {
  roles: ({ roles }): Array<Roles> => {
    return roles
  },
  rolesCopy: ({ rolesCopy }): Array<Roles> => {
    return rolesCopy
  },
  availability: ({ availability }): Array<BasicInfo> => {
    return availability
  },
  area: ({ area }): Array<BasicInfo> => {
    return area
  },
  status: ({ status }): Array<BasicInfo> => {
    return status
  },
}
