import gql from 'graphql-tag'
import { EntityFields } from '..'
import { InspectedComponentFragment } from '../purchase'

export const BaseExtraGuaranteeFragment = gql`
  fragment BaseExtraGuarantee on sales_extra_guarantee{
    ${EntityFields}
  }
`

export const ExtraGuaranteeFragment = gql`
  ${BaseExtraGuaranteeFragment}
  ${InspectedComponentFragment}
  fragment ExtraGuarantee on sales_extra_guarantee{
    ...BaseExtraGuarantee
    inspectedComponent:inspected_component {
      ...InspectedComponent
    }
  }
`
