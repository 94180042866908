import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'
import { ExpenseTypeFragment } from './expenseType'

export const BaseExpenseFragment = gql`
  fragment ExpenseBase on settings_expense {
    ${EntityFields}
    ${EnumFields}
  }
`

export const ExpenseFragment = gql`
  ${BaseExpenseFragment}
  ${ExpenseTypeFragment}
  fragment Expense on settings_expense {
    ...ExpenseBase
    type{
      ...ExpenseType
    }
  }
`
