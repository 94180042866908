import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import {
  Notification,
  initState,
  getters,
  mutations,
} from '@/store/notification/state'
import { actions } from '@/store/notification/actions'

const module: Module<Notification, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
