import gql from 'graphql-tag'
import { BaseComponentFragment, ComponentCategoryFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${ComponentCategoryFragment}
  ${BaseComponentFragment}
  query componentCategory($filter: vehicle_component_category_bool_exp) {
    records: vehicle_component_category(where: $filter) {
      ...ComponentCategory
      components {
        ...ComponentBase
        values{
          value
          component {
            id
          }
          id
          inspectionComponents:inspection_components{
            id
            order
            config
          }
        }
      }
    }
  }

`
export default query
