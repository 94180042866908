import gql from 'graphql-tag'
import { AutoFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${AutoFragment}
  query findAuto($filter: auto_bool_exp!, $limit: Int = 6,$order: [auto_order_by!]) {
    records: auto(where: $filter, limit: $limit, order_by: $order){
      ...Auto
    }
  }
`
export default query
