import { Actions } from '../../interfaces'

import { Document } from '@/entities/documents'
import find from './find'
import fetch from './fetch'
import create from './create'
import update from './update'
import fetchLite from './fetchLite'

export const actions: Actions = {
  find,
  fetch,
  create,
  update,
  fetchLite,
}

export default {
  Model: { Document },
  actions: { [Document.name]: actions },
}
