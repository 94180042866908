import gql from 'graphql-tag'
import { EntityFields } from '..'
import { NegotiationResponseTypeFragment } from './negotiationResponseType'
import { EmployeeFragment } from '../hr'

export const BaseNegotiationResponseFragment = gql`
  fragment BaseNegotiationResponse on purchase_negotiation_response {
    ${EntityFields}
    amount
  }
`

export const NegotiationResponseFragment = gql`
  ${BaseNegotiationResponseFragment}
  ${NegotiationResponseTypeFragment}
  ${EmployeeFragment}
  fragment NegotiationResponse on purchase_negotiation_response {
    ...BaseNegotiationResponse
    responseType: response_type {
      ...NegotiationResponseType
    }
    responsible {
      ...Employee
    }
  }
`
