import gql from 'graphql-tag'
import { RolesFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${RolesFragment}
  query findRoles($filter: roles_bool_exp!,$order: [roles_order_by!]) {
    records: roles(where: $filter,order_by: $order){
      ...Roles
    }
  }
`
export default query
