import gql from 'graphql-tag'
import { AgreementFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${AgreementFragment}
  query findAgreement($filter: persons_agreement_bool_exp, $limit: Int) {
    records: persons_agreement(where: $filter,  limit: $limit) {
      ...Agreement
    }
  }
`
export default query
