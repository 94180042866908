import gql from 'graphql-tag'

import { AppraisalFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${AppraisalFragment}
  mutation insertAppraisal($fields: purchase_appraisal_insert_input!) {
    record: insert_purchase_appraisal_one(object: $fields) {
      ...Appraisal
    }
  }
`

export default mutation
