import gql from 'graphql-tag'
import { EntityFields } from '..'

export const FinancialAccountFragment = gql`
  fragment FinancialAccount on finance_financial_account{
    ${EntityFields}
    description
    financialCategory:financial_category
    idPersonAccount:id_person_account
  }
`
