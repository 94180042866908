import gql from 'graphql-tag'
import { GenderFragment } from '@/graphql/fragments/persons/gender'

export const query = gql`
  ${GenderFragment}
  query findGender($filter: persons_gender_bool_exp, $limit: Int) {
    records: persons_gender(where: $filter,  limit: $limit) {
      ...Gender
    }
  }
`
export default query
