import { Actions } from '../../interfaces'

import find from './find'
import { AgreementType } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { AgreementType },
  actions: { [AgreementType.name]: actions },
}
