import gql from 'graphql-tag'
import { PersonAddressFragment } from '@/graphql/fragments/persons/personAddress'

export const mutation = gql`
  ${PersonAddressFragment}
  mutation updatePersonAddress($id: Int!, $fields: persons_person_address_set_input!) {
    record: update_persons_person_address_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...PersonAddress
    }
  }
`

export default mutation
