import gql from 'graphql-tag'

import { EntityFields } from '..'
import { BankDataFragment } from '@/graphql/fragments/persons/bankData'

export const BasePersonAccountFragment = gql`
  fragment BasePersonAccount on persons_person_account {
    ${EntityFields}
    active
  }
`

export const PersonAccountFragment = gql`
  ${BasePersonAccountFragment}
  ${BankDataFragment}
  fragment PersonAccount on persons_person_account {
    ...BasePersonAccount
    bankData:bank_data {
      ...BankData
    }
  }
`
