import gql from 'graphql-tag'
import { EntityFields } from '..'
import { InspectionAssessmentFragment } from '@/graphql/fragments/purchase/inspectionAssessment'

export const BaseInspectionParameterFragment = gql`
  fragment BaseInspectionParameter on purchase_inspection_parameter{
    ${EntityFields}
    name
    order
    multiple
  }
`

export const InspectionParameterFragment = gql`
  ${BaseInspectionParameterFragment}
  ${InspectionAssessmentFragment}
  fragment InspectionParameter on purchase_inspection_parameter{
    ...BaseInspectionParameter
    inspectionAssessments:inspection_assessments{
      ...InspectionAssessment
    }
  }
`
