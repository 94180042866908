import { Actions } from '../../interfaces'

import find from './find'
import { Currency } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { Currency },
  actions: { [Currency.name]: actions },
}
