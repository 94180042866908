import { Type } from 'class-transformer'
import { Entity } from '..'

import { Region } from '.'
import { Address } from '../persons'

export class City extends Entity {
  @Type(() => Address)
  addresses: Address[];

  @Type(() => Region)
  region: Region;

  name: string;

  toString () {
    const { name } = this
    return name
  }
}
