import gql from 'graphql-tag'
import { VehicleGamaFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${VehicleGamaFragment}
  query findVehicleGama {
    records: vehicle_gama {
      ...VehicleGama
    }
  }
`
export default query
