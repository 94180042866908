import { instanceToPlain, Type } from 'class-transformer'
import { Entity } from '..'

import { Deal } from '.'

export class Icon {
  main?: string

  get serialized () {
    return JSON.stringify(instanceToPlain(this))
  }

  toString () {
    const { main } = this
    return main
  }
}

export class Channel extends Entity {
  public static readonly instagram = 'instagram'
  public static readonly facebook = 'facebook'
  public static readonly floor = 'floor'
  public static readonly clientPortfolio = 'client_portfolio'
  public static readonly webSite = 'web_site'
  public static readonly chileautos = 'chileautos'
  public static readonly yapo = 'yapo'
  public static readonly fullmotor = 'fullmotor'
  public static readonly mercadoLibre = 'mercado_libre'
  public static readonly referred = 'referred'
  @Type(() => Deal)
  deals: Deal[];

  @Type(() => Icon)
  icon: Icon;

  name: string;
  description: string;

  color: string;

  toString () {
    return this.name
  }
}
