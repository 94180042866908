import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { actions } from '@/store/persons/bank/actions'
import {
  Bank,
  initState,
  getters,
  mutations,
} from '@/store/persons/bank/state'

const module: Module<Bank, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
