import { Type } from 'class-transformer'
import { Entity } from '..'

import { Icon } from '../settings'
import { EnablementTask } from '.'

export class EnablementTaskType extends Entity {
  @Type(() => EnablementTask)
  task: EnablementTask[];

  @Type(() => Icon)
  icon?: Icon

  name: string;
  description: string;
  color?: string;
  active: boolean

  toString () {
    return this.description
  }
}
