import gql from 'graphql-tag'
import { AutoFragment } from '@/graphql/fragments/public'
import { AcquisitionTypeFragment, SupportDocumentTypeFragment } from '@/graphql/fragments/settings'

export const StockViewDetailBase = gql`
  fragment StockViewDetailBase on sales_stock_view {
    expectedPublicationAmount:expected_publication_amount
    appraisalAmount:appraisal_amount
    purchaseAmount:purchase_amount
    enablementCost:enablement_cost
    enablementMetadata:enablement_metadata
    appraisalLink:appraisal_link
    idDeal:id_deal
    idAuto:id_auto
    idEnablement:id_enablement
    idNegotiation:id_negotiation
    idPurchase: id_purchase
    idInspection:id_inspection
    supervisorMetadata: supervisor_metadata_inspection
  }
`

export const StockViewDetailFragment = gql`
  ${StockViewDetailBase}
  ${AutoFragment}
  ${SupportDocumentTypeFragment}
  ${AcquisitionTypeFragment}
  fragment StockViewDetail on sales_stock_view {
    ...StockViewDetailBase
    auto{
      ...Auto
    }
    attributes {
      component {
        icon
        id
        slug
        name
        favorite
      }
      componentValue: component_value {
        id
        value
      }
      value
    }
    documentType: document_type{
        ...SupportDocumentType
    }
    acquisitionType: acquisition_type{
      ...AcquisitionType
    }
    stock{
      id
      prices{
        id
        amount
      }
    }
  }
`
