import gql from 'graphql-tag'

import { SaleOrderFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${SaleOrderFragment}
  mutation insertSaleOrder($fields: sales_sale_order_insert_input!) {
    record: insert_sales_sale_order_one(object: $fields) {
      ...SaleOrder
    }
  }
`

export default mutation
