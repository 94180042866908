import gql from 'graphql-tag'
import { EntityFields } from '..'
import { CurrencyFragment, InfractionTypeFragment } from '@/graphql/fragments/settings'

export const BaseTrafficTicketFragment = gql`
  fragment TrafficTicketBase on traffic_ticket {
    ${EntityFields}
    uid
    role
    amount
    tariff
    paymentDate:payment_date
    infractionDate: infraction_date
    judgmentDate:judgment_date
    reportDate:rmnp_report_date
    admissionDate:rmnp_admission_date
  }
`

export const TrafficTicketFragment = gql`
  ${BaseTrafficTicketFragment}
  ${CurrencyFragment}
  ${InfractionTypeFragment}
  fragment TrafficTicket on traffic_ticket {
    ...TrafficTicketBase
    court{
      id
      alias
    }
    amountCurrency: amount_currency{
      ...Currency
    }
    tariffCurrency:tariff_currency {
      ...Currency
    }
    type: infraction_type{
      ...InfractionType
    }
  }
`
