import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: ':role/supervisor_leads',
    name: 'supervisor_dashboard_leads',
    meta: { inherit: true },
    component: () => import('./Leads.vue'),
  },
  {
    path: ':role/supervisor_consignment',
    name: 'supervisor_dashboard_consignment',
    meta: { inherit: true },
    component: () => import('./Consignment.vue'),
  },
  {
    path: ':role/supervisor_inspections',
    name: 'supervisor_dashboard_inspections',
    meta: { inherit: true },
    component: () => import('./Inspections.vue'),
  },
  {
    path: ':role/supervisor_team',
    name: 'supervisor_dashboard_team',
    meta: { inherit: true },
    component: () => import('./Team.vue'),
  },
  {
    path: ':role/supervisor_all',
    name: 'supervisor_dashboard_all',
    meta: { inherit: true },
    component: () => import('./All.vue'),
  },
  {
    path: ':role/supervisor_stock',
    name: 'supervisor_dashboard_stock',
    meta: { inherit: true },
    component: () => import('./Stock.vue'),
  },
  {
    path: ':role/supervisor_treasury',
    name: 'supervisor_dashboard_treasury',
    meta: { inherit: true },
    component: () => import('./Treasury.vue'),
  },
  {
    path: ':role/supervisor_sales',
    name: 'supervisor_dashboard_sales',
    meta: { inherit: true },
    component: () => import('./Sales.vue'),
  },
  {
    path: ':role/supervisor_purchases',
    name: 'supervisor_dashboard_purchases',
    meta: { inherit: true },
    component: () => import('./Purchases.vue'),
  },
]

export default routes
