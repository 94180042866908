import { plainToInstance } from 'class-transformer'

import { Resource, ResourceType } from '../../'
import { Metadata } from '../interfaces/form'

const forms: Record<string, Metadata> = {}

export const examples = Object.entries(forms).map(([slug, metadata]) => {
  return plainToInstance(Resource, {
    name: metadata.form?.title || `local_${slug}`,
    resourceType: {
      name: ResourceType.form,
    },
    slug: `local_${slug}`,
    metadata,
  })
})
