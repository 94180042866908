import { plainToInstance, Type } from 'class-transformer'
import { Entity } from '..'

import { Attribute, Generation, MileagePrice, RotationIndex, Version, Year } from '.'
import { Auto } from '../public'
import { generateUrl } from '@/utils/appraisal/general'
import { removeAccents } from '@/utils/general'

interface CustomAttribute {
  component: string
  value: string
  componentId: number
  componentValueId: number
}

interface CustomGeneration {
  id: number
  sku: string
  rotationIndex: { id: number }
  attributes: CustomAttribute[]
}

export class CustomAttributes extends Entity {
  attributes: CustomAttribute[];
  generations: CustomGeneration[];
  rotationIndex: { id: number }

  private findCommonAttributes (attributes: CustomAttribute[]) {
    if (!attributes?.length) return null

    return {
      transmission: attributes?.find(attr => attr.component === 'Transmisión'),
      fuel: attributes?.find(attr => attr.component === 'Combustible'),
      traction: attributes?.find(attr => attr.component === 'Tracción'),
    }
  }

  get directAttributes () {
    const { attributes } = this
    return this.findCommonAttributes(attributes)
  }

  get generationAttributes () {
    const { generations } = this
    if (generations?.length === 1) {
      const attributes = this.findCommonAttributes(generations?.[0]?.attributes)
      return {
        ...attributes,
        sku: generations?.[0]?.sku,
        id: generations?.[0]?.id,
        rotationIndex: generations?.[0]?.rotationIndex,
      }
    }
    return null
  }

  get multipleGenerationAttributes () {
    const { generations } = this

    if (!generations?.length) return null
    return generations.map(generation => ({
      ...this.findCommonAttributes(generation.attributes),
      sku: generation.sku,
      rotationIndex: generation?.rotationIndex,
      id: generation.id,
    }))
  }

  get hasMultipleSKUs () {
    const { generations } = this
    const skuSet = new Set(generations?.map(generation => generation.sku))
    return skuSet.size > 1
  }

  get attrs (): Array<Record<string, any>> {
    const { hasMultipleSKUs, multipleGenerationAttributes, generationAttributes, directAttributes } = this
    if (hasMultipleSKUs) {
      return multipleGenerationAttributes
    }

    const singleGenAttributes = generationAttributes
    if (singleGenAttributes) {
      return [singleGenAttributes]
    }

    const directAttrs = directAttributes
    return directAttrs ? [directAttrs] : []
  }
}

export class VersionYear extends Entity {
  @Type(() => Version)
  version: Version;

  @Type(() => Year)
  year: Year;

  @Type(() => Auto)
  autos: Auto[];

  @Type(() => Generation)
  generations: Generation[];

  @Type(() => Attribute)
  attributes: Attribute[];

  @Type(() => MileagePrice)
  mileagePrices: MileagePrice[];

  @Type(() => RotationIndex)
  rotationIndex: RotationIndex

  @Type(() => CustomAttributes)
  customAttributes: CustomAttributes

  idGenio: number

  get title () {
    const { version, year } = this
    return `${version.title} ${year}`
  }

  get linkChileAutos () {
    const auto = plainToInstance(Auto, {})
    auto.version = this

    return `www.chileautos.cl/vehiculos/${generateUrl(auto)}`
  }

  buildLinkChileAutos (attributes, aliasBrand, aliasModel) {
    const { linkChileAutos, version } = this

    const { model, model: { brand } } = version

    const fuel = attributes?.find(a => a.component === 'Combustible')
    const transmission = attributes?.find(a => a.component === 'Transmisión')
    let url = linkChileAutos
    if (fuel) {
      const others = ['eléctrico', 'híbrido']
      url = others.includes(fuel.value.toLowerCase())
        ? linkChileAutos.replace('$', fuel.value.toLowerCase()) : linkChileAutos.replace('$', removeAccents(fuel.value.toLowerCase()))
    } else {
      url = url.replace('$-combustible/', '')
    }
    if (transmission) {
      let transmissionValue
      if (transmission.value === 'Mecánica') {
        transmissionValue = 'manual'
      } else {
        transmissionValue = 'automática'
      }

      url = url.replace('#', transmissionValue)
    } else {
      url = url.replace('#-transmisi%C3%B3n/', '')
    }

    const br = aliasBrand?.length ? aliasBrand : brand.name.toLowerCase()
    const md = aliasModel?.length ? aliasModel : model.name.toLowerCase()

    url = url.replace('brand', br).replace('model', md).replaceAll(' ', '-')

    return url
  }

  buildLinkChileAutosAttribute (attributes, aliasBrand, aliasModel) {
    const { linkChileAutos, version } = this

    const { model, model: { brand } } = version

    const fuel = attributes?.find(a => a.component.name === 'Combustible')
    const transmission = attributes?.find(a => a.component.name === 'Transmisión')
    let url = linkChileAutos
    if (fuel) {
      const others = ['eléctrico', 'híbrido']
      url = others.includes(fuel?.val?.toLowerCase())
        ? linkChileAutos.replace('$', fuel.val?.toLowerCase()) : linkChileAutos.replace('$', removeAccents(fuel.val.toLowerCase()))
    } else {
      url = url.replace('$-combustible/', '')
    }
    if (transmission) {
      let transmissionValue
      if (transmission.componentValue.value === 'Mecánica') {
        transmissionValue = 'manual'
      } else {
        transmissionValue = 'automática'
      }

      url = url.replace('#', transmissionValue)
    } else {
      url = url.replace('#-transmisi%C3%B3n/', '')
    }

    const br = aliasBrand?.length ? aliasBrand : brand.name.toLowerCase()
    const md = aliasModel?.length ? aliasModel : model.name.toLowerCase()

    url = url.replace('brand', br).replace('model', md).replaceAll(' ', '-')

    return url
  }

  get attrs () {
    const { customAttributes } = this
    return customAttributes?.attrs || customAttributes?.[0]?.attrs?.[0]
  }

  get hasMultipleSKUs () {
    const { customAttributes } = this
    return customAttributes?.hasMultipleSKUs
  }

  get hasDirectAttributes () {
    const { customAttributes } = this
    return customAttributes?.directAttributes
  }

  get characteristic () {
    const { attributes } = this
    const basic = attributes?.filter(att => att.component.id <= 4)
    return basic?.map(att => {
      const component = att.component.values
      const componentName = att.componentValue.value

      return component || componentName
    }).join('/')
  }
}
