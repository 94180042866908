import gql from 'graphql-tag'
import { PaymentRecipientFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentRecipientFragment}
  mutation insertPaymentRecipient($fields: finance_payment_recipient_insert_input!) {
    record: insert_finance_payment_recipient_one(object: $fields) {
      ...PaymentRecipient
    }
  }
`

export default mutation
