import { Actions } from '../../interfaces'
import { InspectionQualification } from '@/entities/purchase'

import create from './create'
import update from './update'
import remove from './remove'
import find from './find'

export const actions: Actions = {
  create,
  update,
  remove,
  find,
}

export default {
  Model: { InspectionQualification },
  actions: { [InspectionQualification.name]: actions },
}
