import gql from 'graphql-tag'

import { EntityFields } from '..'

export const BaseConcessionaireFragment = gql`
  fragment ConcessionaireBase on vehicle_concessionaire {
    ${EntityFields}
    default
  }
`

export const ConcessionaireFragment = gql`
  ${BaseConcessionaireFragment}
  fragment Concessionaire on vehicle_concessionaire {
    ...ConcessionaireBase
    details:concessionaire_details {
      value
      type
      idModel:id_model
    }
  }
`
