import gql from 'graphql-tag'

import { InspectedComponentFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
    ${InspectedComponentFragment}
    mutation updateInspectionInspectedComponent($id: Int!,$fields: purchase_inspection_inspected_component_set_input!) {
        record: update_purchase_inspection_inspected_component_by_pk(pk_columns: {id: $id}, _set: $fields) {
            ...InspectedComponent
        }
    }
`

export default mutation
