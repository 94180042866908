import gql from 'graphql-tag'

export const activityType = gql`
  query activityType {
    activity: persons_activity_type {
      description
      name
    }
  }
`

export const rentType = gql`
  query rentType {
    rent: persons_rent_type {
      description
      name
    }
  }
`

export const contractType = gql`
  query contractType {
    contract: persons_contract_type {
      description
      name
    }
  }
`

export const findLabor = gql`
  query findLabor($id: Int!) {
    info: laborDataWithDocs(idLaborData: $id) {
      id
      active
      rent
      jobTitle
      admissionDate
      jobKey
      activityType {
        description
        name
      }
      rentType {
        description
        name
      }
      documents {
        documentType
        files {
          idFile
          idDocument
          idLaborData
          fileName
          fileName
          fileDownloadUrl
        }
      }
      contractType {
        description
        name
      }
      employer {
        id
        uid
        surname
        secondSurname
        secondName
        phone:mainPhone
        alias
        firstName
        birthDate
        idCountry
        companyName
        personType {
          description
          name
        }
      }
      executiveComment
    }
  }
`

export const isLaborDataRequired = gql`
  subscription isLaborDataRequired($id: Int!) {
    isLaborData:persons_labor_data(where: {id_person: {_eq: $id}}) {
      active
      id
    }
  }
`
