import gql from 'graphql-tag'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const query = gql`
  ${DealAutoAttributeFragment}
  query findDealAutoAttribute($filter: deal_auto_attribute_bool_exp!) {
    records: deal_auto_attribute(where: $filter){
      ...DealAutoAttribute
    }
  }
`
export default query
