import gql from 'graphql-tag'
import { PersonTypeFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${PersonTypeFragment}
  query findPersonTypes {
    records: persons_person_type(where: {name: {_neq: "system"}}) {
      ...PersonType
    }
  }
`
export default query
