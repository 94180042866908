import gql from 'graphql-tag'
import { LoggedActionsFragment } from '@/graphql/fragments/audit'

export const query = gql`
  ${LoggedActionsFragment}
  query fetchLoggedActions($filter: audit_logged_actions_bool_exp!, $offset: Int!, $limit: Int!) {
    records: audit_logged_actions(where: $filter,offset: $offset, limit: $limit, order_by: {action_tstamp_tx: desc_nulls_last}) {
      ...LoggedActions
    }
  }
`

export default query
