import { Actions } from '../../interfaces'
import { Rol } from '@/entities/public'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Rol },
  actions: { [Rol.name]: actions },
}
