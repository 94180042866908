const vehicles = {
  vehicles: 'Vehiculos',
  inside: 'Interior',
  general: 'General',
  equipment: 'Equipamiento',
  exterior: 'Exterior',
  watch_publication: 'Ver Publicación',
  price: 'Precio',
  publication_status: 'Estatus de la publicación',
  transmissions: 'Transmision',
  tractions: 'Traccion',
  fuels: 'Combustible',
  color: 'Color',
  'airbag type': 'Tipo de Bolsa de Aire',
  seats: 'Asientos',
  'comfort equipment': 'Equipo de Comfort',
  bodyWorks: 'Carroceria',
  ppu: 'Patente',
  mileage: 'Kilometraje',
  places: 'Nº de Plazas',
  doors: 'Nº de Puertas',
  keyType: 'Tipo de Llave',
  airbags: 'Tipo de Airbag',
  nVelocity: 'Nº de Velocidades',
  rim: 'Nº Rin',
  cylinder: 'Cilindrada',
  securityEquipment: 'Equipo de Seguridad',
  comfortEquipment: 'Equipo de Comfort',
  publish: 'Publicar',
  unpublish: 'Despublicar',
  DRAFT: 'Borrador',
  PUBLIC: 'Publicado',
}

export default vehicles
