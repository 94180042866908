import { Actions } from '../../interfaces'
import { PersonAddress } from '@/entities/persons'

import find from './find'
import update from './update'

export const actions: Actions = {
  find,
  update,
}

export default {
  Model: { PersonAddress },
  actions: { [PersonAddress.name]: actions },
}
