import gql from 'graphql-tag'
import { AliasFragment } from '@/graphql/fragments/public'

export const mutation = gql`
  ${AliasFragment}
  mutation insertAlias($fields: alias_insert_input!) {
    record: insert_alias_one(object: $fields) {
      ...Alias
    }
  }
`

export default mutation
