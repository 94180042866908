import Vue from 'vue'
import App from './App.vue'
import 'reflect-metadata'
// @ts-ignore
import ZoomOnHover from 'vue-zoom-on-hover'
import './registerServiceWorker'
import { initAuthTriggers } from './plugins/firebase'
import vuetify from './plugins/vuetify'
import { createProvider, initApolloClient } from './plugins/apollo'
import VueApexCharts from 'vue-apexcharts'
import router, { initRoutesMiddlewares } from './router'
import store from './store'
import i18n from '@/plugins/i18n'

Vue.config.productionTip = false
const apolloProvider = createProvider()

initApolloClient(store, apolloProvider)
initAuthTriggers(store)
initRoutesMiddlewares(store)

Vue.use(VueApexCharts)
Vue.use(ZoomOnHover)
Vue.component('Apexchart', VueApexCharts)
new Vue({
  i18n,
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
