import { Type } from 'class-transformer'
import { Entity } from '..'

import { QuotaCharacteristic } from './QuotaCharacteristic'

export class QuotaType extends Entity {
  @Type(() => QuotaCharacteristic)
  characteristics: QuotaCharacteristic[];

  name: string;

  description: string;
}
