const users = {
  name: 'Nombre',
  user_profile: 'Perfil De Usuario',
  user_roles: 'Roles de Usuario',
  user_name: 'Nombre de Usuario',
  user_first_name: 'Primer Nombre',
  user_last_name: 'Segundo Nombre',
  user_address: 'Dirección',
  user_postal_code: 'Codigo Postal',
  user_about_me: 'Sobre Mi',
  user_country: 'Pais',
  user_city: 'Ciudad',
  user_phone_number: 'Telefono',
  role: 'Rol',
  area: 'Area',
  availability: 'Disponibilidad',
  status: 'Estado',
  updating_person: 'Actualizando Perfil de ',
  select_role: 'Selecciona rol',
  select_area: 'Selecciona area',
  select_availability: 'Selecciona disponibilidad',
  select_status: 'Selecciona estatus',
  admin: 'Administrador',
  roles: 'Roles',
  description: 'Descripción',
  slug: 'Identificador',
  group_name: 'Nombre del Grupo',
}

export default users
