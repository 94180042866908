import { Module } from 'vuex'
import { getters, initState, Teams } from '@/store/settings/team/state'
import { State as RootState } from '@/store/state'
import { mutations } from '@/store/settings/team/mutations'
import { actions } from '@/store/settings/team/actions'

const module: Module<Teams, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
