import dbErrors from './db-errors'
import auth from './auth'
import common from './common'
import errors from './errors'
import resources from './resources'
import users from './users'
import vehicles from './vehicles'
import vuetify from './vuetify'

export default {
  ...dbErrors,
  auth,
  common,
  errors,
  resources,
  users,
  vehicles,
  vuetify,
}
