import gql from 'graphql-tag'

export const mutation = gql`
  mutation insertEmployee($fields: hr_employee_insert_input!) {
    record: insert_hr_employee_one(object: $fields) {
      id
    }
  }
`

export default mutation
