import gql from 'graphql-tag'
import { BasePriceFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${BasePriceFragment}
  mutation insertPrice($fields: sales_price_insert_input!) {
    record: insert_sales_price_one(object: $fields){
      ...BasePrice
    }
  }
`

export default mutation
