import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Details } from '@/utils/generalInterface'

export interface Address {
  addressType: Details[]
  region: Details[]
  commune: Details[]
  streetType: Details[]
  propertyType: Details[]
  addDirection: boolean
  directionCreated: direction
}

export interface direction {
  abbreviation: string
  idPersonAddress: number
}

export function initState (): Address {
  return {
    addressType: [],
    region: [],
    commune: [],
    streetType: [],
    propertyType: [],
    addDirection: false,
    directionCreated: {} as direction,
  }
}

export const mutations: MutationTree<Address> = {
  setDirectionCreated (state, payload) {
    state.directionCreated = payload
  },
  setAddressType (state, addressType: Details[]) {
    state.addressType = addressType
  },
  setRegion (state, region: Details[]) {
    state.region = region
  },
  setCommunes (state, commune: Details[]) {
    state.commune = commune
  },
  setStreetType (state, streetType: Details[]) {
    state.streetType = streetType
  },
  setPropertyType (state, propertyType: Details[]) {
    state.propertyType = propertyType
  },
  setAddDirectionState: (state, value) => {
    state.addDirection = value
  },
}

export const getters: GetterTree<Address, RootState> = {
  addressType: ({ addressType }): Array<Details> => {
    return addressType
  },
  region: ({ region }): Array<Details> => {
    return region
  },
  commune: ({ commune }): Array<Details> => {
    return commune
  },
  streetType: ({ streetType }): Array<Details> => {
    return streetType
  },
  propertyType: ({ propertyType }): Array<Details> => {
    return propertyType
  },
  addDirection: state => state.addDirection,
  directionCreated: state => state.directionCreated,
}
