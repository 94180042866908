import { MutationTree } from 'vuex'
import { UserFunctions } from '@/store/settings/roles/state'
import { deepCopy } from '@/utils/general'
import { Rol } from '@/store/settings/roles/rol'

export const mutations: MutationTree<UserFunctions> = {
  setRoles: (state, roles) => {
    state.roles = roles

    const copy = deepCopy(roles)

    state.rolesCopy = copy.map((cp: Rol) => new Rol(cp.rol))
  },
  setAvailability: (state, availability) => {
    state.availability = availability
  },
  setArea: (state, area) => {
    state.area = area
  },
  setStatus: (state, status) => {
    state.status = status
  },
}
