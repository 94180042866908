import gql from 'graphql-tag'

export const createBankData = gql`
  mutation createBankData(
    $account: String!
    $idBank: Int!
    $idHolder: Int!
    $accountType: String!
    $bipersonal: Boolean = false
  ) {
    createBankData(
      createBankDataInput: {
        accountNumber: $account
        idBank: $idBank
        idHolder: $idHolder
        nameAccountType: $accountType
        bipersonal: $bipersonal
      }
    ) {
      bankData {
        id
        bank {
          companyName
          alias
        }
        accountNumber
        accountType {
          name
          description
        }
        personAccounts {
          id
          person {
            id
            firstName
            secondName
            surname
            secondSurname
          }
        }
      }
      personAccount {
        id
      }
      exists
    }
  }
`

export const isAccountRequired = gql`
  subscription isAccountRequired($id: Int!) {
    isAccount: persons_person_account(where: { id_person: { _eq: $id } }) {
      active
      id
    }
  }
`

export const personUpdateAccountState = gql`
  mutation personUpdateAccountState($id: Int!) {
    update_persons_person_account(
      where: { id: { _eq: $id } }
      _set: { active: false }
    ) {
      affected_rows
    }
  }
`

export const addBankData = gql`
  mutation addBankData($bankData: persons_bank_data_insert_input!) {
    bankData: insert_persons_bank_data_one(object: $bankData) {
      id
    }
  }
`

export const associatePersonAccount = gql`
  mutation asociatePersonAccount(
    $personAccount: persons_person_account_insert_input!
  ) {
    personAccount: insert_persons_person_account_one(object: $personAccount) {
      id
    }
  }
`
