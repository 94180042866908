import gql from 'graphql-tag'

import { NegotiationFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${NegotiationFragment}
  query findNegotiation($filter: purchase_negotiation_bool_exp){
    records: purchase_negotiation(where: $filter) {
      ...Negotiation
    }
  }
`

export default query
