import gql from 'graphql-tag'

import { MaintenanceFragment } from '@/graphql/fragments/public/maintenance'
import { AutoFragment } from '@/graphql/fragments/public'
import { MaintenanceTypeFragment } from '@/graphql/fragments/public/maintenanceType'

export const mutation = gql`
  ${MaintenanceFragment}
  ${AutoFragment}
  ${MaintenanceTypeFragment}
  mutation insertMaintenance($fields: maintenance_insert_input!) {
    record: insert_maintenance_one(object: $fields) {
      ...Maintenance
      auto{
        ...Auto
      }
      type{
        ...MaintenanceType
      }
    }
  }
`

export default mutation
