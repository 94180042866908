import gql from 'graphql-tag'
import { CountryFragment } from '@/graphql/fragments/public/country'

export const query = gql`
  ${CountryFragment}
  query findCountry($filter: country_bool_exp!, $limit: Int=6) {
    records: country(
      where: {
        _and:[
          {
            nationality: {
              _is_null: false
            }
          },
          $filter
        ]
      },
      limit: $limit,
      order_by: {
        national_frequency: desc
      }
    ) {
      ...Country
    }
  }
`
export default query
