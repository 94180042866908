import gql from 'graphql-tag'

// TODO: request to the Backend to change Float for Int in idPerson
export const getExecutiveByPerson = gql`
  query getExecutiveByPerson($idPerson: Float!){
    response:getExecutiveByPerson(idPerson: $idPerson){
      id
    }
  }
`

// Mutación para crear un usuario nuevo
export const createUserByEmail = gql`
  mutation createByEmail($input: createUserByEmailInput!) {
    create_user_by_email(input: $input)
  }
`

// Mutación para modificar el rol por defecto
export const modifyDefaultRole = gql`
  mutation modifyDefaultRole($email: String!, $key: String!, $value: String!) {
    add_hasura_user_claim_to_user(email: $email, key: $key, value: $value)
  }
`

// Mutación para modificar los roles permitidos
export const modifyAllowedRoles = gql`
  mutation modifyAllowedRoles($email: String!, $key: String!, $value: String!) {
    add_hasura_user_claim_to_user(email: $email, key: $key, value: $value)
  }
`
