import gql from 'graphql-tag'

export const getClosingReasonAggregate = gql`
  query getClosingReasonAggregate($model: settings_closing_reason_bool_exp, $aggregate: crm_lead_bool_exp, $order: [settings_closing_reason_order_by!]) {
    aggregates: settings_closing_reason(where: $model, order_by: $order) {
      type{
        name
        description
      }
      leads: leads_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`
