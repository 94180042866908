import gql from 'graphql-tag'

export const query = gql`
  query findLitePerson($filter: persons_person_bool_exp!, $limit: Int = 6, $order: [persons_person_order_by!]) {
    records: persons_person(where: $filter, limit: $limit, order_by: $order){
      id
    }
  }
`
export default query
