const auth = {
  email: 'Correo electrónico',
  sign_google: 'Ingresar con google',
  sign_up: 'Registrarse',
  sign_in: 'Ingresar',
  sign_up_button: 'Regístrate aquí',
  sign_in_button: 'Ingresa aquí',
  sign_in_text: '¿Ya tienes una cuenta?',
  sign_up_text: '¿No tienes una cuenta?',
  forgot_user: '¿Olvidaste tu contraseña?',
  confirm_password: 'Confirmar contraseña',
  start_session: 'Iniciar sesión',
  recover_password: 'Recuperar contraseña',
  remember_session: '¿Recordar la sesión?',
  write_mail_forgot_password: 'Escriba el correo electrónico con el que se registró en GENIO',
  password: 'Contraseña',
  email_required: 'E-mail es requerido',
  email_valid: 'Debe ser un E-mail valido',
  password_invalid: 'La contraseña es invalida o el usuario no tiene contraseña',
  network_error: 'Error de Conexion Verifica tu conexion a internet',
  user_many_request: 'Demasiados intentos de inicio de sesión fallidos. Inténtalo de nuevo más tarde.',
}

export default auth
