import gql from 'graphql-tag'
import { ComponentFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${ComponentFragment}
  query component($filter: vehicle_component_bool_exp) {
    records: vehicle_component(where: $filter){
      ...Component
    }
  }
`
export default query
