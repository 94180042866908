import gql from 'graphql-tag'
import { EntityFields } from '..'

export const FileValidationFragment = gql`
    fragment FileValidation on files_file_validation{
        ${EntityFields}
        name
        value
    }
`
