import gql from 'graphql-tag'
import { BaseComponentFragment, ComponentCategoryFragment } from '@/graphql/fragments/vehicle'
import { ComponentTypeFragment } from '@/graphql/fragments/settings'
import { InspectionComponentFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${ComponentCategoryFragment}
  ${BaseComponentFragment}
  ${ComponentTypeFragment}
  ${InspectionComponentFragment}
  query componentCategory($filter: vehicle_component_category_bool_exp) {
    records: vehicle_component_category(where: $filter) {
      ...ComponentCategory
      components {
        ...ComponentBase
        type {
          ...ComponentType
        }
        values{
          value
          component {
            id
          }
          id
          inspectionComponents:inspection_components{
            ...InspectionComponent
          }
        }
        inspectionComponents:inspection_components{
          ...InspectionComponent
        }
      }
    }
  }

`
export default query
