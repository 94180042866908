import gql from 'graphql-tag'
import { BaseAutoMotiveRegistrationFragment } from '@/graphql/fragments/vehicle/automotiveRegistration'

export const query = gql`
  ${BaseAutoMotiveRegistrationFragment}
  query automotiveRegistration($filter: vehicle_automotive_registration_bool_exp) {
    records: vehicle_automotive_registration(where: $filter) {
      ...BaseAutoMotiveRegistration
    }
  }

`
export default query
