import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ExpenseFragment } from './expense'

export const BaseProcessExpenseFragment = gql`
  fragment ProcessExpenseBase on settings_process_expense {
    ${EntityFields}
  }
`

export const ProcessExpenseFragment = gql`
  ${BaseProcessExpenseFragment}
  ${ExpenseFragment}
  fragment ProcessExpense on settings_process_expense {
    ...ProcessExpenseBase
    expense {
      ...Expense
    }
  }
`
