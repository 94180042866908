import gql from 'graphql-tag'
import { EntityFields } from '..'
import { AppraisalResponseTypeFragment } from '../purchase/appraisalResponseType'
import { EmployeeFragment } from '../hr'

export const BaseResponseFragment = gql`
  fragment ResponseBase on purchase_appraisal_response {
    ${EntityFields}
    amount
    comment
  }
`

export const ResponseFragment = gql`
  ${BaseResponseFragment}
  ${EmployeeFragment}
  ${AppraisalResponseTypeFragment}
  fragment Response on purchase_appraisal_response {
   ...ResponseBase
    responsible {
      ...Employee
    }
    type: response_type {
      ...AppraisalResponseType
    }
  }
`
