import gql from 'graphql-tag'
import { MaintenanceFragment } from '@/graphql/fragments/public/maintenance'

export const query = gql`
  ${MaintenanceFragment}
  query findMaintenance($filter: maintenance_bool_exp!) {
    records: maintenance(where:$filter) {
      ...Maintenance
    }
  }
`
export default query
