import { Type } from 'class-transformer'
import { Entity } from '..'

import { Bonus } from './Bonus'

export class BonusType extends Entity {
  @Type(() => Bonus)
  bonuses: Bonus[];

  name: string;
  description: string;
}
