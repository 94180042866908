import gql from 'graphql-tag'

import { GenerationFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${GenerationFragment}
  query fetchGeneration($id: Int!) {
    records: vehicle_generation_by_pk(id: $id) {
      ...Generation
    }
  }
`

export default query
