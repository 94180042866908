import gql from 'graphql-tag'
import { FileFragment } from '@/graphql/fragments/files'

export const mutation = gql`
  ${FileFragment}
  mutation insertFile($fields: files_file_insert_input!) {
    record: insert_files_file_one(object: $fields) {
      ...File
    }
  }
`

export default mutation
