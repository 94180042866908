import { Type } from 'class-transformer'
import { Entity } from '..'
import { Platform } from '.'

export class Posts extends Entity {
  @Type(() => Platform)
  platform: Platform;

  status: string;
  url
}
