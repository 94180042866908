import gql from 'graphql-tag'

import { ComponentFragment, ComponentValueFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${ComponentFragment}
  ${ComponentValueFragment}
  query attributes($filter: attribute_view_bool_exp, $order: [attribute_view_order_by!], $distinct: [attribute_view_select_column!]) {
    records: attribute_view(where: $filter, order_by: $order, distinct_on: $distinct){
      value
      idAttribute:id_attribute
      component {
        ...Component
      }
      componentValue: component_value {
        ...ComponentValue
      }
    }
  }
`
export default query
