import gql from 'graphql-tag'
import { LoggedActionsFragment } from '@/graphql/fragments/audit'

export const query = gql`
  ${LoggedActionsFragment}
  query aggregateLoggedActions($filter: audit_logged_actions_bool_exp!) {
    records: audit_logged_actions_aggregate(where: $filter) {
      aggregate{
        count
      }
    }
  }
`

export default query
