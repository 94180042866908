import gql from 'graphql-tag'
import { EntityFields } from '..'

export const BaseAutoMotiveRegistrationFragment = gql`
  fragment BaseAutoMotiveRegistration on vehicle_automotive_registration {
    ${EntityFields}
    year
    appraisal
    permission
  }
`

export const AutoMotiveRegistrationFragment = gql`
  ${BaseAutoMotiveRegistrationFragment}
  fragment AutoMotiveRegistration on vehicle_automotive_registration {
    ...BaseAutoMotiveRegistration
  }
`
