import { Metadata } from '.'
import {
  Metadata as IMetadata,
  Api,
  FieldName,
  Input,
  Form as IForm,
  Layout,
  FormDetails, PartialInput,
} from '../interfaces/form'
import { extendsObj } from '@/utils/data'
import { Aggregate } from '@/entities/public/Resource/interfaces'

export class Form extends Metadata implements Omit<IMetadata, '__type'> {
  extends?: string;

  api: Api;
  fields: Record<FieldName, Input>;
  form: IForm;
  layout: Layout;
  audit?: Record<FieldName, PartialInput>

  details?: FormDetails
  aggregates?: Array<Aggregate>

  get model () {
    const { api: { model } } = this
    return model
  }

  get auditFields () {
    const { fields, audit } = this

    return extendsObj(audit, fields) as Record<FieldName, Input>
  }
}
