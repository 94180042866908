import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Details } from '@/utils/generalInterface'

export interface personReferenceDetails {
  exists: boolean
  personReference: personReferenceData
}

export interface personReferenceData {
  active: boolean
  id: number
  person: personReferenceState
  reference: personReferenceState
  relation: relationName
}

interface relationName {
  description: string
}

interface personReferenceState {
  id: number
  firstName: string
  surname: string
  phone?: string
}

export interface Reference {
  relationShips: Array<Details>
  reference: personReferenceDetails
}

export function initState (): Reference {
  return {
    relationShips: [],
    reference: {} as personReferenceDetails,
  }
}

export const mutations: MutationTree<Reference> = {
  setRelationsShips (state, relationShips: Array<Details>): void {
    state.relationShips = relationShips
  },
  setPersonReference (state, personReferenceData): void {
    state.reference = personReferenceData
  },
}

export const getters: GetterTree<Reference, RootState> = {
  relationShips (state): Array<Details> {
    return state.relationShips
  },
  personReference ({ reference }): personReferenceDetails {
    return reference
  },
}
