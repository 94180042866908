import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment } from '@/graphql/fragments/persons'

export const BaseDocumentIntervenerFragment = gql`
  fragment BaseDocumentIntervener on documents_intervener{
    ${EntityFields}
  }
`

export const DocumentIntervenerFragment = gql`
  ${BaseDocumentIntervenerFragment}
  ${PersonFragment}
  fragment DocumentIntervener on documents_intervener{
    ...BaseDocumentIntervener
    person {
      ...Person
    }
    field{
      id
      name
    }
  }
`
