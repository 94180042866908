import { Type } from 'class-transformer'
import { Entity } from '..'
import { fixPrice } from '@/utils/general'

import { Appraisal } from '.'
import { Person } from '../persons'

export class AppraisalExternalOffer extends Entity {
  @Type(() => Appraisal)
  appraisal: Appraisal;

  @Type(() => Person)
  company: Person;

  amount: number;

  get name () {
    const { company: { alias } } = this
    return alias
  }

  get value () {
    const { amount } = this
    return amount
  }

  toString () {
    const { name, amount } = this
    return `${name} = ${fixPrice(amount)}`
  }
}
