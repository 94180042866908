import gql from 'graphql-tag'

import { ProjectionPaymentFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${ProjectionPaymentFragment}
  mutation insertProjectionPayment($fields: sales_projection_payment_insert_input!) {
    record: insert_sales_projection_payment_one(object: $fields) {
      ...ProjectionPayment
    }
  }
`

export default mutation
