import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const MaintenanceTypeFragment = gql`
  fragment MaintenanceType on maintenance_type{
    ${EntityFields}
    ${EnumFields}
    mileage
  }
`
