import { Type } from 'class-transformer'
import { Entity } from '..'

import { EvaluationCondition } from './EvaluationCondition'

export class ConditionType extends Entity {
  public static readonly increaseInitial = 'increase_initial'
  @Type(() => EvaluationCondition)
  evaluationConditions: EvaluationCondition[];

  name: string;
  description: string;
}
