import { Type } from 'class-transformer'
import { Entity } from '..'

import { Stock } from './Stock'

export class KeyIdentifier extends Entity {
  @Type(() => Stock)
  stocks: Stock[];

  keyId: number;
}
