import { ActionTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { Resources } from './state'

import { Query } from '@/utils/computed/Query'
import { UnregisteredQuery } from '@/errors'

export const actions: ActionTree<Resources, RootState> = {
  load: ({ commit, rootGetters }, { model, slug }) => {
    const getForm = rootGetters['resources/getForm']
    const resource = getForm(model, slug)

    commit('setActive', resource)
    return resource
  },
  fetch: ({ dispatch, getters: { resource: { queries } } }, query: Query) => {
    const { uid } = query
    const q = queries?.find(q => q.uid === uid)
    if (!q) throw new UnregisteredQuery({ uid })

    const { filter, params, distinct } = query
    return dispatch('resources/form/fetchData', { query: q, filter, params, distinct }, { root: true })
  },
  setContent: ({ commit }, content) => commit('setContent', content),
  setTool: ({ commit }, content) => commit('setTool', content),
}
