import { Type } from 'class-transformer'
import { Entity } from '..'

import { ExchangeRate } from '.'
import { TrafficTicket } from '../public'

export class Currency extends Entity {
  @Type(() => ExchangeRate)
  exchangeRates: ExchangeRate[];

  @Type(() => ExchangeRate)
  fromCurrencies: ExchangeRate[];

  @Type(() => TrafficTicket)
  trafficTickets: TrafficTicket[];

  @Type(() => TrafficTicket)
  tariffs: TrafficTicket[];

  name: string;

  description: string;

  default: boolean;
}
