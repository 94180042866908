import gql from 'graphql-tag'
import { PersonAddressFragment } from '@/graphql/fragments/persons/personAddress'

export const query = gql`
  ${PersonAddressFragment}
  query findPersonAddress($filter: persons_person_address_bool_exp) {
    records: persons_person_address(where: $filter) {
      ...PersonAddress
    }
  }
`
export default query
