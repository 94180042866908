import gql from 'graphql-tag'

import Fragment from './fragment'

export const query = gql`
  ${Fragment}
  query fetchDeal($id: Int!) {
    records: crm_deal_by_pk(id: $id) {
      ...DealForm
    }
  }
`

export default query
