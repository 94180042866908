import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ProcessStatusFragment } from '../settings/processStatus'
import { ActivityTypeFragment } from './activityType'
import { EmployeeFragment } from '../hr'
import { NoteFragment } from './dealNote'
import { LeadFragment } from '@/graphql/fragments/crm/lead'
import { ClosingReasonFragment } from '@/graphql/fragments/settings'

export const BaseActivityFragment = gql`
  fragment ActivityBase on crm_lead_activity {
    ${EntityFields}
    schedulingDate: scheduling_date
    duration
    activityResult: activity_result
  }
`

export const ActivityFragment = gql`
  ${BaseActivityFragment}
  ${ActivityTypeFragment}
  ${ProcessStatusFragment}
  ${EmployeeFragment}
  ${NoteFragment}
  ${LeadFragment}
  ${ClosingReasonFragment}
  fragment Activity on crm_lead_activity {
    ...ActivityBase
    type: activity_type {
      ...ActivityType
    }
    status: process_status {
      ...ProcessStatus
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    executor {
      ...Employee
    }
    notes: deal_notes {
      ...Note
    }
    lead {
      ...Lead
    }
  }
`
