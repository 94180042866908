import gql from 'graphql-tag'

import { NegotiationFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${NegotiationFragment}
  mutation updateNegotiation($id: Int!, $fields: purchase_negotiation_set_input!) {
    record: update_purchase_negotiation_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Negotiation
    }
  }
`

export default mutation
