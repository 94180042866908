import gql from 'graphql-tag'

import { EvaluationConditionFragment } from '@/graphql/fragments/loans'

export const query = gql`
  ${EvaluationConditionFragment}
  query findEvaluationCondition($filter: loans_evaluation_condition_bool_exp, $limit: Int) {
    records: loans_evaluation_condition(where: $filter, limit: $limit) {
      ...EvaluationCondition
    }
  }
`

export default query
