import { Actions } from '../../interfaces'

import { Offer } from '@/entities/loans'
import find from './find'
import fetch from './fetch'
import create from './create'
import update from './update'

export const actions: Actions = {
  find,
  fetch,
  create,
  update,
}

export default {
  Model: { Offer },
  actions: { [Offer.name]: actions },
}
