import gql from 'graphql-tag'
import { BaseDocumentIntervenerFragment } from '@/graphql/fragments/documents'

export const mutation = gql`
  ${BaseDocumentIntervenerFragment}
  mutation deleteIntervener($id: Int!) {
    record: delete_documents_intervener_by_pk(id: $id) {
      ...BaseDocumentIntervener
    }
  }
`

export default mutation
