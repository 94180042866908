import gql from 'graphql-tag'

import { EvaluationRequirementFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${EvaluationRequirementFragment}
  mutation updateEvaluationRequirement($id: Int!, $fields: loans_evaluation_requirement_set_input!) {
    record: update_loans_evaluation_requirement_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...EvaluationRequirement
    }
  }
`

export default mutation
