import { Actions } from '../../interfaces'

import { FileProcess } from '@/entities/files'
import fetch from './fetch'
import find from './find'
import create from './create'
import update from './update'

export const actions: Actions = {
  create,
  fetch,
  find,
  update,
}

export default {
  Model: { FileProcess },
  actions: { [FileProcess.name]: actions },
}
