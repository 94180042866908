import gql from 'graphql-tag'
import { EntityFields } from '..'

export const FileTypeFragment = gql`
  fragment FileType on files_file_type{
    ${EntityFields}
    name
    mimes
    icon
  }
`
