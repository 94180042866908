import gql from 'graphql-tag'
import { ActivityTypeFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${ActivityTypeFragment}
  query fetchActivityType($id: Int!) {
    records: crm_activity_type_by_pk(id: $id) {
      ...ActivityType
    }
  }
`

export default query
