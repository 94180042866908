import gql from 'graphql-tag'
import { AliasFragment } from '@/graphql/fragments/public'

export const mutation = gql`
  ${AliasFragment}
  mutation deleteAlias($id: Int!) {
    record: delete_alias_by_pk(id: $id) {
      ...Alias
    }
  }
`

export default mutation
