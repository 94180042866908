import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

import { Store } from 'vuex'
import { State } from '@/store/state'

const {
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_DATABASE_URL,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_STORAGE_BUCKET,
  VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_FIREBASE_MEASUREMENT_ID,
} = process.env

const firebaseConfig = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DATABASE_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: VUE_APP_FIREBASE_APP_ID,
  measurementId: VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

export function initAuthTriggers (store: Store<State>): void {
  firebase.auth().onAuthStateChanged(async user => {
    store.state.firebaseLoading = true
    const action = await store.dispatch('user/handleUserChange', user)

    switch (action) {
      case 'login':
        await store.dispatch('resources/loadResources')
        if (window.location.pathname === '/') window.location.href = '/home'
        break
      case 'logout':
        await store.dispatch('resources/cleanResources')
        if (window.location.pathname !== '/') window.location.href = '/'
        break
    }

    store.state.firebaseLoading = false
  })
}
