import { Type } from 'class-transformer'
import { Entity } from '..'

import { SaleOrderItem } from './SaleOrderItem'

export class ItemType extends Entity {
  public static readonly product = 'product'
  public static readonly transfer = 'transfer'
  public static readonly discount = 'discount'
  public static readonly postFinancingDiscount = 'post_financing_discount'
  @Type(() => SaleOrderItem)
  saleOrderItems: SaleOrderItem[];

  name: string;
  description: string;
  billable: boolean;
  negative = false;
}
