import gql from 'graphql-tag'
import { SupportDocumentTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${SupportDocumentTypeFragment}
  query findSupportDocumentType($filter: settings_support_document_type_bool_exp, $order: [settings_support_document_type_order_by!], $limit: Int){
    records: settings_support_document_type(where: $filter, order_by: $order, limit: $limit) {
      ...SupportDocumentType
    }
  }
`

export default query
