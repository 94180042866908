import gql from 'graphql-tag'

export const LoggedActionsFragment = gql`
  fragment LoggedActions on audit_logged_actions {
    actionTstampTx:action_tstamp_tx
    schemaName:schema_name
    tableName:table_name
    hasuraUser:hasura_user
    action
    rowData:row_data
    changedFields:changed_fields
  }
`
