import gql from 'graphql-tag'

export const getBrands = gql`
  subscription getBrands {
    brands: genio_brand {
      id
      name
    }
  }
`

export const getModels = gql`
  subscription getModels($id: Int!) {
    models: genio_model(where: { id_brand: { _eq: $id } }) {
      id
      name
    }
  }
`

export const getYears = gql`
  query getYears {
    years: genio_year(order_by: { id: desc }) {
      id
      name: id
    }
  }
`

export const getVersions = gql`
  subscription version($modelId: Int!, $yearId: Int!) {
    versions: genio_version(
      where: {
        id_model: { _eq: $modelId }
        version_years: { year: { id: { _eq: $yearId } } }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

export const getComponents = gql`
  query getComponents {
    components: genio_component {
      id
      name
      componentValue: component_values {
        id
        name: value
      }
    }
  }
`
