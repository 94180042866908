import { Type } from 'class-transformer'

import { Entity } from '..'

import { Payment } from '@/entities/finance'
import { SaleOrder } from '@/entities/sales/SaleOrder'

export class ProjectionPayment extends Entity {
  @Type(() => Payment)
  payment: Payment

  @Type(() => SaleOrder)
  saleOrder: SaleOrder
}
