import { Actions } from '../../interfaces'
import { TrafficTicket } from '@/entities/public'

import update from './update'

export const actions: Actions = {
  update,
}

export default {
  Model: { TrafficTicket },
  actions: { [TrafficTicket.name]: actions },
}
