import gql from 'graphql-tag'
import { InspectionAssessmentFragment } from '.'
import { EntityFields } from '..'

export const BaseInspectionQualificationFragment = gql`
  fragment BaseInspectionQualification on purchase_inspection_qualification{
    ${EntityFields}
    value
    validated
  }
`

export const InspectionQualificationFragment = gql`
  ${BaseInspectionQualificationFragment}
  ${InspectionAssessmentFragment}
  fragment InspectionQualification on purchase_inspection_qualification{
    ...BaseInspectionQualification
    assessment {
      ...InspectionAssessment
    }
    component {
      id
      cost
      inspectionComponent:inspection_component {
        id
        component{
          id
          slug
          name
        }
        componentValue:component_value {
          id
          value
        }
      }
    }
    parameter {
      id
      order
      name
    }
    value
  }
`
