<template>
  <svg width="194" height="194" viewBox="0 0 194 194" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_212_20364)">
      <path
        d="M149.203 41.104L139.855 53.113C160.005 68.792 170.056 94.176 166.089 119.366C163.183 137.85 153.251 154.096 138.125 165.114C122.994 176.126 104.485 180.602 86.001 177.681C47.844 171.673 21.681 135.743 27.679 97.583C30.585 79.097 40.52 62.85 55.648 51.835C68.856 42.22 84.639 37.602 100.734 38.518L87.579 52.319L97.338 61.632L118.104 39.831L118.109 39.839L127.412 30.07L117.66 20.767L117.655 20.77L95.862 0L86.552 9.769L100.752 23.294C81.449 22.381 62.542 27.996 46.693 39.536C28.28 52.943 16.19 72.717 12.65 95.221C5.34798 141.666 37.19 185.405 83.635 192.714C88.124 193.422 92.611 193.769 97.069 193.769C114.959 193.769 132.342 188.146 147.08 177.413C165.495 164.004 177.583 144.23 181.123 121.731C185.952 91.077 173.72 60.181 149.203 41.104Z"
      />
      <path
        d="M105.24 151.971V151.968L105.241 143.211C115.624 142.052 125.726 135.493 125.726 123.041C125.726 106.122 109.994 104.182 98.503 102.767C91.156 101.889 85.533 100.87 85.533 96.4191C85.533 90.2311 94.255 89.5641 98.006 89.5641C103.573 89.5641 109.513 92.1811 111.531 95.5211L112.117 96.4921L123.659 91.1511L123.088 89.9871C118.787 81.1941 111.079 78.6501 105.238 77.6231V69.9131H91.723V77.5901C79.141 79.4461 71.669 86.4291 71.669 96.4191C71.669 112.709 86.46 114.362 97.251 115.572C106.868 116.706 111.345 119.082 111.345 123.041C111.345 130.604 100.871 131.195 97.66 131.195C90.513 131.195 83.622 127.629 81.629 122.894L81.134 121.725L68.595 127.041L69.095 128.21C72.808 136.901 80.82 142.347 91.725 143.635V151.971H105.24Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_212_20364">
        <rect width="193.769" height="193.769" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
