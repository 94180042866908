import { ProcessStatusFragment } from '@/graphql/fragments/settings'
import gql from 'graphql-tag'

export const query = gql`
  ${ProcessStatusFragment}
  query findSettingsProcessStatus($filter: settings_process_status_bool_exp,$order: [settings_process_status_order_by!], $limit: Int){
    records: settings_process_status(where:$filter,order_by: $order, limit: $limit) {
      ...ProcessStatus
    }
  }
`

export default query
