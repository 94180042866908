import { Resource } from '@/store/settings/resources/state'

export class ResourcesClass {
  public resource: Resource;

  constructor (resources: Resource) {
    this.resource = resources
  }

  get id (): number {
    if (!this.resource.id) return -1
    return this.resource.id
  }

  get name (): string {
    if (!this.resource.name) return ''
    return this.resource.name
  }

  get title (): string {
    if (!this.resource.title) return ''
    return this.resource.title
  }

  get slug (): string {
    if (!this.resource.slug) return ''
    return this.resource.slug
  }

  get description (): string {
    if (!this.resource.description) return ''

    return this.resource.description
  }

  get icon (): string {
    if (!this.resource.icon) return ''

    return this.resource.icon
  }

  get stringPosition (): string {
    if (!this.resource.position) return ''
    return this.resource.position.toString()
  }

  get position (): number {
    if (!this.resource.position) return -1
    return this.resource.position
  }

  set position (position: number) {
    if (!position) {
      this.resource.position = -1
    }
    this.resource.position = position
  }

  get groupId (): number {
    if (!this.resource.resourceGroup || !this.resource.resourceGroup.id) { return 0 }

    return this.resource.resourceGroup.id
  }

  set groupId (id: number) {
    if (!this.resource.resourceGroup) this.resource.resourceGroup = { id }
    this.resource.resourceGroup.id = id
  }

  get groupTitle (): string {
    if (!this.resource.resourceGroup || !this.resource.resourceGroup.title) {
      return ''
    }
    return this.resource.resourceGroup.title
  }
}
