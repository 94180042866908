import { Actions } from '../../interfaces'
import { MaritalStatus } from '@/entities/persons'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { MaritalStatus },
  actions: { [MaritalStatus.name]: actions },
}
