import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/views/errors/401.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/errors/404.vue'),
  },
]

export default routes
