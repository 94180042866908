import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { Reserve } from '@/entities/sales'
import { BaseSaleOrderFragment, ReserveFragment } from '@/graphql/fragments/sales'
import { DealFragment, LeadFragment } from '@/graphql/fragments/crm'

const query = gql`
  ${ReserveFragment}
  ${BaseSaleOrderFragment}
  ${DealFragment}
  ${LeadFragment}
  query reservations($filter: sales_reserve_bool_exp, $order: [sales_reserve_order_by!], $limit: Int, $offset: Int) {
    records: sales_reserve(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...Reserve
      saleOrder:sale_order {
        ...BaseSaleOrder
        deal {
          ...Deal
          lead {
            ...Lead
          }
          stock{
            id
            status {
              ...ProcessStatus
            }
          }
          payments(where: {process:{table_name:{_eq:"reserve"}}}) {
            id
            amount
          }
        }
      }
    }
    total: sales_reserve_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const reservations = QueryBuilder(query, Reserve)
export default reservations
