import { Actions } from '../../interfaces'
import { RotationIndex } from '@/entities/vehicle'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { RotationIndex },
  actions: { [RotationIndex.name]: actions },
}
