import gql from 'graphql-tag'
import { PaymentOrderItemFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentOrderItemFragment}
  mutation insertPaymentOrderItem($fields: finance_payment_order_item_insert_input!) {
    record: insert_finance_payment_order_item_one(object: $fields) {
      ...PaymentOrderItem
    }
  }
`

export default mutation
