import gql from 'graphql-tag'
import { EntityFields } from '@/graphql/fragments'

export const query = gql`
  query version($filter: vehicle_version_bool_exp, $order: [vehicle_version_order_by!]) {
    records: vehicle_version(where: $filter, order_by: $order){
      ${EntityFields}
      name
      discontinued
    }
  }
`
export default query
