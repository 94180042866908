import { GetterTree, MutationTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { Resource } from '@/entities/public'

export interface DetailsModule {
  resource?: Resource
}

export function initState (): DetailsModule {
  return {
    resource: new Resource(), // TODO: Load from local-storage
  }
}

export const mutations: MutationTree<DetailsModule> = {}

export const getters: GetterTree<DetailsModule, RootState> = {}
