import { Actions } from '../../interfaces'

import { Financing } from '@/entities/loans'
import create from './create'
import fetch from './fetch'
import update from './update'
import find from './find'

export const actions: Actions = {
  create,
  fetch,
  update,
  find,
}

export default {
  Model: { Financing },
  actions: { [Financing.name]: actions },
}
